import { arrayMove } from '@dnd-kit/sortable';
import { ArrowLeft, ArrowRight, Backspace } from '@fluentui/keyboard-keys';
import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import { toNumber, toRange } from '../../utils/number';
import { useAutocompleteContext } from '../Autocomplete/AutocompleteContext';
import { AutocompleteTag, AutocompleteTagProps } from '../Autocomplete/AutocompleteTag';
import { EditableText } from '../EditableText';

export type AutocompletePeopleTagProps = AutocompleteTagProps & {
  index: number;
  isSortable?: boolean;
  isDisabled?: boolean;
};

const StyledEditableText = styled(EditableText)`
  align-self: stretch;

  input {
    // We need to enforce this atribute to prevent conflicts because
    // EditableText already have this rule rest.
    padding: 0 4px !important;
  }
`;

const StyledAutocompleteTag = styled(AutocompleteTag)<{ $isPriority?: boolean }>`
  ${(props) => props.$isPriority && `padding-left: 0;`}

  .fui-Tag__primaryText {
    display: flex;
    align-self: stretch;
    align-items: center;
  }
`;

const Text = styled.span``;

const Divider = styled.div`
  width: 1px;
  background-color: #ffd335;
  align-self: stretch;

  margin-right: 4px;
`;

/**
 * This component extends `AutocompleteTag`.
 *
 * It adds tag's position number that can be changed manually by typing a new
 * number.
 */
export const AutocompletePeopleTag: FC<AutocompletePeopleTagProps> = (props): ReactElement => {
  const autocompleteContext = useAutocompleteContext();

  const position = props.index + 1;
  const [inputValue, setInputValue] = useState<string>(String(position));

  useEffect(() => {
    setInputValue(String(position));
  }, [position]);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    /**
     * The code below overrides the default behaviour that comes from Fluent UI.
     *
     * In order to be able to navigate inside input field we should make sure
     * that Fluent UI doesn't perform its internal focusing management on
     * pressing the following keys.
     */
    if (event.key === Backspace || event.key === ArrowRight || event.key === ArrowLeft) {
      // This prevents default behaviour from kicking in
      event.key = '';
    }
  };

  const handleInputBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const parsedPosition = toNumber(event.target.value);
    if (!parsedPosition || !autocompleteContext) {
      setInputValue(String(position));
      return;
    }

    const minPosition = 1;
    const maxPosition = autocompleteContext.selectedOptions.length;
    const newPosition = toRange(parsedPosition, minPosition, maxPosition);

    const currentIndex = position - 1;
    const newIndex = newPosition - 1;

    setInputValue(String(newPosition));

    const newSelectedOptions = arrayMove(autocompleteContext.selectedOptions, currentIndex, newIndex);
    autocompleteContext.setSelectedOptions(newSelectedOptions);
  };

  const isDisabled = autocompleteContext?.isDisabled || props.isDisabled;

  return (
    <StyledAutocompleteTag {...props} disabled={isDisabled} $isPriority={props.isSortable}>
      {props.isSortable && (
        <>
          <StyledEditableText
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            onBlur={handleInputBlur}
            onPointerDown={(event) => event.stopPropagation()}
            onMouseDown={(event) => event.stopPropagation()}
            disabled={isDisabled}
          />
          <Divider />
        </>
      )}
      <Text>{props.children}</Text>
    </StyledAutocompleteTag>
  );
};
