import { castArray, isBoolean, isFunction, isNil, isObject, uniqBy } from 'lodash';
import { createAtom } from 'mobx';

import { MaybeArray } from '../utils/array';

const defaultGroupName = 'default';
type DefaultName = typeof defaultGroupName;

type LoadingGroupName = string | number | symbol;
type RawNamedStateId = string | number;
type NamedStateId = string;

// TODO: Extend list of states from activated and diactivated (true and false) to idle, loading, succes, error
// TODO: Adapt the class for background fetching (i.e. add a new state type)
// TODO: Implement the ability to lock a resource to prevent parallel loading (without implementing the queue feature)

/**
 * Loading group consists out of the default state and list of named states.
 * Each of them can be either `true` (i.e. loading) or `false` (i.e. idle).
 *
 * The default state can be used for simple groups while named states can be
 * used for dynamic lists (so that each item has its independent loading state).
 */
class LoadingGroup<N extends LoadingGroupName> {
  /** Name of the group */
  name: N;

  /** Default loading state of the group. Think of it as a named state that is
   * used by default. */
  defaultState = false;

  /** Individual named loading states within the group */
  namedStates: Record<NamedStateId, { id: NamedStateId; state: boolean }> = {};

  constructor(options: { name: N }) {
    this.name = options.name;
  }

  /**
   * Get loading state of a particular element:
   * - If `id` is provided, get named loading state;
   * - If `id` is NOT provided, get group's default loading state;
   */
  is(id?: NamedStateId): boolean {
    return isNil(id) ? this.defaultState : this.namedStates[id]?.state ?? false;
  }

  /**
   * Get the combined loading state of the group. It will combine the default
   * state and all named states. Returns `true` if at least one of those
   * elements is active.
   */
  isAny(): boolean {
    return this.defaultState || Object.values(this.namedStates).some((item) => item.state);
  }

  /**
   * Activate loading state of a particular element:
   * - If `id` is provided, activates a named loading state;
   * - If `id` is NOT provided, activates group's default loading state;
   */
  on(id?: NamedStateId): void {
    this.set({ id, state: true });
  }

  /**
   * Sets (activated or deactivated) loading state of a particular element:
   * - If `options.id` is provided, sets a named loading state;
   * - If `options.id` is NOT provided, sets group's default loading state;
   */
  set(options: { id?: NamedStateId; state: boolean }) {
    const { id, state } = options;

    if (isNil(id)) {
      this.defaultState = state;
    } else {
      this.namedStates[id] = { id, state };
    }
  }

  /**
   * Deactivate loading state of a particular element:
   * - If `id` is provided, deactivates a named loading state;
   * - If `id` is NOT provided, deactivates group's default loading state;
   */
  off(id?: NamedStateId): void {
    this.set({ id, state: false });
  }

  /**
   * Resets this group:
   * - Deactivates group's default state;
   * - Removes all named loading states;
   */
  reset(): void {
    this.defaultState = false;
    this.namedStates = {};
  }
}

/**
 * This store helps to maintain the loading state in a granular way. It supports
 * namespaces (called "groups") and individual states (called "named states")
 * for elements of a dynamic lists.
 *
 * It's called Factory because it's intended that other stores will have their
 * own independent instance of this class.
 *
 * For more details on implementation you can also refer to the `LoadingGroup`
 * class.
 */
export class LoadingStoreFactory<
  CustomGroupNames extends LoadingGroupName = never,
  GroupNames extends CustomGroupNames | DefaultName = CustomGroupNames | DefaultName,
> {
  /** Mobx utility to maintain reactivity */
  private atom = createAtom('loadingStore');

  /** Set of groups (i.e. namespaces) */
  private groups: Record<GroupNames, LoadingGroup<GroupNames>>;

  private get groupsArray(): LoadingGroup<GroupNames>[] {
    return Object.values(this.groups);
  }

  constructor(options: { names?: CustomGroupNames[] } = {}) {
    const customGroupNames = options.names ?? [];
    const groupNames = [...customGroupNames, defaultGroupName] as GroupNames[];

    this.groups = groupNames.reduce(
      (acc, groupName) => ({
        ...acc,
        [groupName]: new LoadingGroup({ name: groupName }),
      }),
      {} as Record<GroupNames, LoadingGroup<GroupNames>>,
    );
  }

  /**
   * Small utility that converts named state IDs into unified type (which is a
   * string).
   */
  private formatNamedStateId(id?: RawNamedStateId): NamedStateId | undefined {
    if (isNil(id)) return;

    return String(id);
  }

  /**
   * Private function with abstracted logic to convert input props into list of
   * groups.
   */
  private computeGroupList<TPayload extends Record<string, any>>(
    inputGroups?: MaybeArray<GroupNames | ({ name: GroupNames; id?: RawNamedStateId } & TPayload)>,
    inputId?: RawNamedStateId,
  ): ({ name: GroupNames; instance: LoadingGroup<GroupNames>; id?: NamedStateId } & TPayload)[] {
    type ReturnType<T extends GroupNames> = { name: T; id?: NamedStateId } & TPayload;
    type ReturnTypeWithPayload<T extends GroupNames> = { instance: LoadingGroup<T> } & ReturnType<T> & TPayload;

    const groupList = castArray(inputGroups ?? [])
      .map((groupItem) =>
        isObject(groupItem)
          ? { ...groupItem, id: this.formatNamedStateId(groupItem.id ?? inputId) }
          : { name: groupItem, id: this.formatNamedStateId(inputId) },
      )
      .reduce((acc, groupItem) => {
        const groupInstance = this.groups[groupItem.name];

        // Currently we don't support adding new groups dynamically
        if (!groupInstance) return acc;

        const groupObject = {
          ...groupItem,
          instance: this.groups[groupItem.name],
        } as ReturnTypeWithPayload<GroupNames>;

        return [...acc, groupObject];
      }, [] as ReturnTypeWithPayload<GroupNames>[]);

    return uniqBy(groupList, (groupItem) => [groupItem.name, groupItem.id].join('::'));
  }

  /**
   * Check if loading state is active or not. Groups and named states are
   * supported. See examples below.
   *
   * @example
   * ```js
   * is('default');
   * is('default', 123);
   * is('customGroupName');
   * is('customGroupName', 123);
   * is(['default', 'customGroupName']);
   * is([{ name: 'default', id: 123 }, { name: 'customGroupName', id: 123 }]);
   * ```
   */
  is(group: GroupNames, id?: RawNamedStateId): boolean;
  is(groups: GroupNames[]): boolean;
  is(groups: { name: GroupNames; id?: RawNamedStateId }[]): boolean;
  is(
    inputGroups: MaybeArray<GroupNames | { name: GroupNames; id?: RawNamedStateId }>,
    inputId?: RawNamedStateId,
  ): boolean {
    this.atom.reportObserved();

    const groups = this.computeGroupList(inputGroups, inputId);
    return groups.some(({ instance: groupInstance, id: namedStateId }) => groupInstance.is(namedStateId));
  }

  /**
   * Alternative to the `is` function.
   *
   * Check if loading state is active or not. Returns `true` if any of the
   * states is activated. Groups are supported, named states are NOT supported.
   * See examples below.
   *
   * @example
   * ```js
   * isAny();
   * isAny('default');
   * isAny('customGroupName');
   * isAny(['default', 'customGroupName']);
   * isAny([{ name: 'default' }, { name: 'customGroupName' }]);
   * ```
   */
  isAny(): boolean;
  isAny(group: GroupNames): boolean;
  isAny(groups: GroupNames[]): boolean;
  isAny(groups: { name: GroupNames }[]): boolean;
  isAny(inputGroups?: MaybeArray<GroupNames | { name: GroupNames }>): boolean {
    this.atom.reportObserved();

    const groups = this.computeGroupList(inputGroups);

    // If groups is provided ...
    return groups.length > 0
      ? // ... then check only provided ones ...
        groups.some(({ instance: groupInstance }) => groupInstance.isAny())
      : // ... otherwise do the check across all available groups
        this.groupsArray.some((groupInstance) => groupInstance.isAny());
  }

  /**
   * Activates a loading state. Groups and named states are supported. See
   * examples below.
   *
   * @example
   * ```js
   * on('default');
   * on('default', 123);
   * on('customGroupName');
   * on('customGroupName', 123);
   * on(['default', 'customGroupName']);
   * on([{ name: 'default', id: 123 }, { name: 'customGroupName', id: 123 }]);
   * ```
   */
  on(group: GroupNames, id?: RawNamedStateId): void;
  on(groups: GroupNames[]): void;
  on(groups: { name: GroupNames; id?: RawNamedStateId }[]): void;
  on(
    inputGroups: MaybeArray<GroupNames | { name: GroupNames; id?: RawNamedStateId }>,
    inputId?: RawNamedStateId,
  ): void {
    const groups = this.computeGroupList(inputGroups, inputId);
    for (const { instance: groupInstance, id: namedStateId } of groups) {
      groupInstance.on(namedStateId);
    }

    this.atom.reportChanged();
  }

  /**
   * Sets a loading state. Groups and named states are supported. See examples
   * below.
   *
   * @example
   * ```js
   * /// Status can be either `true` or `false`
   * set('default', true);
   * set('default', 123, true);
   * set('customGroupName', true);
   * set('customGroupName', 123, true);
   * set(['default', 'customGroupName'], true);
   * set([{ name: 'default', id: 123 }, { name: 'customGroupName', id: 123 }], true);
   * set([{ name: 'default', state: true }, { name: 'customGroupName', state: false }]);
   * set([{ name: 'default', id: 123, state: true }, { name: 'customGroupName', id: 123, state: false }]);
   * ```
   */
  set(group: GroupNames, state: boolean): void;
  set(group: GroupNames, id: RawNamedStateId, state: boolean): void;
  set(groups: GroupNames[], state: boolean): void;
  set(groups: { name: GroupNames; id?: RawNamedStateId }[], state: boolean): void;
  set(groups: { name: GroupNames; id?: RawNamedStateId; state: boolean }[]): void;
  set(
    groupsOrState: MaybeArray<GroupNames | { name: GroupNames; id?: RawNamedStateId; state?: boolean }> | boolean,
    inputOrId?: RawNamedStateId | boolean,
    inputState?: boolean,
  ): void {
    const inputGroups = isBoolean(groupsOrState) ? undefined : groupsOrState;
    const inputId = isBoolean(inputOrId) ? undefined : inputOrId;
    const stateFallback =
      inputState ??
      (isBoolean(inputOrId) ? inputOrId : undefined) ??
      (isBoolean(groupsOrState) ? groupsOrState : undefined) ??
      false;

    const groups = this.computeGroupList(inputGroups, inputId);
    for (const { instance: groupInstance, id: namedStateId, state } of groups) {
      groupInstance.set({ id: namedStateId, state: state ?? stateFallback });
    }

    this.atom.reportChanged();
  }

  /**
   * Deactivates a loading state. Groups and named states are supported. See
   * examples below.
   *
   * @example
   * ```js
   * off('default');
   * off('default', 123);
   * off('customGroupName');
   * off('customGroupName', 123);
   * off(['default', 'customGroupName']);
   * off([{ name: 'default', id: 123 }, { name: 'customGroupName', id: 123 }]);
   * ```
   */
  off(group: GroupNames, id?: RawNamedStateId): void;
  off(groups: GroupNames[]): void;
  off(groups: { name: GroupNames; id?: RawNamedStateId }[]): void;
  off(
    inputGroups: MaybeArray<GroupNames | { name: GroupNames; id?: RawNamedStateId }>,
    inputId?: RawNamedStateId,
  ): void {
    const groups = this.computeGroupList(inputGroups, inputId);
    for (const { instance: groupInstance, id: namedStateId } of groups) {
      groupInstance.off(namedStateId);
    }

    this.atom.reportChanged();
  }

  /**
   * Helper function that wraps a callback and updates a loading state according
   * to the execution state. See examples below.
   *
   * @example
   * ```js
   * factory('default', () => {});
   * factory('default', 123, () => {});
   * factory('customGroupName', () => {});
   * factory('customGroupName', 123, () => {});
   * factory(['default', 'customGroupName'], () => {});
   * factory([{ name: 'default', id: 123 }, { name: 'customGroupName', id: 123 }], () => {});
   * ```
   *
   * @example
   * ```js
   * const result = await factory('default', () => Promise.resolve('Hello, World!'));
   * /// result = Hello, World!
   * ```
   */
  factory<R>(group: GroupNames, callback: () => Promise<R>): Promise<R | undefined>;
  factory<R>(group: GroupNames, id: RawNamedStateId, callback: () => Promise<R>): Promise<R | undefined>;
  factory<R>(groups: GroupNames[], callback: () => Promise<R>): Promise<R | undefined>;
  factory<R>(groups: { name: GroupNames; id?: RawNamedStateId }[], callback: () => Promise<R>): Promise<R | undefined>;
  async factory<R>(
    inputGroups: MaybeArray<GroupNames | { name: GroupNames; id?: RawNamedStateId }>,
    inputIdOrCallback?: RawNamedStateId | ((...args: any[]) => R),
    callback?: (...args: any[]) => Promise<R>,
  ): Promise<R | undefined> {
    const inputId = isFunction(inputIdOrCallback) ? undefined : inputIdOrCallback;
    const inputCallback =
      callback ??
      (isFunction(inputIdOrCallback) ? inputIdOrCallback : undefined) ??
      (() => Promise.resolve() as unknown as Promise<R>);

    const groups = this.computeGroupList(inputGroups, inputId);
    let result: R | undefined;

    this.on(groups);
    try {
      result = await inputCallback();
    } catch (error) {
      // TODO: Think about creating a wrapper that will automatically collect errors & exit
      console.warn(error);
      throw error;
    } finally {
      this.off(groups);
    }

    return result;
  }

  /**
   * Resets provided groups. It will:
   * - Deactivate default state of those groups;
   * - Remove all named states of those groups;
   *
   * @example
   * ```js
   * rest('default');
   * rest('customGroupName');
   * rest(['default', 'customGroupName']);
   * ```
   */
  reset(inputGroups: MaybeArray<GroupNames>): void {
    const groups = this.computeGroupList(inputGroups);
    for (const { instance: groupInstance } of groups) {
      groupInstance.reset();
    }
  }

  /**
   * Resets all groups (alternative to `reset` function). It will:
   * - Deactivate default state of all groups;
   * - Remove all named states of all groups;
   */
  resetAll(): void {
    for (const groupInstance of this.groupsArray) {
      groupInstance.reset();
    }
  }
}
