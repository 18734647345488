import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  Spinner,
} from '@fluentui/react-components';
import { FC, ReactElement, useState } from 'react';
import styled from 'styled-components';

const Body = styled(DialogBody)`
  text-align: center;
  margin-bottom: 15px;
`;

const Actions = styled(DialogActions)`
  display: flex;
  justify-content: center;
`;

const TextInContent = styled.div``;

interface LimitPopupProps {
  open: boolean;
  onNo: () => void;
  onYes: () => Promise<void>;
}

const DeleteUserVotePopup: FC<LimitPopupProps> = (props): ReactElement => {
  const [isYesClicked, setIsYesClicked] = useState<boolean>(false);
  return (
    <Dialog open={props.open}>
      <DialogSurface>
        <Body>
          <DialogContent>
            <TextInContent>
              Previous vote of the selected attendee will be cancelled and that attendee will recieve a new voting
              invitation.
            </TextInContent>
          </DialogContent>
        </Body>
        <Actions>
          <Button
            appearance="secondary"
            disabled={isYesClicked}
            onClick={() => {
              props.onYes();
              setIsYesClicked(true);
            }}
          >
            {!isYesClicked ? `Continue` : <Spinner size="tiny" />}
          </Button>
          <Button disabled={isYesClicked} appearance="primary" onClick={props.onNo}>
            Cancel
          </Button>
        </Actions>
      </DialogSurface>
    </Dialog>
  );
};

export default DeleteUserVotePopup;
