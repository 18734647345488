import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { CommonProps } from '@/utils/props';

import { LayoutBase } from './BaseLayout';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
`;

const Container = styled.div`
  max-width: 1000px;
  width: 100%;
`;

export type CenteredLayoutProps = CommonProps;

export const CenteredLayout: FC<CenteredLayoutProps> = () => {
  return (
    <LayoutBase>
      <Root>
        <Container>
          <Outlet />
        </Container>
      </Root>
    </LayoutBase>
  );
};
