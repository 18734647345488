import { Theme } from '@fluentui/react-components';
import { TeamsUserCredential } from '@microsoft/teamsfx';
import { FC, PropsWithChildren } from 'react';

import { TeamsFxContext } from '@/components/Context';

export type MicrosoftProviderProps = PropsWithChildren<{
  theme: Theme;
  themeString?: string;
  teamsUserCredential?: TeamsUserCredential;
}>;

export const MicrosoftProvider: FC<MicrosoftProviderProps> = ({
  theme,
  themeString,
  teamsUserCredential,
  children,
}) => {
  return (
    <TeamsFxContext.Provider value={{ theme, themeString: themeString ?? 'dark', teamsUserCredential }}>
      {children}
    </TeamsFxContext.Provider>
  );
};
