import { Text } from '@fluentui/react-components';
import { DateSelectArg, DayHeaderContentArg, EventClickArg, EventContentArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import momentTzPlugin from '@fullcalendar/moment-timezone';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment-timezone';
import { FC, memo, ReactElement, RefObject, useContext } from 'react';
import styled from 'styled-components';

import { getSlotOnSelect } from '../helpers/calendar';
import { currentThemeString, FullCalendarThemeConstructor, themeName } from '../helpers/theming';
import { TeamsFxContext } from './Context';

export interface CalendarEvent {
  id?: string;
  title?: string;
  start: string;
  end: string;
  fromOutlook?: boolean;
  isOutdated?: boolean;
}

interface CalendarProps {
  timeZone: string;
  interval: string;
  select: (event: CalendarEvent) => void;
  unselect: (start: string) => void;
  calendarRef: RefObject<FullCalendar>;
  events: CalendarEvent[];
  allSlots: boolean;
  handleAllSlots: () => void;
  isLoading: boolean;
  workingDays: number[];
  workingTime: [number, number];
}

const CalendarContainer = styled.div`
  .fc-event {
    min-height: 20px;
  }
`;

const EventContainer = styled.div<{ clamps: number }>`
  display: -webkit-box;
  margin-left: 2px;
  -webkit-line-clamp: ${(props) =>
    props.clamps < 1
      ? 1
      : props.clamps > 10
        ? 10
        : props.clamps - Math.ceil(props.clamps) == 0
          ? props.clamps
          : Math.trunc(props.clamps)};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Calendar: FC<CalendarProps> = (props): ReactElement => {
  const { theme, themeString } = useContext(TeamsFxContext);

  const dayHeader = (event: DayHeaderContentArg) => {
    const dateMoment = moment(event.date).tz(props.timeZone);
    const dateData = event.text.split(' ');
    const weekDay = dateData[0];
    const day = dateMoment.format('DD');
    return <Text>{`${weekDay} ${day}`}</Text>;
  };

  const selectSlotHandler = (data: DateSelectArg) => {
    if (props.calendarRef.current) props.calendarRef.current.getApi().unselect();
    const isPast = moment(data.start).isBefore(moment());
    if (isPast) return;
    const slot: CalendarEvent = getSlotOnSelect(data, props.interval);
    props.select(slot);
  };

  const handleAllow = () => {
    return !props.isLoading;
  };

  const eventClickHandler = (data: EventClickArg) => {
    props.unselect(moment(data.event.startStr).toISOString());
  };

  const eventContent = (data: EventContentArg) => {
    const clamps = moment(data.event.end).diff(moment(data.event.start)) / 1800000; // 1800000 is 30 minutes
    return (
      <EventContainer
        title={data.event._def.title}
        clamps={clamps}
      >{`${data.timeText} ${data.event._def.title}`}</EventContainer>
    );
  };

  return (
    <CalendarContainer>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentTzPlugin, momentPlugin]}
        initialView="timeGridWeek"
        ref={props.calendarRef}
        dayMaxEvents={2}
        eventBackgroundColor={
          FullCalendarThemeConstructor(theme).eventBackgroundColor[themeName[currentThemeString(themeString)]]
        }
        eventBorderColor={
          themeString === 'contrast' ? theme?.colorCompoundBrandForeground1 : theme?.colorBrandBackground
        }
        eventTextColor={FullCalendarThemeConstructor(theme).eventTextColor[themeName[currentThemeString(themeString)]]}
        eventColor={'none'}
        droppable={true}
        firstDay={1}
        weekends={true}
        editable={true}
        nowIndicator={true}
        events={[
          ...props.events.map((item) => ({
            ...item,
            start: moment.utc(item.start).tz(props.timeZone).toISOString(),
            end: moment.utc(item.end).tz(props.timeZone).toISOString(),
            backgroundColor: FullCalendarThemeConstructor(theme, item.fromOutlook).backgroundColor[
              themeName[currentThemeString(themeString)]
            ],
            borderColor: FullCalendarThemeConstructor(theme, item.fromOutlook).borderColor[
              themeName[currentThemeString(themeString)]
            ],
            textColor: FullCalendarThemeConstructor(theme, item.fromOutlook).textColor[
              themeName[currentThemeString(themeString)]
            ],
            editable: false,
            durationEditable: false,
          })),
          ...[
            {
              start: moment(0).toISOString(),
              end: moment().toISOString(),
              display: 'background',
              backgroundColor: '#ff000040',
            },
          ],
        ]}
        selectMirror={true}
        scrollTime={'07:00:00'}
        allDaySlot={false}
        dayHeaderContent={dayHeader}
        eventContent={eventContent}
        selectable={true}
        timeZone={props.timeZone}
        slotDuration={'00:30'}
        select={selectSlotHandler}
        selectAllow={handleAllow}
        eventClick={eventClickHandler}
        businessHours={{
          daysOfWeek: props.workingDays,
          startTime: moment.utc(moment.duration(props.workingTime[0], 'hours').asMilliseconds()).format('HH:mm'),
          endTime: moment.utc(moment.duration(props.workingTime[1], 'hours').asMilliseconds()).format('HH:mm'),
        }}
        longPressDelay={275}
        customButtons={{
          selectAllSlots: {
            text: props.isLoading ? 'Loading...' : props.allSlots ? 'Remove all slots' : 'Select a week ahead',
            click: props.handleAllSlots,
          },
        }}
        headerToolbar={{
          left: 'title',
          right: 'selectAllSlots prev,next',
        }}
      />
    </CalendarContainer>
  );
};

export default memo(Calendar);
