import { Button, SelectTabData, Spinner, Tab, TabList, TabValue, Text, Theme } from '@fluentui/react-components';
import { observer } from 'mobx-react-lite';
import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Paper } from '@/components/Paper';
import { useUniversalNavigate } from '@/hooks/useUniversalNavigate';

import { Status } from '../api/meetings';
import { TeamsFxContext } from '../components/Context';
import { MeetingCardSkeleton } from '../components/MeetingCard/components/MeetingCardSkeleton';
import { MeetingCard } from '../components/MeetingCard/MeetingCard';
import MeetingSortMenu from '../components/MeetingsSortMenu';
import { MyMeetingsSort } from '../constants';
import { activeMeetingsFilterHelper, defaultFilteredEventsHelper } from '../helpers/slots';
import { currentThemeString, StyledTabListThemeConstructor, themeName } from '../helpers/theming';
import { useViewSize } from '../hooks/useViewSize';
import { useStore } from '../stores';

const StyledTabList = styled(TabList)<{ theme: Theme; $themeName: themeName }>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 5px;
  gap: 5px;

  button {
    background-color: ${(props) => StyledTabListThemeConstructor(props.theme).backgroundColor[props.$themeName]};
    border-radius: 5px;
    padding: 15px 70px;
    &:hover {
      background-color: ${(props) =>
        props.$themeName === 'contrast'
          ? props.theme.colorCompoundBrandForeground1
          : StyledTabListThemeConstructor(props.theme).backgroundColor[props.$themeName]};
    }
  }

  :nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
  }
`;

const ZeroMeetingsText = styled(Text)`
  font-size: 15px;
  white-space: pre-line;
  overflow-wrap: anywhere;
  text-align: center;
`;

const ZeroMeetingsBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 5px;
  gap: 10px;
`;

const MyMeetings: FC = (): ReactElement => {
  const { appStore } = useStore();
  const navigate = useUniversalNavigate();
  const { theme, themeString } = useContext(TeamsFxContext);
  const [sortMeetingType, setSortMeetingType] = useState<TabValue>('activeMeetings');
  const [selectedItemValue, setSelectedItemValue] = useState<string>('activeMeetings');
  const [checkedValues, setCheckedValues] = useState<Record<string, string[]>>({ sort: ['activeMeetings'] });
  const [myMeetingsLoaded, setMyMeetingsLoaded] = useState<boolean>(false);

  const viewSize = useViewSize(960);

  useEffect(() => {
    if (appStore.isInTeams === false) {
      navigate('/error');
    }
  }, [appStore.isInTeams]);

  useEffect(() => {
    if (appStore.teamsUserCredential && appStore.isInitialized) {
      void (async () => {
        await appStore.getUserInfo();
        await appStore.getMyMeetings();
        await appStore.getMyMeetingsAll();
        setMyMeetingsLoaded(true);
      })();
    }
  }, [appStore.teamsUserCredential, appStore.needConsent, appStore.isInitialized]);

  const handleTabSelect = (data: SelectTabData) => {
    setSortMeetingType(data.value);
  };

  const handleMenuSelect = () => {
    if (appStore.teamsUserCredential && appStore.isInitialized && !appStore.isMyMeetingsAllLoaded) {
      void (async () => {
        await appStore.getMyMeetingsAll();
      })();
    }
  };

  const filteredEvents = () => {
    switch (sortMeetingType) {
      case 'activeMeetings':
        return appStore.myMeetings.filter((item) => activeMeetingsFilterHelper(item));
      case 'allMeetings':
        return appStore.myMeetings;
      case 'expired':
        return appStore.myMeetings.filter((item) => [Status.EXPIRED, Status.CANCELLED].includes(item.status));
      default:
        return appStore.myMeetings.filter((item) => defaultFilteredEventsHelper(item, sortMeetingType));
    }
  };

  return (
    <Paper padding={3}>
      {viewSize.isMobile ? (
        <MeetingSortMenu
          selectedItemValue={selectedItemValue}
          setSelectedItemValue={setSelectedItemValue}
          checkedValues={checkedValues}
          setCheckedValues={setCheckedValues}
          menuItemRadioName="sort"
          handleMenuSelect={handleMenuSelect}
          setSortMeetingType={setSortMeetingType}
        />
      ) : (
        <StyledTabList
          onTabSelect={(e, data) => handleTabSelect(data)}
          size="medium"
          defaultSelectedValue={sortMeetingType}
          theme={theme}
          $themeName={themeName[currentThemeString(themeString)]}
        >
          <Tab
            icon={
              myMeetingsLoaded ? (
                <Text>{appStore.myMeetings.filter((item) => activeMeetingsFilterHelper(item)).length}</Text>
              ) : (
                <Spinner size="tiny" />
              )
            }
            value="activeMeetings"
          >
            Active
          </Tab>
          <Tab
            icon={
              myMeetingsLoaded ? (
                <Text>
                  {appStore.myMeetings.filter((item) => defaultFilteredEventsHelper(item, Status.APPROVED)).length}
                </Text>
              ) : (
                <Spinner size="tiny" />
              )
            }
            value={Status.APPROVED}
          >
            Confirmed
          </Tab>
          <Tab
            icon={
              myMeetingsLoaded ? (
                <Text>
                  {appStore.myMeetings.filter((item) => defaultFilteredEventsHelper(item, Status.EXPIRED)).length}
                </Text>
              ) : (
                <Spinner size="tiny" />
              )
            }
            value={Status.EXPIRED}
          >
            Expired
          </Tab>
          <Tab
            icon={myMeetingsLoaded ? <Text>{appStore.myMeetings.length}</Text> : <Spinner size="tiny" />}
            value="allMeetings"
          >
            All
          </Tab>
        </StyledTabList>
      )}
      {appStore.isLoading ? (
        Array.from({ length: 3 }).map((_, index) => <MeetingCardSkeleton key={index} />)
      ) : filteredEvents().length === 0 ? (
        <ZeroMeetingsBlock>
          <ZeroMeetingsText>There are no meetings created yet.</ZeroMeetingsText>
          <ZeroMeetingsText>Let us help you coordinate attendees for your meeting. </ZeroMeetingsText>
          <Button appearance="primary" onClick={() => navigate('/create-meeting', { pageId: 'create-meeting' })}>
            New meeting
          </Button>
        </ZeroMeetingsBlock>
      ) : (
        filteredEvents().map((item) => (
          <MeetingCard currentSort={sortMeetingType as MyMeetingsSort} key={item.id} data={item} />
        ))
      )}
    </Paper>
  );
};

export default observer(MyMeetings);
