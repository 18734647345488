import _ from 'lodash';
import moment from 'moment';

import { Vote } from '../../api/meetings';

/**
 * This function is a simple sorting wrapper around meeting participants.
 *
 * Resulting sorting order:
 * 1. Participants who voted (desc sorting order).
 * 2. Participants who visited voting page, but didn't voted.
 * 3. Participants who did nothing.
 */
export const sortParticipantsInActionOrder = (
  /**
   * Meeting participants email list.
   */
  participants: string[],
  /**
   * Meeting participants voting links.
   */
  votingLinks: { participant: string; link: string; visited: boolean }[],
  /**
   * Meeting participants votes.
   */
  votes: Vote[],
): string[] => {
  const participantsWhoVoted = _(votes)
    .sortBy((vote) => (vote.createdAt ? moment(vote.createdAt).valueOf() : Infinity))
    .map((item) => item.participant)
    .uniq()
    .value();

  const visitedLinkParticipants = _(votingLinks)
    .filter((item) => item.visited)
    .map((item) => item.participant)
    .difference(participantsWhoVoted)
    .uniq()
    .value();

  const participantsWhoClickedLink = _.intersection(participants, visitedLinkParticipants);
  const participantsWhoDidNothing = _.difference(participants, [...participantsWhoVoted, ...visitedLinkParticipants]);

  const sortedParticipants = [...participantsWhoVoted, ...participantsWhoClickedLink, ...participantsWhoDidNothing];

  return sortedParticipants;
};
