import { useCallback } from 'react';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';

import { useStore } from '@/stores';

export const useUniversalNavigate = () => {
  const baseNavigate = useNavigate();

  const { appStore } = useStore();
  const { microsoftStore } = appStore;

  const navigate = useCallback(
    (to: To, options: NavigateOptions & { pageId?: string } = {}) => {
      if (microsoftStore.isMicrosoftTeams && options.pageId) {
        void microsoftStore.navigateToTab(options.pageId);
      } else {
        baseNavigate(to, options);
      }
    },
    [baseNavigate],
  );

  return navigate;
};
