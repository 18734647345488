import { Button, DialogActions } from '@fluentui/react-components';
import {
  Check24Regular as IconDescription,
  PeopleAddRegular as IconPeopleAdd,
  PersonInfo16Regular as IconOptionalPeopleAdd,
  SendRegular as IconSend,
} from '@fluentui/react-icons';
import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { FreeDateMeeting, UpdateFreeDateMeetingProps } from '../../../api/meetings';
import { charLimits } from '../../../constants';
import { sleep } from '../../../utils/promise';
import { AutocompleteOption } from '../../Autocomplete';
import { AutocompletePeopleRef } from '../../AutocompletePeople';
import { AutocompletePeopleField } from '../../AutocompletePeopleField';
import MissingParticipantsPopup from '../../MissingParticipantsPopup';
import { ParticipantsField } from '../../ParticipantsField';
import TextAreaField from '../../TextAreaField';

interface MeetingEditFormProps {
  data: FreeDateMeeting;
  onClose: () => void;
  onSave: (payload: UpdateFreeDateMeetingProps, options?: { shouldSetMeeting: boolean }) => Promise<void>;
}

interface ValidationState {
  state?: 'success' | 'error';
  message: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media only screen and (max-width: 768px) {
    #fc-dom-1 {
      font-size: 1em;
    }

    .fc .fc-button {
      font-size: 0.7em;
    }
  }
`;

const Actions = styled(DialogActions)`
  display: flex;
  justify-content: flex-end;
`;

const MeetingEditForm: FC<MeetingEditFormProps> = (props): ReactElement => {
  // Participants
  const participantAutocompleteRef = useRef<AutocompletePeopleRef>(null);
  const [participants, setParticipants] = useState<AutocompleteOption[]>(
    props.data.participants.map((item) => ({
      label: item,
      value: item,
      isDisabled: props.data.votes.map((item) => item.participant).includes(item),
    })),
  );
  const participantsRef = useRef<HTMLInputElement>(null);
  const [isPriorityMeeting, setIsPriorityMeeting] = useState<boolean>(props.data.isPriorityMeeting);
  const [isMissingParticipantsPopupOpen, setIsMissingParticipantsPopupOpen] = useState<boolean>(false);
  const [participantsValidation, setParticipantsValidation] = useState<ValidationState>({
    state: undefined,
    message: '',
  });

  useEffect(() => {
    if (participants.length === 0) {
      // TODO: Refactor this form and use this validation rule
      // setParticipantsValidation({ state: 'error', message: 'Invite at least one attendee' });
    } else {
      setParticipantsValidation({ state: 'success', message: 'Correct' });
    }
  }, [participants]);

  // This hooks deletes duplicate emails from optional participants
  useEffect(() => {
    const newOptionalParticipants = optionalParticipants.filter(
      (optionalParticipant) => !participants.find((participant) => participant.value === optionalParticipant.value),
    );

    if (optionalParticipants.length !== newOptionalParticipants.length) {
      setOptionalParticipants(newOptionalParticipants);
    }
  }, [participants]);

  // Optional Participants
  const optionalParticipantAutocompleteRef = useRef<AutocompletePeopleRef>(null);
  const [optionalParticipants, setOptionalParticipants] = useState<AutocompleteOption[]>(
    props.data.optionalParticipants.map((item) => ({
      label: item,
      value: item,
      isDisabled: props.data.votes.map((item) => item.participant).includes(item),
    })),
  );
  const optionalParticipantsRef = useRef<HTMLInputElement>(null);

  // This hooks deletes duplicate emails from the main participants field
  useEffect(() => {
    const newParticipants = participants.filter(
      (participant) =>
        !optionalParticipants.find((optionalParticipant) => optionalParticipant.value === participant.value),
    );

    if (participants.length !== newParticipants.length) {
      setParticipants(newParticipants);
    }
  }, [optionalParticipants]);

  // Meeting description
  const [meetingDescription, setMeetingDescription] = useState<string>(props.data.meetingDescription || '');
  const meetingDescriptionRef = useRef<HTMLTextAreaElement>(null);
  const [meetingDescriptionValidation, setMeetingDescriptionValidation] = useState<ValidationState>({
    state: undefined,
    message: '',
  });

  useEffect(() => {
    if (setMeetingDescription.length > charLimits.description) {
      setMeetingDescriptionValidation({
        state: 'error',
        message: `Meeting description should not be longer than ${charLimits.description} characters long`,
      });
    } else {
      setMeetingDescriptionValidation({ state: 'success', message: 'Correct' });
    }
  }, [meetingDescription]);

  const handleMeetingDescription = (value: string) => {
    if (value.length <= charLimits.description) {
      setMeetingDescription(value);
    }
  };

  const handleSave = async () => {
    participantAutocompleteRef.current?.parseQuery({ ignoreTrailingDelimeter: true });
    optionalParticipantAutocompleteRef.current?.parseQuery({ ignoreTrailingDelimeter: true });
    // The following line is crusial otherwise the participants array will be empty
    await sleep(0);

    const selectedParticipants = participantAutocompleteRef.current?.selectedOptions ?? [];
    const payload = {
      id: props.data.id,
      participants: selectedParticipants.map((participant) => participant.value),
      isPriorityMeeting,
      optionalParticipants: (optionalParticipantAutocompleteRef.current?.selectedOptions ?? []).map(
        (optionalParticipant) => optionalParticipant.value,
      ),
      meetingDescription: meetingDescription === '' ? '' : meetingDescription,
    };

    if (payload.participants.length === 0) {
      setIsMissingParticipantsPopupOpen(true);
      return;
    }

    const isOnlyVotedParticipantsRemained = selectedParticipants.filter((item) => !item.isDisabled).length === 0;

    return props.onSave(payload, { shouldSetMeeting: isOnlyVotedParticipantsRemained });
  };

  const errors = [participantsValidation, meetingDescriptionValidation].filter((item) => item.state === 'error');

  return (
    <Container>
      <ParticipantsField
        disabled={false}
        ref={participantAutocompleteRef}
        selectedOptions={participants}
        excludedOptions={optionalParticipants}
        onSelectedOptionsChange={setParticipants}
        placeholder="Invite attendees (type e-mail addresses)"
        focusOnElementRef={participantsRef}
        icon={<IconPeopleAdd onClick={() => participantsRef.current?.focus()} />}
        labelPosition="after"
        switchValue="Priority"
        switchCheck={isPriorityMeeting}
        onClick={() => setIsPriorityMeeting(!isPriorityMeeting)}
        cursorPointer
      />
      <AutocompletePeopleField
        disabled={false}
        ref={optionalParticipantAutocompleteRef}
        selectedOptions={optionalParticipants}
        excludedOptions={participants}
        onSelectedOptionsChange={setOptionalParticipants}
        placeholder="Invite optional attendees"
        focusOnElementRef={optionalParticipantsRef}
        icon={<IconOptionalPeopleAdd onClick={() => optionalParticipantsRef.current?.focus()} />}
        cursorPointer
      />
      <TextAreaField
        disabled={false}
        value={meetingDescription}
        setValue={handleMeetingDescription}
        placeholder="Add meeting description (Optional)"
        icon={
          <IconDescription onClick={() => meetingDescriptionRef.current && meetingDescriptionRef.current.focus()} />
        }
        focusOnElementRef={meetingDescriptionRef}
        cursorPointer
      />
      <Actions>
        <Button appearance="secondary" onClick={props.onClose}>
          Discard
        </Button>
        <Button appearance="primary" disabled={errors.length > 0} onClick={handleSave} icon={<IconSend />}>
          Save
        </Button>
      </Actions>
      <MissingParticipantsPopup
        isOpen={isMissingParticipantsPopupOpen}
        onClose={() => {
          setIsMissingParticipantsPopupOpen(false);
        }}
      />
    </Container>
  );
};

export default MeetingEditForm;
