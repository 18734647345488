import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from '@fluentui/react-components';
import { FC } from 'react';
import styled from 'styled-components';

const Body = styled(DialogBody)`
  text-align: center;
  margin-bottom: 15px;
`;

const Actions = styled(DialogActions)`
  display: flex;
  justify-content: center;
`;

const TextInContent = styled.div``;

export type MissingParticipantsPopupProps = {
  isOpen: boolean;
  onClose?: () => void;
};

const MissingParticipantsPopup: FC<MissingParticipantsPopupProps> = (props) => {
  return (
    <Dialog open={props.isOpen}>
      <DialogSurface>
        <Body>
          <DialogTitle>Invite at least one attendee.</DialogTitle>
          <DialogContent>
            <TextInContent>Please enter at least one attendee's email.</TextInContent>
            <TextInContent>Make sure you use valid addresses.</TextInContent>
          </DialogContent>
        </Body>
        <Actions>
          <Button appearance="primary" onClick={props.onClose}>
            Close
          </Button>
        </Actions>
      </DialogSurface>
    </Dialog>
  );
};

export default MissingParticipantsPopup;
