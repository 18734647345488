import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Link,
  ProgressBar,
  Spinner,
} from '@fluentui/react-components';
import { forwardRef, ReactElement, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { assignRef } from 'use-callback-ref';

import { MaybePromise, sleep } from '@/utils/promise';

const Body = styled(DialogBody)`
  text-align: center;
  margin-bottom: 15px;
`;

const Actions = styled(DialogActions)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  color: #8f9aa9;
  font-size: 14px;
  white-space: pre;
`;

const StyledLink = styled(Link)`
  color: orange;
  text-decoration: underline;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextInContent = styled.div``;

const OutlookLogo = styled.img`
  content: url('/microsoft-logo.svg');
  width: 32px;
  height: 32px;
`;

const GmailLogo = styled.img`
  content: url('/google-logo.svg');
  width: 32px;
  height: 32px;
`;

export type VotingAuthorizationPopupRef = {
  isLoading: boolean;
  isError: boolean;
  triggerMicrosoftAuth: () => Promise<void>;
  triggerGoogleAuth: () => Promise<void>;
};

export type VotingAuthorizationPopupProps = {
  isOpen: boolean;
  onSignIn?: (options: { providerType: 'microsoft' | 'google' }) => MaybePromise<void>;
  onSlotsFetching?: () => MaybePromise<void>;
  onClose?: () => void;
};

export const VotingAuthorizationPopup = forwardRef<VotingAuthorizationPopupRef, VotingAuthorizationPopupProps>(
  ({ onSignIn, onSlotsFetching, onClose, ...props }, ref): ReactElement => {
    const [isMicrosoftLoading, setIsMicrosoftLoading] = useState<boolean>(false);
    const [isGoogleLoading, setIsGoogleLoading] = useState<boolean>(false);
    const [isSlotsLoading, setIsSlotsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const [progressBarValue, setProgressBarValue] = useState<number>(0);

    // This is a hack that results in skipping one render to be able to access
    // all the new data within the onClose callback. If we'd call this function
    // directly we'd get an old state of the parent component.
    const [shouldClose, setShouldClose] = useState<boolean>(false);
    useEffect(() => {
      if (!shouldClose) return;

      onClose?.();
      setShouldClose(false);
    }, [shouldClose, onClose]);

    const handleSlotsFetching = async () => {
      // How long to wait
      const secondsToWait = 5;
      // How many percents to increment per tick (less ticks means less re-renders)
      const percentsPerTick = 0.02;

      const percentsPerSeconds = 1 / secondsToWait;
      const interval = (percentsPerTick / percentsPerSeconds) * 1000;

      const intervalId = setInterval(() => {
        setProgressBarValue((prevValue) => prevValue + percentsPerTick);
      }, interval);

      // Wait at least 5s before closing the popup
      await Promise.all([sleep(5000), onSlotsFetching?.()]);

      return () => clearInterval(intervalId);
    };

    const handleLogin = useCallback(
      async (providerType: 'microsoft' | 'google') => {
        let isSuccess = false;

        try {
          if (providerType === 'microsoft') {
            setIsMicrosoftLoading(true);
          } else if (providerType === 'google') {
            setIsGoogleLoading(true);
          }

          setIsError(false);

          await onSignIn?.({ providerType });

          setIsSlotsLoading(true);

          isSuccess = true;
        } catch (error) {
          setIsError(true);
        } finally {
          if (isSuccess) {
            await handleSlotsFetching();
            setShouldClose(true);
          }

          // Wait for the fade out animation to finish before resetting the loading state.
          setTimeout(() => {
            setIsMicrosoftLoading(false);
            setIsGoogleLoading(false);
            setIsSlotsLoading(false);
          }, 500);
        }
      },
      [onSignIn, onSlotsFetching],
    );

    const handleMicrosoftLogin = useCallback(async () => {
      await handleLogin('microsoft');
    }, [handleLogin]);

    const handleGoogleLogin = useCallback(async () => {
      await handleLogin('google');
    }, [handleLogin]);

    const handleManual = async () => {
      setShouldClose(true);
    };

    useEffect(() => {
      assignRef(ref, {
        isLoading: isSlotsLoading || isMicrosoftLoading || isGoogleLoading,
        isError,
        triggerMicrosoftAuth: handleMicrosoftLogin,
        triggerGoogleAuth: handleGoogleLogin,
      });
    }, [isError, isSlotsLoading, isMicrosoftLoading, isGoogleLoading, handleMicrosoftLogin, handleGoogleLogin]);

    return (
      <Dialog open={props.isOpen}>
        <DialogSurface>
          {isSlotsLoading ? (
            <Field validationMessage={'Pre-selecting available slots for you. Please wait....'} validationState="none">
              <ProgressBar thickness="large" value={progressBarValue} />
            </Field>
          ) : (
            <Wrapper>
              <Body>
                <DialogTitle>Welcome to Youkeeps</DialogTitle>
                <DialogContent>
                  <TextInContent>
                    Sign in to get your calendar synchronized and prevent overlapping events.
                  </TextInContent>
                </DialogContent>
              </Body>
              <Actions>
                <StyledButton
                  style={{ gap: '10px' }}
                  appearance="secondary"
                  size="large"
                  icon={<OutlookLogo />}
                  disabled={isMicrosoftLoading}
                  onClick={handleMicrosoftLogin}
                >
                  {!isMicrosoftLoading ? `Sign up with Microsoft` : <Spinner size="tiny" />}
                </StyledButton>
                <StyledButton
                  style={{ gap: '10px' }}
                  appearance="secondary"
                  size="large"
                  icon={<GmailLogo />}
                  disabled={isGoogleLoading}
                  onClick={handleGoogleLogin}
                >
                  {!isGoogleLoading ? `Sign up with Google` : <Spinner size="tiny" />}
                </StyledButton>
              </Actions>
              <Body style={{ marginTop: '15px', marginBottom: 0 }}>
                <DialogContent>
                  <TextInContent>{<StyledLink onClick={handleManual}>Stay signed out</StyledLink>}</TextInContent>
                </DialogContent>
              </Body>
            </Wrapper>
          )}
        </DialogSurface>
      </Dialog>
    );
  },
);
