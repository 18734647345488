import { Button } from '@fluentui/react-components';
import { motion } from 'framer-motion';

export const AnimatedChevron = motion(Button);
export const BaseAnimatedWrapper = motion.div;

export const AnimatedMeetingAdditionalInfoVariant = {
  open: {
    opacity: 1,
    display: 'block',
    y: 0,
    transition: { type: 'spring', stiffness: 500, damping: 15 },
  },
  closed: {
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
    y: 20,
    transition: { duration: 0.2 },
  },
};

export const AnimatedButtonVariant = {
  actions: {
    whileHover: { scale: 1.1 },
    whileTap: { scale: 0.9 },
  },
};
