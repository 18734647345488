import { makeStyles, mergeClasses, Switch, Text, tokens, Tooltip } from '@fluentui/react-components';
import { QuestionCircle24Regular as IconQuestion } from '@fluentui/react-icons';
import { FC, useState } from 'react';
import styled from 'styled-components';

import { useViewSize } from '../hooks/useViewSize';

const SwitchInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

// TODO: Anyway looks bad, need to fix width to mobile, now view is good only for tablets\desktops
const StyledSwitch = styled(Switch)<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  ${(props) => (props.isMobile ? 'width: 29%' : 'width: 13%')};
  label {
    display: flex;
    align-items: center;
    font-size: 1em;
    span {
      white-space: nowrap;
    }
  }

  :nth-child(3) {
    padding-left: 0;
  }
`;

export type MeetingPrioritySwitchProps = {
  labelPosition: 'above' | 'after' | 'before';
  value: string;
  switchCheck: boolean;
  onClick: () => void;
};

const useStyles = makeStyles({
  visible: {
    color: tokens.colorNeutralForeground2BrandSelected,
  },
});

const MeetingPrioritySwitch: FC<MeetingPrioritySwitchProps> = (props) => {
  const styles = useStyles();
  const [visible, setVisible] = useState(false);
  const viewSize = useViewSize();

  return (
    <>
      <StyledSwitch
        isMobile={viewSize.isMobile}
        labelPosition={props.labelPosition}
        label={
          <>
            {!viewSize.isMobile && (
              <SwitchInfoWrapper>
                <Text>Priority</Text>
              </SwitchInfoWrapper>
            )}
          </>
        }
        checked={props.switchCheck}
        onClick={props.onClick}
      />
      <Tooltip
        withArrow
        content={'The invitees will receive their invitations in the specified order.'}
        onVisibleChange={(e, data) => setVisible(data.visible)}
        relationship="label"
      >
        <IconQuestion
          style={{
            marginRight: '3px',
            width: '24px',
            height: '24px',
          }}
          className={mergeClasses(visible && styles.visible)}
        />
      </Tooltip>
    </>
  );
};

export default MeetingPrioritySwitch;
