import { FC } from 'react';

import { CommonProps } from '@/utils/props';

export type GoogleProviderProps = CommonProps;

export const GoogleProvider: FC<GoogleProviderProps> = ({ children }) => {
  // const googleClientId = import.meta.env['REACT_APP_GOOGLE_CLIENT_ID'];

  return children;
};
