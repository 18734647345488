import {
  Button,
  Menu,
  MenuItemRadio,
  MenuList,
  MenuOpenChangeData,
  MenuPopover,
  MenuTrigger,
  Theme,
  Tooltip,
} from '@fluentui/react-components';
import {
  BinRecycle24Regular as IconCancel,
  Drafts24Regular as IconDraft,
  MailArrowUp24Regular as MailArrow,
  MailClock24Regular as MailClock,
  MailInboxCheckmark20Regular as IconEarly,
  MoreHorizontal24Filled as IconMore,
} from '@fluentui/react-icons';
import { difference } from 'lodash';
import moment from 'moment';
import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { FreeDateMeeting } from '../../api/meetings';
import { AnimatedButtonVariant, BaseAnimatedWrapper } from '../../helpers/motions';
import { currentThemeString, StyledMenuActionButtonThemeConstructor, themeName } from '../../helpers/theming';
import { useStore } from '../../stores';
import { TeamsFxContext } from '../Context';
import { CancelMeetingPopup } from './CancelMeetingPopup';
import { EndVotingEarlyPopup } from './EndVotingEarlyPopup';

export interface MoreGroupProps {
  /** Whether the menu list is open. */
  isMoreOpen: boolean;
  /**
   * The useState callback that used to change the value of the isMoreOpen variable inside the component.
   */
  setIsMoreOpen: (isMoreOpen: boolean) => void;
  /**
   * The single meeting object
   */
  meeting: FreeDateMeeting;
  /** Whether the more menu is visible. */
  isMoreGroupVisible: boolean;
  /** Whether the more menu buttons is visible. */
  isCloseVisible: boolean;
  isEditVisible: boolean;
  isNudgeVisible: boolean;
  isCancelVisible: boolean;
  onEdit: () => void;
  isMobile: boolean;
  invitationsResendTime: string | undefined;
}

const MoreGroupContainer = styled.div`
  display: flex;
`;

const MeetingActions = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledMenuActionButton = styled(Button)<{ theme: Theme; $themeName: themeName }>`
  border-color: ${(props) => StyledMenuActionButtonThemeConstructor(props.theme).borderColor[props.$themeName]};
  &:hover {
    border-color: ${(props) =>
      StyledMenuActionButtonThemeConstructor(props.theme).borderColorOnHover[props.$themeName]};
  }
  color: ${(props) => StyledMenuActionButtonThemeConstructor(props.theme).color[props.$themeName]};
`;

export const MoreGroup: FC<MoreGroupProps> = (props): ReactElement => {
  const [isWarningPopUpOpen, setIsWarningPopUpOpen] = useState<boolean>(false);
  const [isCancelMeetingPopUpOpen, setIsCancelMeetingPopUpOpen] = useState<boolean>(false);
  const [isResendPossible, setIsResendPossible] = useState<boolean>(true);
  const { appStore } = useStore();
  const { theme, themeString } = useContext(TeamsFxContext);

  useEffect(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const isResendPossible =
      !props.invitationsResendTime || moment.tz(moment(props.invitationsResendTime), timeZone).isBefore(moment());

    if (!isResendPossible) setIsResendPossible(false);
  }, [props.invitationsResendTime, setIsResendPossible]);

  const onOpenChange = (data: MenuOpenChangeData) => {
    if (data.type === 'menuTriggerClick') {
      props.setIsMoreOpen(!props.isMoreOpen);
    }
    return;
  };

  const handleEndVotingEarlyPopUpClick = () => {
    setIsWarningPopUpOpen(!isWarningPopUpOpen);
    props.setIsMoreOpen(!props.isMoreOpen);
  };

  const handleCancelMeetingPopUpClick = () => {
    setIsCancelMeetingPopUpOpen(!isCancelMeetingPopUpOpen);
    props.setIsMoreOpen(!props.isMoreOpen);
  };

  const handleEndVotingEarlyMenuClick = async () => {
    const payload = {
      meetingId: props.meeting.id,
    };
    await appStore.postEndVotingEarly(payload);
    handleEndVotingEarlyPopUpClick();
  };

  const handleEditClick = () => {
    props.onEdit();
    props.setIsMoreOpen(false);
  };

  const handleCancelMeetingClick = async () => {
    const payload = {
      meetingId: props.meeting.id,
    };
    await appStore.postCancelMeeting(payload);
    handleCancelMeetingPopUpClick();
  };

  const handleNudgeClick = async () => {
    const payload = {
      meetingId: props.meeting.id,
    };

    const res = await appStore.postResendInvitations(payload);
    if (res) {
      setIsResendPossible(false);
    }
  };

  return (
    <>
      <EndVotingEarlyPopup
        open={isWarningPopUpOpen}
        onYes={() => handleEndVotingEarlyMenuClick()}
        onNo={() => handleEndVotingEarlyPopUpClick()}
        attendees={difference(
          props.meeting.participants,
          props.meeting.votes.map((vote) => vote.participant),
        )}
      />
      <CancelMeetingPopup
        open={isCancelMeetingPopUpOpen}
        onYes={() => handleCancelMeetingClick()}
        onNo={() => handleCancelMeetingPopUpClick()}
      />
      {props.isMoreGroupVisible && (
        <MoreGroupContainer>
          {props.isMobile ? (
            <Menu positioning={'below-end'} open={props.isMoreOpen} onOpenChange={(e, data) => onOpenChange(data)}>
              <MenuTrigger disableButtonEnhancement>
                <Button icon={<IconMore />} appearance="transparent" />
              </MenuTrigger>

              <MenuPopover>
                <MenuList>
                  {props.isEditVisible && (
                    <MenuItemRadio
                      name="action"
                      icon={<IconDraft />}
                      value={'edit'}
                      checkmark={null}
                      onClick={handleEditClick}
                    >
                      Edit
                    </MenuItemRadio>
                  )}
                  {props.isCloseVisible && (
                    <MenuItemRadio
                      name="action"
                      icon={<IconEarly />}
                      value={'endVoting'}
                      checkmark={null}
                      onClick={() => setIsWarningPopUpOpen(!isWarningPopUpOpen)}
                    >
                      Close
                    </MenuItemRadio>
                  )}
                  {props.isNudgeVisible && (
                    <MenuItemRadio
                      name="action"
                      disabled={!isResendPossible}
                      icon={isResendPossible ? <MailArrow /> : <MailClock />}
                      value={'nudge'}
                      checkmark={null}
                      onClick={() => handleNudgeClick()}
                    >
                      Nudge
                    </MenuItemRadio>
                  )}
                  {props.isCancelVisible && (
                    <MenuItemRadio
                      name="action"
                      disabled={!isResendPossible}
                      icon={<IconCancel />}
                      value={'cancel'}
                      checkmark={null}
                      onClick={() => setIsCancelMeetingPopUpOpen(!isCancelMeetingPopUpOpen)}
                    >
                      Cancel
                    </MenuItemRadio>
                  )}
                </MenuList>
              </MenuPopover>
            </Menu>
          ) : (
            <MeetingActions>
              {props.isCloseVisible && (
                <Tooltip content={'Close voting'} relationship="description" withArrow>
                  <BaseAnimatedWrapper
                    whileHover={AnimatedButtonVariant.actions.whileHover}
                    whileTap={AnimatedButtonVariant.actions.whileTap}
                  >
                    <StyledMenuActionButton
                      onClick={() => setIsWarningPopUpOpen(!isWarningPopUpOpen)}
                      icon={<IconEarly />}
                      shape="circular"
                      appearance="outline"
                      theme={theme}
                      $themeName={themeName[currentThemeString(themeString)]}
                    >
                      Close
                    </StyledMenuActionButton>
                  </BaseAnimatedWrapper>
                </Tooltip>
              )}
              {props.isEditVisible && (
                <Tooltip content={'Edit meeting'} relationship="description" withArrow>
                  <BaseAnimatedWrapper
                    whileHover={AnimatedButtonVariant.actions.whileHover}
                    whileTap={AnimatedButtonVariant.actions.whileTap}
                  >
                    <StyledMenuActionButton
                      onClick={handleEditClick}
                      icon={<IconDraft />}
                      shape="circular"
                      appearance="outline"
                      theme={theme}
                      $themeName={themeName[currentThemeString(themeString)]}
                    >
                      Edit
                    </StyledMenuActionButton>
                  </BaseAnimatedWrapper>
                </Tooltip>
              )}
              {props.isNudgeVisible && (
                <Tooltip
                  content={
                    isResendPossible
                      ? 'Nudge pending voters'
                      : 'Whoa! Looks like you have recently nudged pending voters, try again in 2 hours.'
                  }
                  relationship="description"
                  withArrow
                >
                  <BaseAnimatedWrapper
                    whileHover={AnimatedButtonVariant.actions.whileHover}
                    whileTap={AnimatedButtonVariant.actions.whileTap}
                  >
                    <StyledMenuActionButton
                      onClick={() => handleNudgeClick()}
                      disabled={!isResendPossible}
                      appearance={'outline'}
                      shape={'circular'}
                      icon={isResendPossible ? <MailArrow /> : <MailClock />}
                      theme={theme}
                      $themeName={themeName[currentThemeString(themeString)]}
                    >
                      Nudge
                    </StyledMenuActionButton>
                  </BaseAnimatedWrapper>
                </Tooltip>
              )}
              {props.isCancelVisible && (
                <Tooltip content={'Cancel meeting'} relationship="description" withArrow>
                  <BaseAnimatedWrapper
                    whileHover={AnimatedButtonVariant.actions.whileHover}
                    whileTap={AnimatedButtonVariant.actions.whileTap}
                  >
                    <StyledMenuActionButton
                      onClick={() => setIsCancelMeetingPopUpOpen(!isCancelMeetingPopUpOpen)}
                      icon={<IconCancel />}
                      shape="circular"
                      appearance="outline"
                      theme={theme}
                      $themeName={themeName[currentThemeString(themeString)]}
                    >
                      Cancel
                    </StyledMenuActionButton>
                  </BaseAnimatedWrapper>
                </Tooltip>
              )}
            </MeetingActions>
          )}
        </MoreGroupContainer>
      )}
    </>
  );
};
