import { Textarea } from '@fluentui/react-components';
import { FC, ReactElement, RefObject } from 'react';
import styled from 'styled-components';

import IconWrapper from './IconWrapper';
import PaddedField from './PaddedField';

interface TextAreaProps {
  value: string;
  setValue: (value: string) => void;
  disabled: boolean;
  icon: ReactElement;
  placeholder: string;
  tooltip?: string;
  focusOnElementRef?: RefObject<HTMLTextAreaElement> | null;
  cursorPointer?: boolean;
}

const StyledTextArea = styled(Textarea)`
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  height: 65px;
  border-color: lightgray;
  width: 100%;
`;

const TextAreaField: FC<TextAreaProps> = (props): ReactElement => (
  <PaddedField title={props.tooltip}>
    <IconWrapper cursor={props.cursorPointer ? 'pointer' : ''} textArea>
      {props.icon}
    </IconWrapper>
    <StyledTextArea
      disabled={props.disabled}
      size="large"
      appearance="outline"
      value={props.value}
      onChange={(_, data) => props.setValue(data.value)}
      placeholder={props.placeholder}
      resize="none"
      ref={props.focusOnElementRef}
    />
  </PaddedField>
);

export default TextAreaField;
