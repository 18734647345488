import { FC, ReactElement, RefObject } from 'react';
import styled from 'styled-components';

import InputField from './InputField';
import MeetingTypeSwitch from './MeetingTypeSwitch';

const LocationFieldWrapper = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    gap: 40px;
  }
`;

interface LocationFieldProps {
  value: string;
  setValue: (value: string) => void;
  disabled: boolean;
  placeholder: string;
  tooltip?: string;
  icon: ReactElement;
  labelPosition: 'above' | 'after' | 'before';
  switchValue: string;
  switchCheck: boolean;
  onClick: () => void;
  focusOnElementRef?: RefObject<HTMLInputElement> | null;
  cursorPointer?: boolean;
}

const LocationField: FC<LocationFieldProps> = (props): ReactElement => {
  return (
    <LocationFieldWrapper>
      <InputField
        disabled={props.disabled}
        focusOnElementRef={props.focusOnElementRef}
        value={props.value}
        setValue={props.setValue}
        placeholder={props.placeholder}
        tooltip={props.tooltip}
        icon={props.icon}
        cursorPointer={props.cursorPointer}
      />
      <MeetingTypeSwitch
        labelPosition={props.labelPosition}
        value={props.switchValue}
        switchCheck={props.switchCheck}
        onClick={props.onClick}
      />
    </LocationFieldWrapper>
  );
};

export default LocationField;
