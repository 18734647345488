import type { StackAlignContent, StackAlignItems, StackAlignSelf, StackDirections, StackJustifyContent } from './types';

export const getFlexProps = (
  input?: StackAlignContent | StackAlignItems | StackAlignSelf | StackJustifyContent,
): string | undefined => {
  switch (input) {
    case 'inherit': {
      return 'inherit';
    }

    case 'start': {
      return 'flex-start';
    }

    case 'end': {
      return 'flex-end';
    }

    case 'center': {
      return 'center';
    }

    case 'fill': {
      return 'stretch';
    }

    case 'spaceBetween': {
      return 'space-between';
    }

    case 'spaceAround': {
      return 'space-around';
    }

    case 'spaceEvenly': {
      return 'space-evenly';
    }

    case 'baseline': {
      return 'baseline';
    }

    case 'stretch': {
      return 'stretch';
    }
    default:
  }
};

export const getFlexDirection = (direction?: StackDirections): string => {
  switch (direction) {
    case 'inherit': {
      return 'inherit';
    }

    case 'up': {
      return 'column-reverse';
    }

    case 'down': {
      return 'column';
    }

    case 'start': {
      return 'row-reverse';
    }

    case 'end':
    default: {
      return 'row';
    }
  }
};
