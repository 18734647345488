import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Spinner,
  Subtitle2Stronger,
} from '@fluentui/react-components';
import { FC, ReactElement, useState } from 'react';
import styled from 'styled-components';

const Body = styled(DialogBody)`
  text-align: center;
  margin-bottom: 15px;
`;

const Actions = styled(DialogActions)`
  display: flex;
  justify-content: center;
`;

const TextInContent = styled.div``;

interface LimitPopupProps {
  open: boolean;
  onAdd: () => void;
  onConfirm: () => Promise<void>;
  selectedSlotIds: string[];
  availableSlotsAmount: number;
}

const LimitPopup: FC<LimitPopupProps> = (props): ReactElement => {
  const [isYesClicked, setIsYesClicked] = useState<boolean>(false);
  return (
    <Dialog open={props.open}>
      <DialogSurface>
        <Body>
          <DialogTitle>The number of slots is too limited.</DialogTitle>
          <DialogContent>
            <TextInContent>
              <Subtitle2Stronger>{props.selectedSlotIds.length}</Subtitle2Stronger> slots are usually not enought for
              succeffull scheduling, consider selecting at least{' '}
              <Subtitle2Stronger>{Math.ceil(props.availableSlotsAmount * 0.3)}</Subtitle2Stronger> slots.
            </TextInContent>
            <TextInContent>Are you certain you want to proceed with this selection?</TextInContent>
          </DialogContent>
        </Body>
        <Actions>
          <Button
            appearance="secondary"
            disabled={isYesClicked}
            onClick={() => {
              props.onConfirm();
              setIsYesClicked(true);
            }}
          >
            {!isYesClicked ? `Yes` : <Spinner size="tiny" />}
          </Button>
          <Button appearance="primary" disabled={isYesClicked} onClick={props.onAdd}>
            Add more slots
          </Button>
        </Actions>
      </DialogSurface>
    </Dialog>
  );
};

export default LimitPopup;
