import { observer } from 'mobx-react-lite';
import { FC, PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';

import { useIsEmbeddedLayout } from '../hooks/useIsEmbeddedLayou';
import { LayoutBase } from './BaseLayout';
import { EmbeddedLayout } from './EmbeddedLayout';
import { FullLayout } from './FullLayout';

export type MainLayoutProps = PropsWithChildren;

export const MainLayout: FC<MainLayoutProps> = observer(() => {
  const isEmbeddedLayout = useIsEmbeddedLayout();

  if (isEmbeddedLayout) {
    return (
      <LayoutBase>
        <EmbeddedLayout>
          <Outlet />
        </EmbeddedLayout>
      </LayoutBase>
    );
  } else {
    return (
      <LayoutBase>
        <FullLayout>
          <Outlet />
        </FullLayout>
      </LayoutBase>
    );
  }
});
