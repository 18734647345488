import moment from 'moment-timezone';

import { FreeDateMeeting, Status } from '../api/meetings';
import { CalendarEvent } from '../components/Calendar';

interface generateSlotsProps {
  interval: string;
  events: CalendarEvent[];
  slots: CalendarEvent[];
  workingDays: number[];
  workingTime: [number, number];
}

interface reArrangeSlotsProps {
  interval: string;
  events: CalendarEvent[];
  slots: CalendarEvent[];
  workingTime: [number, number];
}
interface SplitEventsIntoSlotsProps {
  interval: string;
  events: CalendarEvent[];
}

export const generateSlots = ({ interval, events, slots, workingDays, workingTime }: generateSlotsProps) => {
  const startDate = moment();
  const endDate = moment().add(1, 'week').hour(workingTime[1]);

  const timeSlots: CalendarEvent[] = [];
  const range = moment.duration(interval).asMinutes();

  const intervalMilliseconds = 30 * 60 * 1000;
  const cursor = moment(Math.ceil(Number(startDate.format('x')) / intervalMilliseconds) * intervalMilliseconds);

  let tempEvents = [...events, ...slots].filter((item) =>
    moment.utc(item.start).isBetween(startDate, endDate, undefined, '[)'),
  );
  do {
    if (!workingDays.includes(cursor.weekday()) || cursor.hour() < workingTime[0] || cursor.hour() >= workingTime[1]) {
      cursor.add(30, 'minutes');
    } else {
      const newTimeSlot = {
        start: cursor.toISOString(),
        end: moment(cursor).add(range, 'minutes').toISOString(),
      };
      tempEvents = tempEvents.filter((item) => moment.utc(item.end).isAfter(moment(newTimeSlot.start)));

      const currentOverlappingEvents = tempEvents.filter(
        (item) =>
          moment.utc(item.start).isBetween(moment(newTimeSlot.start), moment(newTimeSlot.end), undefined, '[)') ||
          moment.utc(item.end).isBetween(moment(newTimeSlot.start), moment(newTimeSlot.end), undefined, '(]') ||
          moment(newTimeSlot.start).isBetween(moment.utc(item.start), moment.utc(item.end)),
      );

      if (
        currentOverlappingEvents.length === 0 &&
        moment(newTimeSlot.end).isSameOrBefore(moment(cursor).hour(workingTime[1]).minute(0))
      ) {
        timeSlots.push(newTimeSlot);
        cursor.add(range, 'minutes');
      } else {
        cursor.add(30, 'minutes');
      }
    }
  } while (cursor.isBefore(moment(endDate)));

  return timeSlots;
};

export const reArrangeSlots = ({ interval, events, slots, workingTime }: reArrangeSlotsProps) => {
  const newTimeSlots: CalendarEvent[] = [];
  const range = moment.duration(interval).asMinutes();

  let tempEvents =
    slots.length > 0
      ? events.filter((item) =>
          moment
            .utc(item.start)
            .isBetween(
              moment(slots[0].start),
              moment(slots[slots.length - 1].end).add(range, 'minutes'),
              undefined,
              '[)',
            ),
        )
      : [];

  for (const slot of slots) {
    const intervalMilliseconds = 30 * 60 * 1000;
    const cursor = moment(
      Math.ceil(Number(moment(slot.start).format('x')) / intervalMilliseconds) * intervalMilliseconds,
    );
    do {
      const newTimeSlot = {
        start: cursor.toISOString(),
        end: moment(cursor).add(range, 'minutes').toISOString(),
      };

      tempEvents = [...tempEvents, ...newTimeSlots].filter((item) =>
        moment.utc(item.end).isAfter(moment(newTimeSlot.start)),
      );

      const currentOverlappingEvents = tempEvents.filter(
        (item) =>
          moment.utc(item.start).isBetween(moment(newTimeSlot.start), moment(newTimeSlot.end), undefined, '[)') ||
          moment.utc(item.end).isBetween(moment(newTimeSlot.start), moment(newTimeSlot.end), undefined, '(]') ||
          moment(newTimeSlot.start).isBetween(moment.utc(item.start), moment.utc(item.end)),
      );

      if (
        currentOverlappingEvents.length === 0 &&
        moment(newTimeSlot.end).isSameOrBefore(moment(slot.start).hour(workingTime[1]).minute(0))
      ) {
        newTimeSlots.push({
          start: newTimeSlot.start,
          end: newTimeSlot.end,
          title:
            events.find((event) => slots.find((slot) => moment(slot.start).isSame(moment.utc(event.start))))?.title ||
            'Redacted',
        });
        cursor.add(range, 'minutes');
      } else {
        cursor.add(30, 'minutes');
      }
    } while (cursor.isBefore(moment(slot.end)));
  }

  return newTimeSlots;
};

export const splitEventsIntoSlots = ({ interval, events }: SplitEventsIntoSlotsProps) => {
  const slots: CalendarEvent[] = [];
  const range = moment.duration(interval).asMinutes();

  for (const event of events) {
    const eventStart = moment.utc(event.start);
    const eventEnd = moment.utc(event.end);

    const adjustedStart = eventStart
      .clone()
      .startOf('minute')
      .minute(Math.floor(eventStart.minute() / range) * range);

    const adjustedEnd = eventEnd
      .clone()
      .endOf('minute')
      .minute(Math.ceil(eventEnd.minute() / range) * range);

    const cursor = adjustedStart.clone();

    if (adjustedEnd.isBefore(adjustedStart)) {
      continue;
    }

    while (cursor.isBefore(adjustedEnd)) {
      const slotEnd = cursor.clone().add(range, 'minutes');

      if (slotEnd.isAfter(adjustedEnd)) {
        break;
      }

      slots.push({
        title: event.title,
        start: cursor.toISOString(),
        end: slotEnd.toISOString(),
      });

      cursor.add(range, 'minutes');
    }
  }

  return slots;
};

/**
 * This helper should be used in filtering of meeting list as filtering helper
 * @param event Single meeting object
 * @returns Should meeting appear in Active meetings tab or not
 */
export const activeMeetingsFilterHelper = (event: FreeDateMeeting) => {
  // Not expired IN_APPROVAL, APPROVED and FORCE_APPROVED.

  // для in_approval оставить как есть а для confirmed и force_approved сделать проверку
  // не по началу последнего слота а по началу виннинг слота
  const isMeetingNotExpired = moment
    .unix(Math.max(...event.slots.map((item) => moment(item.start).unix())))
    .isAfter(moment());

  const isApprovedMeetingNotExpired = moment.unix(moment(event.winningSlot?.start).unix()).isAfter(moment());

  return (
    (event.status === Status.IN_APPROVAL && isMeetingNotExpired) ||
    ((event.status === Status.APPROVED || event.status === Status.FORCE_APPROVED) && isApprovedMeetingNotExpired)
  );
};

/**
 * This helper should be used in filtering of meeting list as filtering helper
 * @param event Single meeting object
 * @param sortMeetingType Current sort meeting type selected by user
 * @returns Should meeting appear in user selected meeting sort tab or not
 */
export const defaultFilteredEventsHelper = (event: FreeDateMeeting, sortMeetingType: unknown) => {
  switch (sortMeetingType) {
    case Status.APPROVED: {
      // Not expired APPROVED and FORCE APPROVED
      return event.status === Status.APPROVED || event.status === Status.FORCE_APPROVED;
    }
    case Status.EXPIRED: {
      return event.status === Status.EXPIRED || event.status === Status.CANCELLED;
    }
  }
};
