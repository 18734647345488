import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { useStore } from '@/stores';

export const MainPage: FC = observer(() => {
  const { appStore } = useStore();
  const { authStore } = appStore;

  if (!authStore.initialized) return null;

  if (authStore.isSignedIn) {
    return <Navigate to="/create-meeting" replace />;
  } else {
    return <Navigate to="/sign-in" replace />;
  }
});
