import { Theme } from '@fluentui/react-components';
import { styled } from 'styled-components';

import { ContainerThemeConstructor, themeName } from '@/helpers/theming';

import { Stack } from '../Stack';

export const StyledRootDiv = styled(Stack)<{
  $theme?: Theme;
  $themeName: themeName;
  $paddingString: string;
}>`
  padding: ${(props) => props.$paddingString};
  /* max-width: 1000px; */
  /* margin: 0 auto; */
  border-radius: 10px;
  background-color: ${(props) =>
    props.$theme ? ContainerThemeConstructor(props.$theme).backgroundColor[props.$themeName] : '#fff'};
`;
