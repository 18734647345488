import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogSurface,
  DialogTitle,
  Spinner,
} from '@fluentui/react-components';
import { FC, ReactElement, useState } from 'react';
import styled from 'styled-components';

const Body = styled(DialogBody)`
  text-align: center;
  margin-bottom: 15px;
`;

const Actions = styled(DialogActions)`
  display: flex;
  justify-content: center;
`;

interface CancelMeetingPopupProps {
  /** Whether the popup is open. */
  open: boolean;
  /**
   * The callback is executed when user clicks "No" Button.
   */
  onNo: () => void;
  /**
   * The callback is executed when user clicks "Yes" Button.
   */
  onYes: () => Promise<void>;
}

export const CancelMeetingPopup: FC<CancelMeetingPopupProps> = (props): ReactElement => {
  const [isYesClicked, setIsYesClicked] = useState<boolean>(false);
  return (
    <Dialog open={props.open}>
      <DialogSurface>
        <Body>
          <DialogTitle>Are you sure you want to cancel this meeting?</DialogTitle>
        </Body>
        <Actions>
          <Button
            appearance="secondary"
            disabled={isYesClicked}
            onClick={() => {
              props.onYes();
              setIsYesClicked(true);
            }}
          >
            {!isYesClicked ? `Yes` : <Spinner size="tiny" />}
          </Button>
          <Button disabled={isYesClicked} appearance="primary" onClick={props.onNo}>
            No
          </Button>
        </Actions>
      </DialogSurface>
    </Dialog>
  );
};
