export const charLimits = {
  title: 500,
  location: 500,
  description: 5000,
};

export const workingDays = [1, 2, 3, 4, 5];

export enum MyMeetingsSort {
  ACTIVE_MEETINGS = 'activeMeetings',
  APPROVED = 'APPROVED',
  EXPIRED = 'expired',
  ALL_MEETINGS = 'allMeetings',
}

export const workingTime: [number, number] = [8, 19];
