import { ProviderFeature } from '@/shared';

import { get, post } from './helpers';

export const getCurrentSession: () => Promise<{
  data: { user?: any; integration?: { providerType: 'microsoft' | 'google' } };
}> = async () => await get('getCurrentSession', {}, { isExternal: true });

export const getMicrosoftAuthUrl = (payload: { features?: ProviderFeature[]; loginHint?: string } = {}) =>
  get<{ data: { url: string } }>('getMicrosoftAuthUrl', payload);

export const getMicrosoftAccessToken = (payload: { code: string }) =>
  post<{ data: { tokenSaveId: string; profile: { email: string } } }>('getMicrosoftAccessToken', payload);

export const getGoogleAuthUrl = (
  payload: { features?: ProviderFeature[]; loginHint?: string; forceConsent?: boolean } = {},
) => get<{ data: { url: string } }>('getGoogleAuthUrl', payload);

export const getGoogleAccessToken = (payload: { code: string }) =>
  post<{ data: { tokenSaveId: string; profile: { email: string } } }>('getGoogleAccessToken', payload);

export const signInWithMicrosoftToken: (payload: { accessToken?: string; code?: string }) => Promise<{
  data: { csrfToken: string; user: any; integration?: { providerType: 'microsoft' | 'google' } };
}> = async (payload) => await post('signInWithMicrosoftToken', payload);

export const signInWithGoogleToken: (payload: { code: string }) => Promise<{
  data: { csrfToken: string; user: any; integration?: { providerType: 'microsoft' | 'google' } };
}> = async (payload) => await post('signInWithGoogleToken', payload);

export const signOut: () => Promise<{ data: { csrfToken: string; user: any } }> = async () =>
  await post('signOut', {}, { isExternal: true });
