import { isNil } from 'lodash';

type EmptyString = '' | null | undefined;
type EmptyObject = Record<string, never> | null | undefined;
type EmptyArray = never[] | null | undefined;

type EmptyType = EmptyString | EmptyObject | EmptyArray;
type EmptyReturnType<T> = T extends string
  ? EmptyString
  : T extends any[]
    ? EmptyArray
    : T extends object
      ? EmptyObject
      : never;

export const isEmpty = <T extends string | any[] | object>(value: T | EmptyType): value is EmptyReturnType<T> => {
  if (isNil(value)) {
    return true;
  } else if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  } else if (typeof value === 'string') {
    return value.trim() === '';
  }

  return false;
};
