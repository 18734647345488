import { ReusableAbortController } from 'abort-utils';
import axios, { AxiosInstance, ResponseType } from 'axios';
import axiosRetry from 'axios-retry';
import Cookies from 'js-cookie';
import qs from 'qs';

import { type AppStore as AppStoreBase } from '@/store/AppStore';
import { keepNonNullish } from '@/utils/array';
import { environment } from '@/utils/config';
import { getErrorCode } from '@/utils/error';

import { RequestAbortError } from '../errors/RequestAbortError';
import { captureException } from '../helpers/sentry';

export type RequestController = AbortController | ReusableAbortController;

enum RequestMethod {
  get = 'get',
  post = 'post',
  put = 'put',
  patch = 'patch',
  delete = 'delete',
}

const config = {
  initiateLoginEndpoint: import.meta.env['REACT_APP_START_LOGIN_PAGE_URL'],
  clientId: import.meta.env['REACT_APP_CLIENT_ID'],
  apiEndpoint: import.meta.env['REACT_APP_FUNC_ENDPOINT'],
};

let AppStore = null as unknown as AppStoreBase;

export function setAppStore(store: any) {
  AppStore = store;
}

const createExternalApiClient = (apiEndpoint: string): AxiosInstance => {
  const instance = axios.create({
    baseURL: apiEndpoint,
  });

  axiosRetry(instance, {
    retries: 3,
    retryCondition: (error) => {
      const errorCode = getErrorCode(error);
      return errorCode === 'SESSION_EXPIRED_ERROR';
    },
    onRetry: async () => {
      await AppStore.authStore.refreshSession();
    },
  });

  return instance;
};

const baseRequest = async <TReturn>(
  requestType: RequestMethod,
  functionName: string,
  body?: any,
  options: {
    /** @deprecated All request are external now */
    isExternal?: boolean;
    responseType?: ResponseType;
    controller?: RequestController;
  } = {},
): Promise<TReturn> => {
  const { responseType, controller } = options;

  let apiClient: AxiosInstance;
  try {
    const { microsoftStore, googleStore } = AppStore ?? {};

    const apiBaseUrl = config.apiEndpoint + '/api/';
    apiClient = createExternalApiClient(apiBaseUrl);

    if (apiClient) {
      const sessionEnvironment = environment !== 'production' ? environment : undefined;
      const sessionSandbox = microsoftStore.isMicrosoftTeams
        ? 'ms365'
        : googleStore.isGoogleAppsScript
          ? 'apps-script'
          : undefined;
      const sessionTag = [sessionEnvironment, sessionSandbox].filter(keepNonNullish).join('-');
      console.log({
        isMicrosoftTeams: microsoftStore.isMicrosoftTeams,
        isGoogleAppsScript: googleStore.isGoogleAppsScript,
        test: sessionStorage.getItem('isGoogleAppsScript'),
      });
      const csrfToken = Cookies.get(sessionTag ? `csrfToken-${sessionTag}` : 'csrfToken');

      const params =
        requestType === RequestMethod.get && body
          ? qs.stringify(body, { arrayFormat: 'indices', encode: false, addQueryPrefix: true })
          : '';

      const response = await apiClient<TReturn>({
        method: requestType,
        url: functionName + params,
        headers: {
          'X-CSRF-Token': csrfToken,
          'X-Session-Tag': sessionTag,
        },
        data: body,
        signal: controller?.signal,
        responseType,
        withCredentials: true,
      });
      return response.data;
    } else {
      throw new Error('Api client failed to initialize');
    }
  } catch (error: unknown) {
    if (axios.isCancel(error)) {
      throw new RequestAbortError();
    }

    captureException(error);

    if (axios.isAxiosError(error)) {
      const errorCode = getErrorCode(error);

      const isInsufficientPermissionsError = errorCode === 'INTEGRATION_ACCESS_INSUFFICIENT_PERMISSIONS_ERROR';
      const isMissingSessionError = errorCode === 'SESSION_NOT_FOUND_ERROR';

      let funcErrorMsg = '';

      if (AppStore.authStore.isSignedIn) {
        if (isInsufficientPermissionsError) {
          // TODO: Apply retry logic to consent popup as well (the same as we did with the session refresh)
          AppStore.needConsent = true;
        } else if (isMissingSessionError) {
          await AppStore.authStore.signOut();
        }
      }

      if (error?.response?.status === 404) {
        funcErrorMsg = `There may be a problem with the deployment of Azure Function App, please deploy Azure Function (Run command palette "Teams: Deploy") first before running this App`;
      } else if (error.message === 'Network Error') {
        funcErrorMsg =
          'Cannot call Azure Function due to network error, please check your network connection status and ';
        if (error.config?.url && error.config.url.indexOf('localhost') >= 0) {
          funcErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
        } else {
          funcErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision" and "Teams: Deploy") first before running this App`;
        }
      } else {
        funcErrorMsg = error.message;
        if (error.response?.data?.error) {
          funcErrorMsg += ': ' + error.response.data.error;
        }
      }

      throw new Error(funcErrorMsg, { cause: { code: errorCode, status: error?.response?.status } });
    }

    throw error;
  }
};

export const get = async <TReturn>(
  functionName: string,
  payload?: any,
  options?: { isExternal?: boolean; responseType?: ResponseType; controller?: RequestController },
) => baseRequest<TReturn>(RequestMethod.get, functionName, payload, options);

export const post = async <TReturn>(
  functionName: string,
  payload: any,
  options?: { isExternal?: boolean; responseType?: ResponseType; controller?: RequestController },
) => baseRequest<TReturn>(RequestMethod.post, functionName, payload, options);
// Not 'delete' because it's reserved
export const deleteHttp = async <TReturn>(
  functionName: string,
  payload: any,
  options?: { isExternal?: boolean; responseType?: ResponseType; controller?: RequestController },
) => baseRequest<TReturn>(RequestMethod.delete, functionName, payload, options);
