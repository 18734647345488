import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Spinner,
} from '@fluentui/react-components';
import { FC, ReactElement, useState } from 'react';
import styled from 'styled-components';

const Body = styled(DialogBody)`
  text-align: center;
  margin-bottom: 15px;
`;

const Actions = styled(DialogActions)`
  display: flex;
  justify-content: center;
`;

const TextInContent = styled.div``;

interface LimitPopupProps {
  open: boolean;
  onNo: () => void;
  onYes: () => Promise<void>;
}

const EndVotingPopup: FC<LimitPopupProps> = (props): ReactElement => {
  const [isYesClicked, setIsYesClicked] = useState<boolean>(false);
  return (
    <Dialog open={props.open}>
      <DialogSurface>
        <Body>
          <DialogTitle>
            Are you sure that you want to confirm the meeting now? Not all attendees have voted.
          </DialogTitle>
          <DialogContent>
            <TextInContent>
              All pending attendees will be removed and the meeting will be confirmed to the closest common slot based
              on current votes.
            </TextInContent>
            <TextInContent>Only current attendees will recieve the email confirmation.</TextInContent>
          </DialogContent>
        </Body>
        <Actions>
          <Button
            appearance="secondary"
            disabled={isYesClicked}
            onClick={() => {
              props.onYes();
              setIsYesClicked(true);
            }}
          >
            {!isYesClicked ? `Yes` : <Spinner size="tiny" />}
          </Button>
          <Button disabled={isYesClicked} appearance="primary" onClick={props.onNo}>
            No
          </Button>
        </Actions>
      </DialogSurface>
    </Dialog>
  );
};

export default EndVotingPopup;
