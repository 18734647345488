import throttle from 'lodash/throttle';
import { DependencyList, useCallback, useEffect, useRef } from 'react';

/**
 * This hook applies throttle to the provided function.
 * With that, it also prevents the throttle wait timer to be reset when deps list changes.
 */
export const useThrottleCallback = <TCallback extends (...args: any) => any>(
  callback: TCallback,
  timeout: number,
  deps: DependencyList,
): ((...args: Parameters<TCallback>) => ReturnType<TCallback> | undefined) => {
  // Create a reference that will hold the callback function
  const ref = useRef(callback);

  // Every time deps are changed update the reference with a new instance of the
  // callback
  useEffect(() => {
    ref.current = callback;
  }, deps);

  // The first useCallback have empty deps list to prevent it from re-creating.
  // Otherwise we would lose the internal timer of the underlying function from
  // lodash.
  const invokeFn = useCallback(
    // Keeping it as a nested function is important.
    throttle((...args) => {
      // We use reference because we cannot re-create this function therefore
      // we're forced to update the reference instead.
      return ref.current(...args);
    }, timeout),
    [],
  );

  // The second useCallback is a wrapper around the first one. The sole purpose
  // of it is to create a new instance of wrapper each time deps list is
  // updated. This way we comply with common rules of building react hooks.
  return useCallback((...args: Parameters<TCallback>) => invokeFn(...args), deps);
};
