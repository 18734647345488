import isNaN from 'lodash/isNaN';

/**
 * Get a number from a string or return an undefined value otherwise (instead of NaN)
 *
 * @example
 * // returns 123
 * toNumber('123abc');
 * @example
 * // returns undefined
 * toNumber('abc123');
 *
 * @param input A string that contains a number
 * @returns A valid number or an undefined value
 */
export const toNumber = <T extends number>(input: T | any): T | undefined => {
  const matchedString = String(input).match(/((?:-|\+)?[0-9]+)/)?.[0];
  if (!matchedString) return;

  const result = Number.parseFloat(matchedString);

  return isNaN(result) ? undefined : (result as T);
};

/**
 * Make sure a number is in a range
 *
 * @example
 * // returns 5
 * toRange(10, 1, 5);
 *
 * @example
 * // returns 1
 * toRange(-5, 1, 15);
 *
 * @param input A number
 * @param min A minimum value of the range
 * @param max A maximum value of the range
 * @returns A number within the provided range
 */
export const toRange = (input: number, min: number, max: number): number => {
  const higherValue = Math.max(min, max);
  const lowerValue = Math.min(min, max);

  return Math.max(lowerValue, Math.min(higherValue, input));
};
