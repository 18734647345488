import type { MutableRefObject, RefCallback } from 'react';
import { useCallback, useRef } from 'react';

import { useForceUpdate } from './useForceUpdate';

type RefGetter<T> = () => T | null;

type RefSetter<T> = (instance: T | null) => void;

/**
 * The `useReactiveRef` hook helps react to reference updates more quickly.
 *
 * Normally you can't put reference values into dependency arrays of React
 * Hooks. This hook provides utility function to fix that problem.
 *
 * It outputs array of three values:
 * - Standart ref variable that you could use to access ref value;
 * - Getter function that you **should** use to track changes of the ref value.
 *   It's safe to put this variable into dependency arrray of a hook.
 * - Setter function that you **should** use to update ref value. It's safe to
 *   put this variable into the ref property.
 */
export const useReactiveRef = <T>(
  initialValue: T | null,
): [ref: MutableRefObject<T | null>, getter: RefGetter<T>, setter: RefSetter<T>] => {
  const forceUpdate = useForceUpdate();

  const ref = useRef<T | null>(initialValue);
  const getter = useCallback(() => ref.current, [forceUpdate]);
  const setter = useCallback<RefCallback<T>>((newRef) => {
    ref.current = newRef;
    forceUpdate();
  }, []);

  return [ref, getter, setter];
};
