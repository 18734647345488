import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useStore } from '@/stores';

export type RouteAuthProps = PropsWithChildren<{
  access?: 'protected' | 'guest' | 'public';
}>;

export const RouteAuth = observer(({ access = 'protected', children }: RouteAuthProps) => {
  const { appStore } = useStore();
  const { authStore } = appStore;

  if (authStore.initialized) {
    if (access === 'protected' && !authStore.isSignedIn) {
      // Protected access means that the route is accessible only for signed-in users
      return <Navigate to="/" />;
    } else if (access === 'guest' && authStore.isSignedIn) {
      // Guest access means that the route is accessible only for non-signed-in users
      return <Navigate to="/" replace />;
    }

    // Public access means that the route is accessible for everyone
  }

  return children;
});
