import { FC, memo } from 'react';

import { Avatar } from '../Avatar';
import { Skeleton } from '../Skeleton/Skeleton';
import { Email, InfoContainer, Name, StyledTagPickerOption } from './styles';

export type AutocompletePeopleOptionSkeletonProps = { value: string };

export const AutocompletePeopleOptionSkeleton: FC<AutocompletePeopleOptionSkeletonProps> = memo(({ value }) => {
  const name = 'John Doe';
  const email = 'john.doe@example.com';

  return (
    <StyledTagPickerOption value={value}>
      <Skeleton variant="circle" isActive>
        <Avatar color="colorful" size={40} name={name} />
      </Skeleton>
      <InfoContainer>
        <Skeleton isActive>
          <Name>{name}</Name>
        </Skeleton>
        <Skeleton isActive>
          <Email>{email}</Email>
        </Skeleton>
      </InfoContainer>
    </StyledTagPickerOption>
  );
});
