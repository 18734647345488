import { Avatar, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Text, Tooltip } from '@fluentui/react-components';
import { MoreHorizontal32Regular as IconThreeDots } from '@fluentui/react-icons';
import { FC, ReactElement } from 'react';
import styled from 'styled-components';

import { StyledOptionalParticipantsButton } from '../styles';

interface OptionalParticipantsButtonProps {
  optionalParticipants: string[];
}

const AvatarAndOptionalParticipantInfo = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const OptionalParticipantsButton: FC<OptionalParticipantsButtonProps> = (props): ReactElement => {
  return (
    <Menu positioning="below-start">
      <MenuTrigger disableButtonEnhancement>
        <Tooltip content="Optional attendees" relationship="label">
          <StyledOptionalParticipantsButton shape="circular" icon={<IconThreeDots />} />
        </Tooltip>
      </MenuTrigger>

      <MenuPopover>
        <MenuList style={{ maxHeight: '330px' }}>
          {props.optionalParticipants.map((item, index) => {
            return (
              <MenuItem key={index}>
                <AvatarAndOptionalParticipantInfo>
                  <Avatar name={item} />
                  <Text>{item}</Text>
                </AvatarAndOptionalParticipantInfo>
              </MenuItem>
            );
          })}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};
