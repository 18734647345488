import { Input, InputProps } from '@fluentui/react-components';
import { FC } from 'react';
import styled from 'styled-components';

import { useReactiveRef } from '../../hooks/useReactiveRef';
import { useTextWidth } from '../../hooks/useTextWidth';
import { isEmpty } from '../../utils/main';

// TODO: Apply theming

/**
 * Reset many default styles from the Fluent UI package.
 *
 * Also use calcualted width of the input, because by default all input elements
 * have min width value forced by browsers.
 */
const StyledInput = styled(Input)<{ $textWidth: number }>`
  min-height: 0;
  font-size: inherit;
  line-height: inherit;
  background-color: transparent;
  border: 1px solid transparent;

  &:hover {
    border-color: #616161;
  }

  input {
    width: ${(props) => props.$textWidth}px;
    padding: unset;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-align: center;

    // This style is important as it allows to assign padding to the element
    // without interfering with width calculations
    box-sizing: content-box;
  }
`;

export type EditableTextProps = InputProps;

/**
 * This component is based on the Input from Fluent UI, but designed to act as a
 * normal text until user hovers over it.
 */
export const EditableText: FC<EditableTextProps> = (props) => {
  const [inputRef, inputRefGetter, inputRefSetter] = useReactiveRef<HTMLInputElement>(null);

  // If the value is not provided then render the input field with a width of
  // one symbol, which is a `0` in this case.
  const value = isEmpty(props.value) ? '0' : props.value;

  const textWidth = useTextWidth(value, {
    ref: inputRef,
    dependencies: [inputRefGetter],
  });

  return <StyledInput {...props} $textWidth={textWidth} ref={inputRefSetter} />;
};
