import { observer } from 'mobx-react-lite';
import { FC, ReactElement } from 'react';

import { Paper } from '@/components/Paper';

const MyMeetings: FC = (): ReactElement => {
  return (
    <Paper padding={3}>
      <span>
        Youkeeps is a corporate scheduling tool for meetings inside and across organizations. It reduces meeting
        coordination time by factor of 3. The service automatically finds common available slots or, if needed,
        participants can choose the suitable slots manually. It correctly handles time zones and personal settings for
        each participant. Youkeeps materially improves your productivity and reduce frustration caused by endless manual
        scheduling.
      </span>
    </Paper>
  );
};

export default observer(MyMeetings);
