import { forwardRef, ReactElement, RefObject } from 'react';

import { AutocompleteOption } from './Autocomplete/Autocomplete';
import { AutocompletePeople, AutocompletePeopleRef } from './AutocompletePeople/AutocompletePeople';
import IconWrapper from './IconWrapper';
import InputWrapper from './InputWrapper';
import PaddedField from './PaddedField';

export type AutocompletePeopleFieldProps = {
  selectedOptions: AutocompleteOption[];
  excludedOptions: AutocompleteOption[];
  onSelectedOptionsChange: (selectedOptions: AutocompleteOption[]) => void;
  disabled?: boolean;
  placeholder: string;
  tooltip?: string;
  icon: ReactElement;
  focusOnElementRef?: RefObject<HTMLInputElement> | null;
  cursorPointer?: boolean;
  isSelectedOptionsSortable?: boolean;
};

export const AutocompletePeopleField = forwardRef<AutocompletePeopleRef, AutocompletePeopleFieldProps>((props, ref) => {
  return (
    <PaddedField title={props.tooltip}>
      <IconWrapper cursor={props.cursorPointer ? 'pointer' : ''}>{props.icon}</IconWrapper>
      <InputWrapper>
        <AutocompletePeople
          ref={ref}
          focusElementRef={props.focusOnElementRef}
          placeholder={props.placeholder}
          selectedOptions={props.selectedOptions}
          excludedDefaultOptions={props.excludedOptions}
          onSelectedOptionsChange={props.onSelectedOptionsChange}
          isSelectedOptionsSortable={props.isSelectedOptionsSortable}
          isDisabled={props.disabled}
        />
      </InputWrapper>
    </PaddedField>
  );
});
