import { FC } from 'react';
import styled from 'styled-components';

import { CommonProps } from '@/utils/props';

const Root = styled.div`
  min-width: 100%;
  min-height: 100%;

  display: flex;
  justify-content: center;
  align-items: stretch;
`;

const Container = styled.div`
  max-width: 1000px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export type LayoutBaseProps = CommonProps;

export const LayoutBase: FC<LayoutBaseProps> = ({ children }) => {
  return (
    <Root>
      <Container>{children}</Container>
    </Root>
  );
};
