import styled from 'styled-components';

const InputWrapper = styled.div`
  width: 100%;

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;

export default InputWrapper;
