import { createContext, useContext } from 'react';

import type { AutocompleteOption } from '../Autocomplete/Autocomplete';

export interface AutocompleteContextType {
  isDisabled?: boolean;
  selectedOptions: AutocompleteOption[];
  setSelectedOptions: (value: AutocompleteOption[]) => void;
}

export const AutocompleteContext = createContext<AutocompleteContextType | undefined>(undefined);

export const AutocompleteProvider = AutocompleteContext.Provider;

export const useAutocompleteContext = (): AutocompleteContextType | undefined => useContext(AutocompleteContext);
