import { isAxiosError } from 'axios';
import { isError, isObject, isString } from 'lodash';

export const getErrorCode = (error: unknown): string | undefined => {
  const headers = (isAxiosError(error) ? error.response?.headers : undefined) ?? {};
  const data = (isAxiosError(error) ? (error.response?.data as any) : undefined) ?? {};

  const codeFromError =
    isError(error) && isObject(error.cause) && 'code' in error.cause ? (error.cause.code as string) : undefined;
  const codeFromData = isObject(data) && 'code' in data ? (data.code as string) : undefined;
  const codeFromHeaders = isString(headers['x-error-code']) ? headers['x-error-code'] : undefined;

  const errorCode = codeFromError ?? codeFromData ?? codeFromHeaders;

  return errorCode;
};

export const getErrorMessage = (error: unknown): string => {
  const data = (isAxiosError(error) ? (error.response?.data as any) : undefined) ?? {};

  const messageFromError = isError(error) ? error.message : undefined;
  const messageFromData = isObject(data) && 'error' in data ? (data.error as string) : undefined;

  const message = messageFromData ?? messageFromError ?? 'Something went wrong. Please try again later.';

  return message;
};

export const getErrorMeta = (error: unknown): { code?: string; message: string } => {
  const code = getErrorCode(error);
  const message = getErrorMessage(error);

  return { code, message };
};
