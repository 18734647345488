import { makeAutoObservable } from 'mobx';

import { getUserEvents } from '@/api/calendar';
import { type AppStore } from '@/store/AppStore';

export type UserDTO = {
  id: number;
  accessToken: string;
  refreshToken: string;
  isActive: boolean;
};

export class UserStore {
  rootStore: AppStore;

  currentUser: UserDTO | undefined;

  constructor(rootStore: AppStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  async getUserCalendarEvents() {
    const result = await getUserEvents();

    return result;
  }
}
