import { Dropdown, Option } from '@fluentui/react-components';
import { FC, ReactElement, RefObject } from 'react';
import styled from 'styled-components';

import { useViewSize } from '../hooks/useViewSize';
import IconWrapper from './IconWrapper';
import InputWrapper from './InputWrapper';
import PaddedField from './PaddedField';

interface DropDownFieldProps {
  options: {
    value: string;
    label: string;
  }[];
  defaultValue: string;
  icon: ReactElement;
  cursorPointer: boolean;
  setValue: (optionValue: string) => void;
  disabled: boolean;
  defaultSelectedOptions: string;
  elementToClickRef?: RefObject<HTMLButtonElement> | null;
  textAddition?: string;
  'data-testid'?: string;
}

const StyledDropdownField = styled(Dropdown)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-color: lightgray;
  height: 48px;

  button[role='combobox'] {
    padding-left: 0;
    font-size: 14px;
    font-weight: 600 !important;
    color: var(--colorNeutralForeground1);
  }

  button {
    grid-template-columns: none;
    justify-content: left;
    gap: 3px;
  }
`;

const DropDownField: FC<DropDownFieldProps> = (props): ReactElement => {
  const viewSize = useViewSize();

  return (
    <PaddedField data-testid={props['data-testid']}>
      <IconWrapper cursor={props.cursorPointer ? 'pointer' : ''}>{props.icon}</IconWrapper>
      <InputWrapper>
        <StyledDropdownField
          disabled={props.disabled}
          ref={props.elementToClickRef}
          appearance="underline"
          listbox={{ style: { maxHeight: '300px', width: viewSize.isMobile ? '73%' : '25%' } }}
          defaultValue={props.defaultValue}
          defaultSelectedOptions={[props.defaultSelectedOptions]}
          onOptionSelect={(e, data) => props.setValue(data.optionValue ?? props.defaultValue)}
        >
          {props.options.map((item, index) => (
            <Option
              text={item.label === props.defaultValue ? item.label : `${props.textAddition ?? ''}${item.label}`}
              key={index}
              value={item.value}
            >
              {item.label}
            </Option>
          ))}
        </StyledDropdownField>
      </InputWrapper>
    </PaddedField>
  );
};

export default DropDownField;
