import { Theme } from '@fluentui/react-components';
import { FC } from 'react';
import { createGlobalStyle } from 'styled-components';

import { currentThemeString, GlobalStyleThemeConstructor, themeName } from '@/helpers/theming';
import { useStore } from '@/stores';
import { CommonProps } from '@/utils/props';

export const GlobalStyle = createGlobalStyle<{ theme?: Theme; $themeName: themeName }>`
  :root {
    --fc-today-bg-color: ${(props) => GlobalStyleThemeConstructor(props.theme).fcTodayBgColor[props.$themeName]};
    --fc-page-bg-color: rgba(0, 0, 0, 0);
    --fc-border-color: ${(props) => GlobalStyleThemeConstructor(props.theme).fcBorderColor[props.$themeName]};
    --fc-non-business-color: ${(props) =>
      GlobalStyleThemeConstructor(props.theme).fcNonBusinessColor[props.$themeName]};
    --fc-now-indicator-color: ${(props) =>
      GlobalStyleThemeConstructor(props.theme).fcNowIndicatorColor[props.$themeName]};
    --fc-button-text-color: ${(props) =>
      GlobalStyleThemeConstructor(props.theme).fc.fcButtonTextColor[props.$themeName]};
    --fc-button-bg-color: ${(props) => GlobalStyleThemeConstructor(props.theme).fc.fcButtonBgColor[props.$themeName]};
    --fc-button-border-color: ${(props) =>
      GlobalStyleThemeConstructor(props.theme).fc.fcButtonBorderColor[props.$themeName]};
    --fc-button-hover-bg-color: ${(props) =>
      GlobalStyleThemeConstructor(props.theme).fc.fcButtonHoverBgColor[props.$themeName]};
    --fc-button-hover-border-color: ${(props) =>
      GlobalStyleThemeConstructor(props.theme).fc.fcButtonHoverBorderColor[props.$themeName]};
    --fc-button-active-bg-color: ${(props) =>
      GlobalStyleThemeConstructor(props.theme).fc.fcButtonActiveBgColor[props.$themeName]};
    --fc-button-active-border-color: ${(props) =>
      GlobalStyleThemeConstructor(props.theme).fc.fcButtonActiveBorderColor[props.$themeName]};
  }
  .fc-timegrid-slot-label {
    color: ${(props) => GlobalStyleThemeConstructor(props.theme).fcTimegridSlotLabel[props.$themeName]};
  }

  .fc-col-header-cell-cushion {
    color: ${(props) => GlobalStyleThemeConstructor(props.theme).fcColHeaderCellCushion[props.$themeName]};
  }

  .fc .fc-button-primary:not(:disabled):active {
    background-color: ${(props) =>
      GlobalStyleThemeConstructor(props.theme).fc.fcButtonPrimaryNotDisabledActiveBg[props.$themeName]};
    border-color: ${(props) => GlobalStyleThemeConstructor(props.theme).fc.fcButtonActiveBorderColor[props.$themeName]};
  }

  .fc .fc-button-primary:not(:disabled):active:focus {
    box-shadow: none;
  }

  .fc .fc-button-primary:focus {
    box-shadow: none;
  }

  .fc .fc-button:focus {
    box-shadow: none;
  }
`;

export type StyledComponentsProviderProps = CommonProps;

export const StyledComponentsProvider: FC<StyledComponentsProviderProps> = ({ children }) => {
  const { appStore } = useStore();
  const { theme, themeString } = appStore.microsoftStore;

  return (
    <>
      <GlobalStyle theme={theme} $themeName={themeName[currentThemeString(themeString)]} />
      {children}
    </>
  );
};
