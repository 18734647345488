import { Button, MessageBar, MessageBarActions, MessageBarBody, MessageBarGroup } from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import { observer } from 'mobx-react-lite';
import React, { FC, ReactElement, SyntheticEvent, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { useStore } from '../stores';

const BarGroup = styled(MessageBarGroup)`
  width: fit-content;
`;

const Bar = styled(MessageBar)`
  margin-top: 20px;
  white-space: pre-line;
`;

interface NotificationProps {
  open: boolean;
}

const Notification: FC<NotificationProps> = (props): ReactElement => {
  const { appStore } = useStore();

  useEffect(() => {
    if (props.open) {
      setTimeout(handleClose, 5000);
    }
  }, [props.open]);

  const handleClose = useCallback(
    (event: SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      appStore.setSnackBar(undefined);
    },
    [appStore],
  );

  return (
    <BarGroup animate="both">
      {[...(props.open ? [true] : [])].map((_, index) => (
        <Bar key={index} intent={appStore.snackBar?.type}>
          <MessageBarBody>{appStore.snackBar?.message}</MessageBarBody>
          <MessageBarActions
            containerAction={
              <Button aria-label="dismiss" appearance="transparent" icon={<DismissRegular />} onClick={handleClose} />
            }
          />
        </Bar>
      ))}
    </BarGroup>
  );
};

export default observer(Notification);
