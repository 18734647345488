import { DateSelectArg } from '@fullcalendar/core';
import moment from 'moment-timezone';

export const getSlotOnSelect = (date: DateSelectArg, duration: string) => {
  const start = moment(date.startStr);
  const interval = moment(duration, 'HH:mm').diff(moment('00:00', 'HH:mm'), 'minutes');

  return {
    start: start.toISOString(),
    end: start.clone().add(interval, 'minutes').toISOString(),
  };
};
