import { Button, Menu, MenuItemRadio, MenuList, MenuPopover, MenuTrigger, Text } from '@fluentui/react-components';
import {
  RadioButton20Filled as IconRadioFilled,
  RadioButton20Regular as IconRadio,
  TextAlignJustifyFilled as IconHorizontal,
} from '@fluentui/react-icons';
import { FC, ReactElement } from 'react';
import styled from 'styled-components';

import { Status } from '../api/meetings';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface MeetingSortMenuProps {
  selectedItemValue: string;
  setSelectedItemValue: (value: string) => void;
  checkedValues: Record<string, string[]>;
  setCheckedValues: (value: Record<string, string[]>) => void;
  menuItemRadioName: string;
  handleMenuSelect: () => void;
  setSortMeetingType: (value: string) => void;
}

const MeetingSortMenu: FC<MeetingSortMenuProps> = (props): ReactElement => {
  return (
    <Container>
      <Text>
        {props.selectedItemValue === 'activeMeetings'
          ? 'Active'
          : props.selectedItemValue === Status.APPROVED
            ? 'Confirmed'
            : props.selectedItemValue === Status.EXPIRED
              ? 'Expired'
              : 'All'}
      </Text>
      <Menu
        checkedValues={props.checkedValues}
        onCheckedValueChange={(e, { name, checkedItems }) => {
          props.setCheckedValues({ ...props.checkedValues, [name]: checkedItems });
          props.setSelectedItemValue(checkedItems[0]);
          props.setSortMeetingType(checkedItems[0]);
          props.handleMenuSelect();
        }}
      >
        <MenuTrigger disableButtonEnhancement>
          <Button icon={<IconHorizontal />} />
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuItemRadio
              checkmark={null}
              icon={props.selectedItemValue === 'activeMeetings' ? <IconRadioFilled /> : <IconRadio />}
              name={props.menuItemRadioName}
              value="activeMeetings"
            >
              Active
            </MenuItemRadio>
            <MenuItemRadio
              checkmark={null}
              icon={props.selectedItemValue === Status.APPROVED ? <IconRadioFilled /> : <IconRadio />}
              name={props.menuItemRadioName}
              value={Status.APPROVED}
            >
              Confirmed
            </MenuItemRadio>
            <MenuItemRadio
              checkmark={null}
              icon={props.selectedItemValue === Status.EXPIRED ? <IconRadioFilled /> : <IconRadio />}
              name={props.menuItemRadioName}
              value={Status.EXPIRED}
            >
              Expired
            </MenuItemRadio>
            <MenuItemRadio
              checkmark={null}
              icon={props.selectedItemValue === 'allMeetings' ? <IconRadioFilled /> : <IconRadio />}
              name={props.menuItemRadioName}
              value="allMeetings"
            >
              All
            </MenuItemRadio>
          </MenuList>
        </MenuPopover>
      </Menu>
    </Container>
  );
};

export default MeetingSortMenu;
