import { FC, useContext } from 'react';

import { currentThemeString, themeName } from '@/helpers/theming';
import { castArray, MaybeArray } from '@/utils/array';
import { CommonProps, MergeProps } from '@/utils/props';

import { TeamsFxContext } from '../Context';
import { StackProps } from '../Stack';
import { StyledRootDiv } from './styles';

export type PaperProps = MergeProps<
  CommonProps<{
    padding?: MaybeArray<number>;
  }>,
  StackProps
>;

export const Paper: FC<PaperProps> = ({
  className,
  padding: paddingProps = 2,
  direction = 'down',
  children,
  ...restProps
}) => {
  // TODO: Use styled-components theme instead
  const { theme, themeString } = useContext(TeamsFxContext);

  const paddingString = castArray(paddingProps)
    .map((value) => `${value * 8}px`)
    .join(' ');

  return (
    <StyledRootDiv
      {...restProps}
      className={className}
      $themeName={themeName[currentThemeString(themeString)]}
      $theme={theme}
      $paddingString={paddingString}
      direction={direction}
    >
      {children}
    </StyledRootDiv>
  );
};
