import { useSortable } from '@dnd-kit/sortable';
import { motion, MotionProps } from 'framer-motion';
import { FC } from 'react';
import styled from 'styled-components';

import { CommonProps } from '../../utils/react';

export type AutocompleteDraggableProps = CommonProps<{
  value: string;
}>;

const StyledMotionDiv = styled(motion.div)<{ $isDragging: boolean }>`
  opacity: ${(props) => (props.$isDragging ? 0.5 : 1)};
`;

/**
 * This component should be used as a wrapper around `AutocompleteTag`
 * component. It provides additional logic for animation.
 *
 * This is internal component that's shouldn't be exported.
 */
export const AutocompleteDraggable: FC<AutocompleteDraggableProps> = (props) => {
  const { attributes, isDragging, listeners, setNodeRef } = useSortable({ id: props.value });

  const motionProps: MotionProps = {
    layout: 'position',
    transition: {
      ease: 'easeInOut',
      duration: 0.25,
    },
  };

  return (
    <StyledMotionDiv {...motionProps} {...attributes} {...listeners} ref={setNodeRef} $isDragging={isDragging}>
      {props.children}
    </StyledMotionDiv>
  );
};
