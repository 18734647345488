import { observer } from 'mobx-react-lite';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { NavigationBar } from '@/components/NavigationBar';
import { Stack } from '@/components/Stack';
import { useStore } from '@/stores';

const Container = styled.div`
  max-width: 1000px;
  padding: 20px 0;
  flex-grow: 1;
`;

export type FullLayoutProps = PropsWithChildren;

export const FullLayout: FC<FullLayoutProps> = observer(({ children }) => {
  const { appStore } = useStore();

  return (
    <Container>
      <Stack direction="down" spacing={2}>
        {appStore.authStore.isSignedIn && <NavigationBar />}
        {children}
      </Stack>
    </Container>
  );
});
