import { resolve } from 'node:path';

import * as dotenv from 'dotenv';
import { packageDirectorySync } from 'pkg-dir';

// TODO: Create a separate config service?

const isStaging = import.meta.env?.['REACT_APP_START_LOGIN_PAGE_URL']?.includes('staging.youkeeps.com');
const isProduction = import.meta.env?.['REACT_APP_START_LOGIN_PAGE_URL']?.includes('my.youkeeps.com');

export const environment = isProduction ? 'production' : isStaging ? 'staging' : 'development';

// #############################################################################

// TODO: Move all the code below to a shared package once we create one

export const getPackageRootPath = () => {
  const rootPath = packageDirectorySync();
  if (!rootPath) {
    throw new Error('Root path not found');
  }

  return rootPath;
};

export const getMonorepoRootPath = () => {
  const packageRootPath = getPackageRootPath();
  const monorepoRootPath = packageRootPath.includes('/api') ? resolve(packageRootPath, '..') : packageRootPath;

  return monorepoRootPath;
};

export const getDevConfigPath = () => resolve(getPackageRootPath(), '.localConfigs');

export const getMonorepoDevConfigPath = () => resolve(getMonorepoRootPath(), '.env');

export const loadConfig = (
  options: {
    path?: string;
    variableNames?: string[];
    mapToEnv?: (env: Record<string, any>) => Record<string, any>;
  } = {},
): Record<string, any> => {
  const { path, variableNames, mapToEnv } = options;

  const loadedVariables: dotenv.DotenvPopulateInput = {};
  const savedVariables: dotenv.DotenvPopulateInput = {};
  let mappedVariables: dotenv.DotenvPopulateInput = {};

  dotenv.config({ path: path ?? getDevConfigPath(), processEnv: loadedVariables });

  for (const [key, value] of Object.entries(loadedVariables)) {
    const isVariableExcluded = variableNames && !variableNames.includes(key);
    const isVariableExists = process.env[key] !== undefined;

    if (isVariableExcluded || isVariableExists) {
      continue;
    }

    savedVariables[key] = value;
  }

  mappedVariables = mapToEnv?.(savedVariables) ?? savedVariables;

  return mappedVariables;
};

export const loadConfigIntoEnv = (
  options: {
    path?: string;
    variableNames?: string[];
    mapToEnv?: (env: Record<string, any>) => Record<string, any>;
  } = {},
) => {
  const variables = loadConfig(options);

  for (const [key, value] of Object.entries(variables)) {
    process.env[key] = value;
  }
};

/**
 * This will load variables in development environment only since the .env file
 * does not exist in production.
 */
export const loadDevConfigIntoEnv = (variableNames?: string[]) =>
  loadConfigIntoEnv({
    path: getDevConfigPath(),
    variableNames,
  });

export const loadMonorepoDevConfigIntoEnv = (variableNames?: string[]) =>
  loadConfigIntoEnv({
    path: getMonorepoDevConfigPath(),
    variableNames,
  });
