import { FC, ReactElement, RefObject } from 'react';

import IconWrapper from './IconWrapper';
import InputWrapper from './InputWrapper';
import PaddedField from './PaddedField';
import StyledInput from './StyledInput';

interface InputFieldProps {
  value: string;
  setValue: (value: string) => void;
  disabled: boolean;
  icon: ReactElement;
  placeholder: string;
  tooltip?: string;
  header?: boolean;
  focusOnElementRef?: RefObject<HTMLInputElement> | null;
  cursorPointer?: boolean;
}

const InputField: FC<InputFieldProps> = (props): ReactElement => {
  return (
    <PaddedField title={props.tooltip}>
      <IconWrapper cursor={props.cursorPointer ? 'pointer' : ''}>{props.icon}</IconWrapper>
      <InputWrapper>
        <StyledInput
          disabled={props.disabled}
          ref={props.focusOnElementRef}
          size="large"
          appearance="underline"
          value={props.value}
          onChange={(_, data) => props.setValue(data.value)}
          placeholder={props.placeholder}
          header={props.header}
        />
      </InputWrapper>
    </PaddedField>
  );
};

export default InputField;
