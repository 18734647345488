import { redirect, RouteObject } from 'react-router-dom';

import { RouteAuth } from '@/features/auth/components/RouteAuth';
import { CenteredLayout } from '@/features/layout/components/CenteredLayout';
import { MainLayout } from '@/features/layout/components/MainLayout';
import About from '@/pages/About';
import { MainPage } from '@/pages/MainPage';
import SignIn from '@/pages/SignIn';

import Overlays from '../components/Overlays';
import CreateMeeting from '../pages/CreateMeeting';
import ErrorPage from '../pages/ErrorPage';
import MyMeetings from '../pages/MyMeetings';
import Voting from '../pages/Voting';

const goHome = async () => {
  throw redirect('/');
};

const routes: RouteObject[] = [
  {
    path: '*',
    loader: goHome,
  },
  {
    element: <Overlays />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <MainPage />,
      },
      {
        element: <CenteredLayout />,
        children: [
          {
            path: '/error',
            element: <ErrorPage />,
          },
          {
            path: '/sign-in',
            element: (
              <RouteAuth access="guest">
                <SignIn />
              </RouteAuth>
            ),
          },
        ],
      },
      {
        element: <MainLayout />,
        children: [
          {
            index: true,
            path: '/create-meeting',
            element: (
              <RouteAuth access="protected">
                <CreateMeeting />
              </RouteAuth>
            ),
          },
          {
            path: '/my-meetings',
            element: (
              <RouteAuth access="protected">
                <MyMeetings />
              </RouteAuth>
            ),
          },
          {
            path: '/voting',
            element: (
              <RouteAuth access="public">
                <Voting />
              </RouteAuth>
            ),
          },
          {
            path: '/about',
            element: (
              <RouteAuth access="protected">
                <About />
              </RouteAuth>
            ),
          },
        ],
      },
    ],
  },
];

export default routes;
