import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { styled } from 'styled-components';

import { useStore } from '@/stores';
import { CommonProps } from '@/utils/props';

const StyledImg = styled.img`
  max-width: 150px;
  object-fit: contain;
`;

export type LogoWithTextProps = CommonProps;

export const LogoWithText: FC<LogoWithTextProps> = observer(({ className }) => {
  const { appStore } = useStore();

  const src = appStore.themeMode === 'light' ? '/logo-text.svg' : '/logo-text-reverse.svg';

  return <StyledImg className={className} src={src} />;
});
