import { AvatarGroupItem, Button, Subtitle2, Subtitle2Stronger } from '@fluentui/react-components';
import { FC, useContext } from 'react';
import styled from 'styled-components';

import { currentThemeString, themeName } from '../../../helpers/theming';
import { TeamsFxContext } from '../../Context';
import { Skeleton } from '../../Skeleton/Skeleton';
import {
  AvatarGroupContainer,
  Container,
  MeetingTitleAndAvatarWrapper,
  Members,
  MembersBox,
  MembersBoxTitle,
  StyledAvatarGroup,
  StyledMeetingCard,
  TitleWrapper,
} from '../styles';

const StyledTitleWrapper = styled(TitleWrapper)`
  display: flex;
  align-items: center;
`;

const StyledAvatarGroupContainer = styled(AvatarGroupContainer)`
  gap: 10px;
`;

export const MeetingCardSkeleton: FC = () => {
  const { theme, themeString } = useContext(TeamsFxContext);
  const title = 'Confirmed by 0 of 10';
  const subtitle = 'Confirmed by 0 of 10';

  return (
    <StyledMeetingCard>
      <Container theme={theme} $themeName={themeName[currentThemeString(themeString)]}>
        <StyledTitleWrapper>
          <Skeleton height="20px" width="130px" variant="text" isActive>
            <Subtitle2Stronger>{title}</Subtitle2Stronger>
          </Skeleton>
          <Skeleton width="25px" height="25px" variant="rect" isActive>
            <Button />
          </Skeleton>
        </StyledTitleWrapper>
        <Members>
          <MembersBox theme={theme} $themeName={themeName[currentThemeString(themeString)]}>
            <MeetingTitleAndAvatarWrapper>
              <MembersBoxTitle>
                <Skeleton variant="rect" isActive>
                  <Subtitle2>{subtitle}</Subtitle2>
                </Skeleton>
              </MembersBoxTitle>
              <StyledAvatarGroup layout="stack" style={{ gap: '18px' }}>
                {Array.from({ length: 3 }).map((_, index) => (
                  <StyledAvatarGroupContainer>
                    <Skeleton height="30px" width="30px" variant="circle" isActive>
                      <AvatarGroupItem key={index} />
                    </Skeleton>
                    <Skeleton height="20px" width="20px" variant="rect" isActive>
                      <Button />
                    </Skeleton>
                  </StyledAvatarGroupContainer>
                ))}
              </StyledAvatarGroup>
            </MeetingTitleAndAvatarWrapper>
          </MembersBox>
        </Members>
      </Container>
    </StyledMeetingCard>
  );
};
