import { Check24Regular as IconDescription } from '@fluentui/react-icons';
import { FC, ReactElement } from 'react';
import styled from 'styled-components';

import IconWrapper from './IconWrapper';

interface DescriptionProps {
  description: string;
}

const StyledIconWrapper = styled(IconWrapper)`
  margin-top: 0;
`;

const StyledDescription = styled.span`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 15px;
  font-size: 16px;
  white-space: pre-line;
  overflow-wrap: anywhere;
`;

const Description: FC<DescriptionProps> = (props): ReactElement => {
  return (
    <StyledDescription>
      <StyledIconWrapper>
        <IconDescription />
      </StyledIconWrapper>
      {props.description}
    </StyledDescription>
  );
};

export default Description;
