import * as Sentry from '@sentry/react';
import { configure as configureMobx } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import {
  createHashRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import ConsentPopup from './components/ConsentPopup';
import { SplashScreen } from './components/SplashScreen';
import { GoogleProvider } from './features/google/providers/GoogleProvider';
import { MicrosoftProvider } from './features/microsoft-teams/providers/MicrosoftProvider';
import { FluentUIProvider } from './providers/FluentUIProvider';
import { StyledComponentsProvider } from './providers/StyledComponentsProvider';
import Routes from './routes/Routes';
import { useStore } from './stores';
import { environment } from './utils/config';

configureMobx({ enforceActions: 'never' });

Sentry.init({
  environment,
  enabled: environment === 'production' || environment === 'staging',
  dsn: import.meta.env['REACT_APP_SENTRY_DSN'],
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'youkeeps'],
  // Session Replay
  replaysSessionSampleRate: environment === 'production' ? 0.1 : 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: environment === 'production' ? 1.0 : 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const sentryCreateHashRouter = Sentry.wrapCreateBrowserRouter(createHashRouter);
const router = sentryCreateHashRouter(Routes);

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default observer(function App() {
  const { appStore } = useStore();
  const { microsoftStore, authStore, isAppInitialized } = appStore;
  const { theme, themeString, teamsUserCredential } = microsoftStore;
  const { isAuthPossible } = authStore;

  useEffect(() => {
    appStore.initializeApp();
  }, []);

  return (
    <FluentUIProvider>
      <StyledComponentsProvider>
        <ConsentPopup open={appStore.needConsent} />
        {!isAppInitialized ? (
          <SplashScreen />
        ) : !isAuthPossible ? (
          <SplashScreen>
            Unfortunately, 3rd party cookies are disabled in your browser. Please enable them to use the app.
            Alternatively, you can use the Microsoft Teams desktop or mobile app. Also you could open the app in a new
            browser tab.
          </SplashScreen>
        ) : (
          <MicrosoftProvider theme={theme} themeString={themeString} teamsUserCredential={teamsUserCredential}>
            <GoogleProvider>
              <RouterProvider router={router} />
            </GoogleProvider>
          </MicrosoftProvider>
        )}
      </StyledComponentsProvider>
    </FluentUIProvider>
  );
});
