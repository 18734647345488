import { Button, Link, Spinner } from '@fluentui/react-components';
import { observer } from 'mobx-react-lite';
import { FC, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Paper } from '@/components/Paper';
import { Stack } from '@/components/Stack';
import { LogoWithText } from '@/features/layout/components/Logo';

import { useStore } from '../stores';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
`;

const StyledPaper = styled(Paper)`
  max-width: 400px;
  width: 100%;
`;

const MicrosoftLogo = styled.img`
  content: url('/microsoft-logo.svg');
  width: 16px;
  height: 16px;
`;

const GoogleLogo = styled.img`
  content: url('/google-logo.svg');
  width: 16px;
  height: 16px;
`;

const ProductLinks = styled.span`
  max-width: 75%;
  align-self: center;
  display: block;
  text-align: center;
  color: #5f6368;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
`;

const SignIn: FC = (): ReactElement => {
  const { appStore } = useStore();
  const { microsoftStore } = appStore;
  const navigate = useNavigate();

  const handleMicrosoftTeamsSignIn = async () => {
    await appStore.authStore.signInWithMicrosoft({ mode: 'teams', forcePermissionRequest: true });

    navigate('/');
  };

  const handleMicrosoftSignIn = async () => {
    await appStore.authStore.signInWithMicrosoft();

    navigate('/');
  };

  const handleGoogleSignIn = async () => {
    await appStore.authStore.signInWithGoogle();

    navigate('/');
  };

  return (
    <Container>
      <Stack direction="down" spacing={2}>
        <StyledPaper padding={3} spacing={4}>
          <Stack alignSelf="center">
            <LogoWithText />
          </Stack>
          <Stack direction="down" spacing={2}>
            {microsoftStore.isMicrosoftTeams ? (
              <Button
                icon={appStore.authStore.loading.is(['signInMicrosoft']) ? <Spinner size="tiny" /> : undefined}
                onClick={handleMicrosoftTeamsSignIn}
              >
                Grant permissions
              </Button>
            ) : (
              <>
                <Button
                  icon={
                    appStore.authStore.loading.is(['signInMicrosoft']) ? <Spinner size="tiny" /> : <MicrosoftLogo />
                  }
                  onClick={handleMicrosoftSignIn}
                >
                  Sign In with Microsoft
                </Button>
                <Button
                  icon={appStore.authStore.loading.is(['signInGoogle']) ? <Spinner size="tiny" /> : <GoogleLogo />}
                  onClick={handleGoogleSignIn}
                >
                  Sign In with Google
                </Button>
              </>
            )}
          </Stack>
        </StyledPaper>
        <ProductLinks>
          By using Youkeeps, you agree to our{' '}
          <Link inline href="https://youkeeps.com/termsofuse">
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link inline href="https://youkeeps.com/privacy">
            Privacy Policy
          </Link>
          .
        </ProductLinks>
      </Stack>
    </Container>
  );
};

export default observer(SignIn);
