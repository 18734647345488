import { Link } from '@fluentui/react-components';
import { FC, ReactElement } from 'react';

interface NameMailtoProps {
  creatorName: string;
  email: string;
}

const NameMailto: FC<NameMailtoProps> = (props): ReactElement => {
  return (
    <Link appearance="subtle" href={`mailto:${props.email}`}>
      {props.creatorName} ({props.email})
    </Link>
  );
};

export default NameMailto;
