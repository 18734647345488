import { FluentProvider, tokens } from '@fluentui/react-components';
import { FC } from 'react';

import { useStore } from '@/stores';
import { CommonProps } from '@/utils/props';

export type FluentUIProviderProps = CommonProps;

export const FluentUIProvider: FC<FluentUIProviderProps> = ({ children }) => {
  const { appStore } = useStore();
  const { theme, themeString } = appStore.microsoftStore;

  return (
    <FluentProvider
      theme={{
        ...theme,
        colorNeutralBackground3: themeString === 'default' ? '#eeeeee' : theme.colorNeutralBackground3,
      }}
      style={{ background: tokens.colorNeutralBackground3, minHeight: '100%' }}
    >
      {children}
    </FluentProvider>
  );
};
