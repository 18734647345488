import { Backspace } from '@fluentui/keyboard-keys';
import type { TagPickerInputProps, TagPickerProps } from '@fluentui/react-components';
import { TagPicker, TagPickerControl, TagPickerInput, TagPickerList } from '@fluentui/react-components';
import { isNil, times, uniqBy } from 'lodash';
import { ClipboardEvent, FC, ReactElement, ReactNode, RefObject, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useViewSize } from '../../hooks/useViewSize';
import { replaceNull } from '../../utils/null';
import { AutocompleteContextType, AutocompleteProvider } from './AutocompleteContext';
import { AutocompleteOption } from './AutocompleteOption';
import { AutocompleteOptionSkeleton } from './AutocompleteOptionSkeleton';
import { AutocompleteTag } from './AutocompleteTag';
import { AutocompleteTagGroup } from './AutocompleteTagGroup';
import { EmptyMessage } from './styles';

// TODO: Allow duplicate emails with error message (right now they just disappear)
// TODO: Update all colors to match light/dark/contrast theme

/**
 * It's required for all options to have value. This constant is used to
 * populate value for placeholder options during loading state.
 */
const OPTION_PLACEHOLDER_PREFIX = 'OPTION_PLACEHOLDER';

export type AutocompleteOption = { label?: string; value: string; isDisabled?: boolean };

export type AutocompleteProps = {
  /** The short hint displayed in the `input` before the user enters a value. */
  placeholder?: string;
  /**
   * The value of the search input. If not provided, makes the input
   * uncontrolled. Use together with `onQueryChange`.
   */
  query?: string;
  /**
   * The list of options, will be put into dropdown.
   */
  options?: AutocompleteOption[];
  /**
   * The list of selected options, will be rendered into list of tags within the
   * input field.
   *
   * It's possible to provide selected options that are not in the `options` list.
   */
  selectedOptions?: AutocompleteOption[];
  /**
   * The callback is executed when search input value changes.
   *
   * Allows to transform search input into controlled field. In this case it's also
   * required to provide `query` prop.
   */
  onQueryChange?: (value: string, meta: { isPasted?: boolean }) => void;
  /**
   * The callback is executed when search input value is pasted.
   */
  onQueryPaste?: (
    value: string,
    meta: { event: ClipboardEvent<HTMLInputElement>; selectionStart?: number; selectionEnd?: number; query: string },
  ) => void;
  /**
   * The callback is executed when list of selected options is changed.
   *
   * It provides a new array of options with all changes already applied. At the
   * moment there's no way to know what were changed.
   */
  onSelectedOptionsChange?: (selectedOptions: AutocompleteOption[]) => void;
  /** The reference of the focusable element. */
  focusElementRef?: RefObject<HTMLInputElement> | null;
  /**
   * The callback is used to render custom element of selected option (tag) near the input field.
   */
  renderTag?: (data: { index: number; option: AutocompleteOption }) => ReactNode;
  /**
   * The callback is used to render custom element of option in the dropdown menu.
   */
  renderOption?: (data: { option: AutocompleteOption; isLoading?: boolean }) => ReactNode;
  /**
   * Is field disabled to interact or not.
   */
  isDisabled?: boolean;
  /**
   * If `true`, renders placeholder options.
   */
  isLoading?: boolean;
  /**
   * If `true`, selected options (tags) can be re-ordered via drag-n-drop.
   */
  isSelectedOptionsSortable?: boolean;
};

const StyledTagPickerControl = styled(TagPickerControl)`
  border-color: lightgray;
  padding: 3px 0 4px;

  input {
    font-size: 14px;
    font-weight: 600;
  }

  // This fixes the chevron position given that the input height is more than it
  // was intended by the FluentUI devs
  .fui-TagPickerControl__aside {
    grid-template-rows: minmax(48px, auto) 1fr;
  }
`;

const DropdownHeaderContainer = styled.div`
  padding: 4px;
`;

const DropdownHeader = styled.span`
  font-weight: 600;
  font-size: 12px;
`;

export const Autocomplete: FC<AutocompleteProps> = (props): ReactElement => {
  const viewSize = useViewSize();

  // If query prop is not provided, use the internal value instead
  const [uncontrolledQuery, setUncontrolledQuery] = useState('');
  const query = isNil(props.query) ? uncontrolledQuery : props.query;

  // Hide placeholder if the field is not empty (either query or tag list is populated)
  const isShowPlaceholder = (props.selectedOptions ?? []).length === 0;

  /**
   * Single function to render option tags. It triggers the `renderTag` callback
   * and uses the default tag component as a fallback.
   */
  const renderTag = useCallback(
    (data: { index: number; option: AutocompleteOption }) => {
      const customTag = props.renderTag?.(data);
      if (customTag) return customTag;

      const defaultTag = (
        <AutocompleteTag key={data.option.value} value={data.option.value}>
          {data.option.label ?? data.option.value}
        </AutocompleteTag>
      );

      return defaultTag;
    },
    [props.renderTag],
  );

  /**
   * Single function to render option element. It triggers the `renderOption`
   * callback and uses the default option component as a fallback.
   */
  const renderOption = useCallback(
    (data: { option: AutocompleteOption; isLoading?: boolean }): ReactNode => {
      const customOption = props.renderOption?.(data);
      if (customOption) return customOption;

      const defaultOption = data.isLoading ? (
        <AutocompleteOptionSkeleton key={data.option.value} value={data.option.value} />
      ) : (
        <AutocompleteOption key={data.option.value} label={data.option.label} value={data.option.value} />
      );

      return defaultOption;
    },
    [props.renderOption],
  );

  /**
   * Compile memoized list of option elements.
   */
  const renderedOptions = useMemo<ReactNode[]>(() => {
    // If loading, then render 5 placeholder options
    if (props.isLoading) {
      return times(5, (index) =>
        renderOption({
          option: { value: `${OPTION_PLACEHOLDER_PREFIX}_${index}` },
          isLoading: true,
        }),
      );
    }

    return (props.options ?? []).map((option) => renderOption({ option }));
  }, [renderOption, props.options, props.isLoading]);

  /**
   * Single function to handle query changes both when its value is changed and
   * when it clears out after option selection.
   */
  const setQueryValue = (value: string, meta: { isPasted?: boolean } = {}) => {
    const { isPasted } = meta;

    props.onQueryChange?.(value, { isPasted });
    setUncontrolledQuery(value);
  };

  const handleQueryChange: TagPickerInputProps['onChange'] = (event) => {
    const nativeEvent = event.nativeEvent as (typeof event)['nativeEvent'] & {
      inputType?: 'insertText' | 'insertFromPaste';
    };
    const isPasted = nativeEvent.inputType === 'insertFromPaste';

    setQueryValue(event.target.value, { isPasted });
  };

  const handleQueryPaste: TagPickerInputProps['onPaste'] = (event) => {
    props.onQueryPaste?.(event.clipboardData.getData('Text'), {
      event,
      selectionStart: replaceNull(event.currentTarget.selectionStart),
      selectionEnd: replaceNull(event.currentTarget.selectionEnd),
      query,
    });
  };

  const handleQueryKeyDown: TagPickerInputProps['onKeyDown'] = (event) => {
    /**
     * The code below overrides the default behaviour that comes from Fluent UI.
     *
     * For some reason, you can't select the whole text and then hit Backspace
     * (not sure if it was intentional). The fix below makes sure that if at
     * least one symbol is selected, hitting the Backspace button actually
     * removes the selected text.
     */
    const selectionDistance = (event.currentTarget.selectionEnd ?? 0) - (event.currentTarget.selectionStart ?? 0);
    if (event.key === Backspace && selectionDistance > 0) {
      // This prevents default behaviour from kicking in
      event.key = '';
    }
  };

  /**
   * This function converts selected options from Fluent UI format into our own.
   */
  const handleOptionSelect: TagPickerProps['onOptionSelect'] = (
    event,
    { value, selectedOptions: selectedOptionsValues },
  ) => {
    // First get list of every possible option.
    // It's important to have selected options first and then the rest of the options.
    const allOptions = uniqBy([...(props.selectedOptions ?? []), ...(props.options ?? [])], (option) => option.value);
    // Then convert list of option values into list of option objects
    const selectedOptions = allOptions.filter((option) => selectedOptionsValues.includes(option.value));

    if (value.startsWith(OPTION_PLACEHOLDER_PREFIX)) {
      // If user tries to click on a placeholder option then ignore the action
      event.preventDefault();
    } else {
      props.onSelectedOptionsChange?.(selectedOptions);
      setQueryValue('');
    }
  };

  /**
   * Convert selected options format from our own into Fluent UI style.
   */
  const selectedOptionsValues = props.selectedOptions?.map((selectedOption) => selectedOption.value);

  const context: AutocompleteContextType = {
    isDisabled: props.isDisabled,
    selectedOptions: props.selectedOptions ?? [],
    setSelectedOptions: (value) => props.onSelectedOptionsChange?.(value),
  };

  return (
    <AutocompleteProvider value={context}>
      <TagPicker
        size="large"
        appearance="underline"
        selectedOptions={selectedOptionsValues}
        onOptionSelect={handleOptionSelect}
        disabled={props.isDisabled}
      >
        <StyledTagPickerControl expandIcon={null}>
          <AutocompleteTagGroup
            selectedOptions={props.selectedOptions}
            onSelectedOptionsChange={props.onSelectedOptionsChange}
            renderTag={renderTag}
            isSortable={props.isSelectedOptionsSortable}
          />
          <TagPickerInput
            ref={props.focusElementRef}
            value={query}
            autoComplete="off"
            placeholder={isShowPlaceholder ? props.placeholder : undefined}
            onChange={handleQueryChange}
            onPaste={handleQueryPaste}
            onKeyDown={handleQueryKeyDown}
          />
        </StyledTagPickerControl>
        <TagPickerList style={{ maxHeight: '300px', width: viewSize.isMobile ? '73%' : '52%' }}>
          <DropdownHeaderContainer>
            <DropdownHeader>Suggested contacts</DropdownHeader>
          </DropdownHeaderContainer>
          {renderedOptions.length > 0 ? renderedOptions : <EmptyMessage>We couldn't find any matches</EmptyMessage>}
        </TagPickerList>
      </TagPicker>
    </AutocompleteProvider>
  );
};
