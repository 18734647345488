import { Body1Stronger } from '@fluentui/react-components';
import moment from 'moment-timezone';
import { FC, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Status } from '../../../api/meetings';

interface CountdownTimerProps {
  countdownTarget: string;
  timeZone: string;
  meetingStatus: Status;
}

const getTimeLeft = (countdownTarget: string, timeZone: string) => {
  const targetTime = moment.tz(countdownTarget, timeZone);
  const currentTime = moment.tz(timeZone);
  const totalTimeLeft = Math.max(targetTime.diff(currentTime), 0); // Ensures totalTimeLeft is not negative

  const duration = moment.duration(totalTimeLeft);

  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return {
    hours: Math.max(hours, 0),
    minutes: Math.max(minutes, 0),
    seconds: Math.max(seconds, 0),
  };
};

const Countdown = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const CountdownElement = styled(Body1Stronger)``;

const CountdownTimer: FC<CountdownTimerProps> = (props): ReactElement => {
  const [timeLeft, setTimeLeft] = useState(() => getTimeLeft(props.countdownTarget, props.timeZone));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft(props.countdownTarget, props.timeZone));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Countdown>
      <CountdownElement>{timeLeft.hours || '00'}:</CountdownElement>
      <CountdownElement>{timeLeft.minutes || '00'}:</CountdownElement>
      <CountdownElement>{timeLeft.seconds || '00'}</CountdownElement>
    </Countdown>
  );
};

export default CountdownTimer;
