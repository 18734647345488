import styled from 'styled-components';

const IconWrapper = styled.div<{ textArea?: boolean; cursor?: string }>`
  color: gray;
  margin-top: ${(props) => (props.textArea ? '23px' : '15px')};
  cursor: ${(props) => (props.cursor ? props.cursor : 'default')};
  svg {
    width: 20px;
    height: 20px;
  }
`;

export default IconWrapper;
