import { FC, useEffect, useState } from 'react';
import { styled } from 'styled-components';

import { CommonProps } from '@/utils/props';

const SplashScreenRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  height: 100vh;
`;

const SplashScreenIcon = styled.img<{ $visible: boolean }>`
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  transition: opacity 0.5s;
`;

export type SplashScreenProps = CommonProps;

export const SplashScreen: FC<SplashScreenProps> = ({ children }) => {
  const [isLogoVisible, setIsLogoVisible] = useState(false);

  // Delay the appearance of the logo by 500ms
  useEffect(() => {
    const timerId = setTimeout(() => {
      setIsLogoVisible(true);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  });

  return (
    <SplashScreenRoot>
      <SplashScreenIcon $visible={isLogoVisible} src="/logo-icon.svg" />
      {children}
    </SplashScreenRoot>
  );
};
