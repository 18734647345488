import { DependencyList, RefObject, useMemo } from 'react';

/**
 * This hook calculates width of the text.
 *
 * Use `ref` option to provide an element that this hook should retrieve styles
 * from during calculation.
 *
 * NOTE: When using the `ref` option it's highly recommended to use
 * `useReactiveRef` instead of the built-in `useRef`. This will prevent possible
 * visual flickering on the initial render.
 */
export const useTextWidth = (
  value?: string,
  options: {
    /** If provided, this hook will use this element's styles during calculation. */
    ref?: RefObject<HTMLElement>;
    /** Fallback width value. @default 0 */
    fallbackWidth?: number;
    /** If provided, it will recalculate every time those dependencies change. */
    dependencies?: DependencyList;
  } = {},
): number => {
  const { ref, fallbackWidth = 0, dependencies = [] } = options;
  const styles = getComputedStyle(ref?.current ?? document.body);

  const font = styles.font;

  const width = useMemo(() => {
    if (!value) return;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) return;

    context.font = font;

    return context.measureText(value).width;
  }, [value, font, ...dependencies]);

  return width ?? fallbackWidth;
};
