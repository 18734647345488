import { LocationRegular as IconLocation } from '@fluentui/react-icons';
import { FC, ReactElement } from 'react';
import styled from 'styled-components';

import IconWrapper from './IconWrapper';

interface LocationProps {
  location: string;
}

const StyledIconWrapper = styled(IconWrapper)`
  margin-top: 0px;
`;

const StyledLocation = styled.span`
  gap: 8px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: 16px;
  white-space: pre-line;
  overflow-wrap: anywhere;
`;

const Location: FC<LocationProps> = (props): ReactElement => {
  return (
    <StyledLocation>
      <StyledIconWrapper>
        <IconLocation />
      </StyledIconWrapper>
      {props.location}
    </StyledLocation>
  );
};

export default Location;
