import React, { Children, isValidElement, useContext } from 'react';

import { CommonProps } from '../../utils/react';
import { TeamsFxContext } from '../Context';
import { Root } from './styles';

export type SkeletonProps = CommonProps<{
  /** If provided, uses that HTML tag as a base. @default 'span' */
  component?: keyof JSX.IntrinsicElements;
  /** If provided, uses fixed width. By default it takes the width of the underlying content. */
  width?: number | string;
  /** If provided, uses fixed height. By default it takes the height of the underlying content. */
  height?: number | string;
  /**
   * Different variations of the skeleton:
   * - `rect` - basic rectangle
   * - `circle` - same as `rect` but with round corners
   * - `text` - simmilar to `rect` but have special positioning to create a thin
   *   wrapper around text
   *
   * @default 'text'
   */
  variant?: 'text' | 'rect' | 'circle';
  /**
   * Different variations of the animation. If provided `false`, disables the
   * animation.
   *
   * @default 'pulse' */
  animation?: 'pulse' | 'wave' | false;
  /** If `true`, renders a skeleton. Otherwise just passes through all children. */
  isActive?: boolean;
}>;

/**
 * This component helps building placeholders for loading content.
 */
export const Skeleton = React.forwardRef<HTMLDivElement, SkeletonProps>(
  (
    { className, component = 'span', width, height, variant = 'text', animation = 'pulse', isActive, children },
    ref,
  ) => {
    const { themeString } = useContext(TeamsFxContext);

    if (!isActive) return <>{children}</>;

    // Multiple children are not supported (every block should have its own skeleton wrapper)
    const firstChild = Children.toArray(children)[0];
    // If a child is not an element (ex. plain text) wrap it in span
    const firstChildElement = isValidElement(firstChild) ? firstChild : <span>{firstChild}</span>;

    const isWithChildren = Boolean(children);

    return (
      <Root
        className={className}
        as={component}
        ref={ref}
        $themeString={themeString}
        $isWithChildren={isWithChildren}
        $width={width}
        $height={height}
        $variant={variant}
        $animation={animation}
      >
        {firstChildElement}
      </Root>
    );
  },
);
