import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

export type EmbeddedLayoutProps = PropsWithChildren;

const Container = styled.div`
  max-width: 1000px;
  padding: 20px 0;
  flex-grow: 1;
`;

export const EmbeddedLayout: FC<EmbeddedLayoutProps> = ({ children }) => {
  return (
    <Container>
      <div>{children}</div>
    </Container>
  );
};
