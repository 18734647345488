import { useEffect, useState } from 'react';

/**
 * A custom hook that determines if the current viewport width is considered "mobile".
 *
 * @param {number} - The width threshold for determining if the viewport is mobile.
 * @default width 768
 * @returns {{ isMobile: boolean }} An object with a boolean `isMobile` indicating if the viewport width is mobile.
 */
export const useViewSize = (width: number = 768) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= width);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [setIsMobile]);

  return { isMobile };
};
