import { get, post, RequestController } from './helpers';

interface DateTime {
  dateTime: string;
  timeZone: string;
}

export interface Event {
  id?: string;
  subject: string;
  start: DateTime;
  end: DateTime;
}

export interface Profile {
  businessPhones: string[];
  displayName: string;
  givenName: string;
  id: string;
  jobTitle: string | null;
  mail: string;
  mobilePhone: string | null;
  officeLocation: string | null;
  preferredLanguage: string;
  surname: string;
  userPrincipalName: string;
}

export interface Attendee {
  id: string;
  displayName?: string;
  emailAddress: string;
}

export const getUserProfile: () => Promise<{ data: Profile }> = async () => await get('getUserProfile');

export const getUserAvatar = (payload: { userId: string }): Promise<ArrayBuffer> =>
  get('getUserAvatar', payload, { responseType: 'arraybuffer' });

export const searchAttendees = (
  payload: { query: string; limit: number },
  options?: { controller?: RequestController },
): Promise<{ data: Attendee[] }> => post('searchAttendees', payload, options);

export const getUserEvents: () => Promise<Event[]> = async () => await get('getUserEvents');

export const getUserBusyEvents = (payload: { tokenSaveId?: string }) =>
  post<{ data: Event[] }>('getCalendarBusyEvents', payload);

export const postUserEvent: (payload: Event) => Promise<{ graphClientMessage: Event }> = async (payload) =>
  await post('postUserEvent', payload);

export const sendEmail: () => Promise<void> = async () => await post('sendEmail', {});
