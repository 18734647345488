import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from '@fluentui/react-components';
import React, { FC, ReactElement } from 'react';

import { useStore } from '../stores';

interface ConsentPopupProps {
  open: boolean;
}

const ConsentPopup: FC<ConsentPopupProps> = (props): ReactElement => {
  const { appStore } = useStore();

  const handleClick = async () => {
    try {
      await appStore.requestConsent();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={props.open}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Need permissions</DialogTitle>
          <DialogContent>We need your permission to access your profile/calendar</DialogContent>
          <DialogActions>
            <Button appearance="secondary" onClick={() => appStore.setNeedConsent(false)}>
              Close
            </Button>
            <Button appearance="primary" onClick={() => void handleClick()}>
              Grant permissions
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default ConsentPopup;
