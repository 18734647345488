import { forwardRef, ReactElement, RefObject } from 'react';
import styled from 'styled-components';

import { AutocompleteOption } from './Autocomplete';
import { AutocompletePeopleRef } from './AutocompletePeople';
import { AutocompletePeopleField } from './AutocompletePeopleField';
import MeetingPrioritySwitch from './MeetingPrioritySwitch';

const ParticipantsFieldWrapper = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    gap: 40px;
  }
`;

export type ParticipantsFieldProps = {
  selectedOptions: AutocompleteOption[];
  excludedOptions: AutocompleteOption[];
  onSelectedOptionsChange: (selectedOptions: AutocompleteOption[]) => void;
  disabled?: boolean;
  placeholder: string;
  tooltip?: string;
  icon: ReactElement;
  labelPosition: 'above' | 'after' | 'before';
  switchValue: string;
  switchCheck: boolean;
  onClick: () => void;
  focusOnElementRef?: RefObject<HTMLInputElement> | null;
  cursorPointer?: boolean;
};

export const ParticipantsField = forwardRef<AutocompletePeopleRef, ParticipantsFieldProps>((props, ref) => {
  return (
    <ParticipantsFieldWrapper>
      <AutocompletePeopleField
        ref={ref}
        focusOnElementRef={props.focusOnElementRef}
        selectedOptions={props.selectedOptions}
        excludedOptions={props.excludedOptions}
        onSelectedOptionsChange={props.onSelectedOptionsChange}
        isSelectedOptionsSortable={props.switchCheck}
        placeholder={props.placeholder}
        tooltip={props.tooltip}
        icon={props.icon}
        cursorPointer={props.cursorPointer}
        disabled={props.disabled}
      />
      <MeetingPrioritySwitch
        labelPosition={props.labelPosition}
        value={props.switchValue}
        switchCheck={props.switchCheck}
        onClick={props.onClick}
      />
    </ParticipantsFieldWrapper>
  );
});
