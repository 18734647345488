import { AvatarGroup, MenuButton, Theme } from '@fluentui/react-components';
import styled from 'styled-components';

import { Status } from '../../api/meetings';
import {
  MeetingCardContainerThemeConstructor,
  MembersBoxThemeConstructor,
  StatusDotThemeConstructor,
  themeName,
} from '../../helpers/theming';

export const StyledOptionalParticipantsButton = styled(MenuButton)`
  display: flex;
  place-self: flex-start;
  min-width: 34px;
  height: 34px;
`;

export const StyledMeetingCard = styled.div``;

export const Container = styled.div<{ theme: Theme; $themeName: themeName }>`
  margin-top: 15px;
  background-color: ${(props) => MeetingCardContainerThemeConstructor(props.theme).backgroundColor[props.$themeName]};
  padding: 30px;
  box-shadow: 0px 3px 15px rgba(20, 67, 114, 0.08);
  border-radius: 10px;
  ${(props) =>
    props.$themeName === 'contrast' &&
    `
    outline: 1px solid white;
  `}
`;

export const StatusWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
`;

export const StatusDot = styled.span<{ theme: Theme; $themeName: themeName; status: Status }>`
  background: ${(props) => {
    switch (props.status) {
      case Status.APPROVED:
        return StatusDotThemeConstructor(props.theme).backgroundApproved[props.$themeName];
      case Status.FORCE_APPROVED:
        return StatusDotThemeConstructor(props.theme).backgroundForceApproved[props.$themeName];
      case Status.IN_APPROVAL:
        return StatusDotThemeConstructor(props.theme).backgroundNotApproved[props.$themeName];
      case Status.EXPIRED:
        return StatusDotThemeConstructor(props.theme).backgroundNotApproved[props.$themeName];
      case Status.CANCELLED:
        return StatusDotThemeConstructor(props.theme).backgroundCancelled[props.$themeName];
    }
  }};
  border-radius: 100%;
  width: 16px;
  height: 16px;
`;

export const Members = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const MembersBox = styled.div<{ theme: Theme; $themeName: themeName }>`
  padding: 15px;
  width: 100%;
  background: ${(props) => MembersBoxThemeConstructor(props.theme).background[props.$themeName]};
  border-radius: 10px;
  ${(props) =>
    props.$themeName === 'contrast' &&
    `
    outline: 1px solid white;
  `}
`;

export const MembersBoxTitle = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
`;

// TODO reuse
export const SlotListWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const AvatarGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledAvatarGroup = styled(AvatarGroup)`
  display: flex;
  flex-wrap: wrap;
`;

export const MeetingTitleAndAvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  :nth-child(0) {
    width: 100%;
  }
`;
