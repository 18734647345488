import { Button, Dropdown, Option } from '@fluentui/react-components';
import { FC, ReactElement, useState } from 'react';
import styled from 'styled-components';

import { useViewSize } from '../hooks/useViewSize';
import InputWrapper from './InputWrapper';
import PaddedField from './PaddedField';

interface DropDownFieldProps {
  options: {
    value: string;
    label: string;
  }[];
  defaultValue: string;
  icon: ReactElement;
  cursorPointer: boolean;
  setValue: (optionValue: string) => void;
  disabled: boolean;
  defaultSelectedOptions: string;
  textAddition?: string;
  textOverload?: boolean;
  overloadText?: string;
  'data-testid'?: string;
}

const StyledDropdownField = styled(Dropdown)<{ $isDisabled: boolean }>`
  display: flex;
  justify-content: left;
  align-items: center;
  border-bottom-color: lightgray;
  height: 48px;

  button[role='combobox'] {
    padding-left: 0;
    font-size: 14px;
    font-weight: 600;
    color: var(--colorNeutralForeground1);
  }
  button {
    gap: 3px;
    justify-content: left;
    grid-template-columns: none;
    ${(props) => props.$isDisabled && 'color: gray'};
    svg {
      color: gray;
    }
    button {
      padding: 0;
    }
  }
`;

const DropDownFieldOneLiner: FC<DropDownFieldProps> = (props): ReactElement => {
  const viewSize = useViewSize();
  const [currentText, setCurrentText] = useState<string>(props.defaultValue);

  return (
    <PaddedField data-testid={props['data-testid']}>
      <InputWrapper>
        <StyledDropdownField
          button={
            <Button appearance="transparent" icon={props.icon}>
              {currentText}
            </Button>
          }
          $isDisabled={props.disabled}
          disabled={props.disabled}
          appearance="underline"
          listbox={{ style: { maxHeight: '300px', width: viewSize.isMobile ? '73%' : '27%' } }}
          defaultValue={props.defaultValue}
          defaultSelectedOptions={[props.defaultSelectedOptions]}
          onOptionSelect={(e, data) => {
            props.setValue(data.optionValue ?? props.defaultValue);

            const overloadText = props.overloadText ?? props.defaultValue;
            const defaultText = data.optionText ? `${props.textAddition ?? ''}${data.optionText}` : props.defaultValue;

            setCurrentText(
              //TODO: I know it's ugly but it works, so we schould refactor this when we'll have time
              props.textOverload && data.optionValue === '2' ? overloadText : defaultText,
            );
          }}
        >
          {props.options.map((item, index) => (
            <Option key={index} value={item.value}>
              {item.label}
            </Option>
          ))}
        </StyledDropdownField>
      </InputWrapper>
    </PaddedField>
  );
};

export default DropDownFieldOneLiner;
