import { Input } from '@fluentui/react-components';
import styled from 'styled-components';

const StyledInput = styled(Input)<{ header?: boolean }>`
  padding: 0;
  font-size: ${(props) => (props.header ? '21px' : '14px')};
  font-weight: 600;
  height: 48px;
  border-color: lightgray;
  width: 100%;
  input {
    padding: 0;
    height: 48px;
  }
`;

export default StyledInput;
