import { Button, Subtitle1, Title3 } from '@fluentui/react-components';
import { FC, ReactElement } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';
import styled from 'styled-components';

import { Paper } from '@/components/Paper';

import ButtonInnerWrapper from '../components/ButtonInnerWrapper';
import { useStore } from '../stores';

const Title = styled(Title3)`
  display: flex;
  justify-content: center;
`;

const ErrorNotification = styled(Subtitle1)`
  display: flex;
  justify-content: center;
`;

const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
`;

const ErrorPage: FC = (): ReactElement => {
  const { appStore } = useStore();
  const error = useRouteError() as { statusText: string; message: string };
  const navigate = useNavigate();

  const backHomeBtn = () => {
    appStore.isInTeams ? navigate('/create-meeting') : (window.location.href = 'https://youkeeps.com/');
  };

  return (
    <Paper padding={3}>
      <Title>Ooops!</Title>
      <ErrorNotification>Sorry, an unexpected error had occured.</ErrorNotification>
      <ButtonInnerWrapper>
        <Button appearance="primary" onClick={backHomeBtn}>
          Back to home page
        </Button>
      </ButtonInnerWrapper>
      <ErrorMessage>{error?.statusText || error?.message}</ErrorMessage>
    </Paper>
  );
};

export default ErrorPage;
