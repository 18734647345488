import moment from 'moment';
import { FC, ReactElement } from 'react';
import styled from 'styled-components';

interface MeetingInfoWrapperProps {
  interval?: string;
  timeZone: string;
}

const Label = styled.span`
  display: block;
  color: #8f9aa9;
  font-size: 14px;
  white-space: pre;
`;

const Duration = styled.span`
  display: block;
  color: #8f9aa9;
  font-size: 14px;
  white-space: pre;
`;

const MeetingInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const MeetingInfo: FC<MeetingInfoWrapperProps> = (props): ReactElement => {
  return (
    <MeetingInfoWrapper>
      <Duration>Meeting duration: {moment.duration(props.interval).asMinutes()} minutes</Duration>
      <Label>All times are in {props.timeZone}</Label>
    </MeetingInfoWrapper>
  );
};

export default MeetingInfo;
