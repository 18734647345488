import isEmail from 'validator/lib/isEmail';

const checkIsStandardEmail = (value: string): boolean => {
  const [, email] = value.match(/<?([^>]*)/) ?? [];
  if (!email) return false;

  return isEmail(email.trim(), { allow_display_name: false });
};

const checkIsNamedEmail = (value: string): boolean => {
  return isEmail(value.trim(), { require_display_name: true });
};

const checkIsEmail = (value: string): boolean => checkIsStandardEmail(value) || checkIsNamedEmail(value);

const parseEmailAddress = (value: string): { name?: string; email: string } | undefined => {
  const isStandardEmail = checkIsStandardEmail(value);
  const isNamedEmail = checkIsNamedEmail(value);

  if (isNamedEmail) {
    const [, rawName, rawEmail] = value.match(/^([^<]*)<([^>]*)>/) ?? [];

    // The regex removes all unescaped quotes
    const name = rawName?.replaceAll(/(?<!\\)['"]/g, '')?.trim();
    const email = rawEmail?.trim()?.toLowerCase();
    if (!name || !email) return;

    return { name, email };
  } else if (isStandardEmail) {
    const [, rawEmail] = value.match(/<?([^>]*)/) ?? [];

    const email = rawEmail.trim().toLowerCase();

    return { email };
  }
};

/**
 * The utility function that takes a string as an input and returns:
 * - list of parsed email addreses
 * - input string with all detected emails being removed
 *
 * Disclaimer: We can't simply use the .split() function because we need to
 * modify the query string more granularly, and using the .split() function
 * strips out the delimiter info. With this function, we would be able to parse
 * emails from string, but we wouldn't be able to maintain the query field's
 * value.
 */
export const parseQueryText = (
  query: string,
  options: {
    /**
     * By default, only emails with delimiter characters following them will be
     * parsed (i.e. email won't be parsed until the user hits space, comma
     * etc.). To override this behaviour set the ignoreTrailingDelimeter option
     * to true.
     *
     * Usually, it makes sense when a user pastes a text.
     */
    ignoreTrailingDelimeter?: boolean;
  } = {},
): { query: string; recipients: { name?: string; email: string }[] } => {
  const { ignoreTrailingDelimeter } = options;

  const queryLetters = [...query];

  // Since we're searching both named and not named emails, we'll have to use
  // chunks of different lengths hence we'll use different starting positions
  // for the search.
  let searchStandardEmailFromIndex = 0;
  let searchNamedEmailFromIndex = 0;

  // We keep track of opened quotes and brackets because we shouldn't detect
  // delimiters within those.
  let isOpenedDoubleQuotes = false;
  let isOpenedAngledBrackets = false;

  let chunks: { startIndex: number; endIndex: number; value: string }[] = [];

  // Iterate over all letters
  for (const [index, letter] of queryLetters.entries()) {
    const isLetterEscaped = query.charAt(index - 1) === `\\`;
    if (!isLetterEscaped) {
      if (letter === '"') isOpenedDoubleQuotes = !isOpenedDoubleQuotes;
      else if (letter === '<') isOpenedAngledBrackets = true;
      else if (letter === '>') isOpenedAngledBrackets = false;
    }

    // Again, everything within quotes and brackets shouldn't be delimiters
    const isDelimiterDetectable = !isOpenedDoubleQuotes && !isOpenedAngledBrackets;

    const isDelimiterLetter = isDelimiterDetectable && !!letter.match(/[ ,;]/);
    const isLastLetter = index + 1 === query.length;

    const standardChunkValue = query.slice(searchStandardEmailFromIndex, isDelimiterLetter ? index : index + 1);
    const namedChunkValue = query.slice(searchNamedEmailFromIndex, isDelimiterLetter ? index : index + 1);

    const isStandardEmail = checkIsStandardEmail(standardChunkValue);
    const isNamedEmail = checkIsNamedEmail(namedChunkValue);

    // List of conditions that indicate that named emails are impossible at this
    // point due to violation of specification and that we need to reset the
    // `searchNamedEmailFromIndex` variable.
    if (isDelimiterDetectable && (letter === '.' || (!isNamedEmail && isStandardEmail))) {
      searchNamedEmailFromIndex = index + 1;
    } else if (letter === '"' && !isLetterEscaped && isOpenedDoubleQuotes) {
      searchNamedEmailFromIndex = index;
    }

    // End the chunk if:
    // - Delimiter is detected
    // - It's end of the string and `ignoreTrailingDelimeter` is True
    const isChunkEnd = isDelimiterLetter || (ignoreTrailingDelimeter && isLastLetter);
    if (isChunkEnd) {
      const rawChunkValue = isNamedEmail
        ? query.slice(searchNamedEmailFromIndex, !isDelimiterLetter && isLastLetter ? index + 1 : index)
        : query.slice(searchStandardEmailFromIndex, !isDelimiterLetter && isLastLetter ? index + 1 : index);
      const chunkValue = rawChunkValue.trim();

      const startIndex = isNamedEmail ? searchNamedEmailFromIndex : searchStandardEmailFromIndex;
      const endIndex = index + 1;

      // At this step, if the email is detected as a named one then make sure
      // this email is the only chunk between the start & end indexes above
      if (isNamedEmail) {
        chunks = chunks.filter((chunk) => chunk.endIndex <= searchNamedEmailFromIndex);
      }

      chunks.push({ startIndex, endIndex, value: chunkValue });

      searchStandardEmailFromIndex = endIndex;
      searchNamedEmailFromIndex = isNamedEmail ? endIndex : searchNamedEmailFromIndex;
    }
  }

  const recipients: { name?: string; email: string }[] = [];
  let newQuery = query;
  let indexOffset = 0;

  // Iterate over all chunks. If some of them are valid emails, then cut them
  // off from the query string.
  for (const chunkValue of chunks) {
    if (!checkIsEmail(chunkValue.value)) continue;

    const parsedEmailAddress = parseEmailAddress(chunkValue.value);
    if (!parsedEmailAddress) continue;

    // Code below removes email address from the query string
    newQuery = [
      newQuery.slice(0, chunkValue.startIndex - indexOffset),
      newQuery.slice(chunkValue.endIndex - indexOffset),
    ].join('');
    indexOffset += chunkValue.endIndex - chunkValue.startIndex;

    // Add email address to the array of options
    recipients.push(parsedEmailAddress);
  }

  return { query: newQuery, recipients };
};
