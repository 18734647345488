import { useCallback, useState } from 'react';

/**
 * This hook lets you force a component to re-render.
 *
 * Additionaly you can track when component was forcibly re-rendered by
 * including the `forceUpdate` function into a dependencies of, for example,
 * the `useEffect` hook.
 */
export const useForceUpdate = (): (() => void) => {
  const [value, forceUpdate] = useState<number | undefined>(undefined);

  const handleforceUpdate = useCallback(() => {
    forceUpdate(Math.random());
  }, [value]);

  return handleforceUpdate;
};
