import { ReusableAbortController } from 'abort-utils';
import { isNil, uniqBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { assignRef } from 'use-callback-ref';

import { searchAttendees } from '../../api/calendar';
import { RequestAbortError } from '../../errors/RequestAbortError';
import { captureException } from '../../helpers/sentry';
import { useTeams } from '../../hooks/useTeams';
import { useThrottleCallback } from '../../hooks/useThrottleCallback';
import { useStore } from '../../stores';
import { isEmpty } from '../../utils/main';
import { sleep } from '../../utils/promise';
import { Autocomplete, AutocompleteOption, AutocompleteProps } from '../Autocomplete/Autocomplete';
import { AutocompletePeopleOption } from './AutocompletePeopleOption';
import { AutocompletePeopleOptionSkeleton } from './AutocompletePeopleOptionSkeleton';
import { AutocompletePeopleTag } from './AutocompletePeopleTag';
import { parseQueryText } from './utils';

const MAX_PEOPLE_OPTIONS = 5;

export type AutocompletePeopleRef = {
  selectedOptions: AutocompleteProps['selectedOptions'];
  parseQuery: (meta?: { ignoreTrailingDelimeter?: boolean }) => void;
};

export type AutocompletePeopleProps = AutocompleteProps & {
  /**
   * The list of options that should be excluded from the default list of
   * suggestions.
   *
   * Note: Options will be excluded from the list of suggestions only if the
   * query string is empty.
   */
  excludedDefaultOptions?: AutocompleteOption[];
  /**
   * By default this variable enables drag-n-drop of selected options (tags).
   * The AutocompletePeople component also adds input element to the tags so
   * user can manually change positions of tags.
   */
  isSelectedOptionsSortable?: boolean;
};

/**
 * This component is a wrapper around Autocomplete component.
 *
 * It adds a feature for parsing email addresses from the search field.
 * Everything else is passed through to the original component.
 */
export const AutocompletePeople = observer<AutocompletePeopleProps, AutocompletePeopleRef>(
  (props, ref) => {
    const { appStore } = useStore();
    const teams = useTeams();

    // Have internal state for the query string in case if it's not controlled by
    // parent component (i.e. `props.query` is undefined)
    const [uncontrolledQuery, setUncontrolledQuery] = useState('');
    const query = isNil(props.query) ? uncontrolledQuery : props.query;
    const setQuery = useCallback(
      (value: string) => {
        if (value !== uncontrolledQuery) setUncontrolledQuery(value);
        if (props.query && value !== props.query) props.onQueryChange?.(value, {});
      },
      [uncontrolledQuery, props.onQueryChange],
    );

    const [contacts, setContacts] = useState<{ id: string; name?: string; email: string }[]>([]);

    // Since searches are meant to be made in parallel, prepare reusable abort
    // controller
    const abortController = useMemo(() => new ReusableAbortController(), []);

    // Loading state is a combination of all parallel executions
    const isLoading = appStore.loading.isAny('attendeesAutocomplete');

    assignRef(ref, {
      selectedOptions: props.selectedOptions,
      parseQuery: ({ ignoreTrailingDelimeter } = {}) => {
        processQueryChange(query, { isPasted: ignoreTrailingDelimeter });
      },
    });

    /**
     * Single callback for processing changes that comes both from the
     * Autocomplete component and from the parent component (in case the query
     * field is controlled)
     */
    const processQueryChange = (value?: string, meta?: { isPasted?: boolean }) => {
      // If query is empty then there's nothing to parse, so return early
      if (isEmpty(value)) {
        setQuery(value ?? '');
        return;
      }

      // Parse list of emails from the query
      const parsedData = parseQueryText(value, { ignoreTrailingDelimeter: meta?.isPasted });

      // Remove found emails from the query string
      setQuery(parsedData.query);

      // Convert parsed emails into autocomplete options
      // TODO: Maybe get option label from the `props.options` if match found?
      const parsedOptions = parsedData.recipients.map<AutocompleteOption>(({ name, email }) => ({
        label: name && name !== email ? `${name} (${email})` : email,
        value: email,
      }));
      // And combine them with existing list of selected options
      const selectedOptions = uniqBy(
        [...(props.selectedOptions ?? []), ...parsedOptions],
        (selectedOption) => selectedOption.value,
      );
      // Trigger the callback with resulting array
      props.onSelectedOptionsChange?.(selectedOptions);
    };

    /**
     * In order to remove emails from the list of suggestions we:
     * - Increase search limit by 1 for every selected option
     * - Increase search limit by 1 for every excluded option
     * - Increase search limit by 1 for the current user email
     * - Exclude emails that were selected
     * - Exclude emails that were explicitly excluded (only when query is empty)
     * - Exclude current user email
     *
     * As a result we always have at least MAX_PEOPLE_OPTIONS of suggestions.
     */
    const searchLimit =
      MAX_PEOPLE_OPTIONS + (props.selectedOptions?.length ?? 0) + (props.excludedDefaultOptions?.length ?? 0) + 1;

    const performSearch = useThrottleCallback(
      async (rawQuery?: string) => {
        // Abort all ongoing requests
        abortController.abortAndReset();

        const executionId = String(Math.random());
        const query = !isEmpty(rawQuery) ? rawQuery : '';

        try {
          /**
           * This function is expected to be executed in paralled, therefore every
           * execution should control its own isolated loading state.
           */
          appStore.loading.on('attendeesAutocomplete', executionId);

          const response = await searchAttendees({ query, limit: searchLimit }, { controller: abortController });

          const newContacts = response.data
            .map((attendee) => ({
              id: attendee.id,
              name: attendee.displayName,
              email: attendee.emailAddress,
            }))
            .filter(
              (item) =>
                // Filter out emails that are not suitable for the autocomplete
                !item.email.startsWith('no-reply@') &&
                !item.email.startsWith('noreply@') &&
                // Also filter out the current user
                item.id !== appStore.user?.objectId,
            );

          setContacts(newContacts);
        } catch (error: any) {
          const isAbortError = error instanceof RequestAbortError;
          if (!isAbortError) {
            captureException(error);
            appStore.setSnackBar({
              message: error.message || 'Something went wrong. Please try again later.',
              type: 'error',
            });
            console.error(error);
          }
        } finally {
          // Before disabling the loading state wait for a next render so all
          // the changes are applied
          await sleep(0);

          appStore.loading.off('attendeesAutocomplete', executionId);
        }
      },
      500,
      [searchLimit],
    );

    /**
     * Every time query changes we should:
     * - Parse query string
     * - Update list of suggestions
     */
    const onQueryChange: AutocompleteProps['onQueryChange'] = (value, meta) => {
      processQueryChange(value, meta);
      performSearch(value);
    };

    const onQueryPaste: AutocompleteProps['onQueryPaste'] = (value, { event, selectionStart, selectionEnd, query }) => {
      // If query is empty then there's nothing to parse, so return early
      if (isEmpty(value)) {
        setQuery(value ?? '');
        return;
      }

      // Prevent `onQueryChange` from executing
      event.preventDefault();

      // Parse list of emails from the query
      const parsedData = parseQueryText(value, { ignoreTrailingDelimeter: true });

      // Remove found emails from the query string
      const newQuery = [query.slice(0, selectionStart), parsedData.query, query.slice(selectionEnd)].join('');
      setQuery(newQuery);

      // Convert parsed emails into autocomplete options
      // TODO: Maybe get option label from the `props.options` if match found?
      const parsedOptions = parsedData.recipients.map<AutocompleteOption>(({ name, email }) => ({
        label: name && name !== email ? `${name} (${email})` : email,
        value: email,
      }));
      // And combine them with existing list of selected options
      const selectedOptions = uniqBy(
        [...(props.selectedOptions ?? []), ...parsedOptions],
        (selectedOption) => selectedOption.value,
      );
      // Trigger the callback with resulting array
      props.onSelectedOptionsChange?.(selectedOptions);
    };

    /**
     * Perform the initial search only once the app is authenticated. Also
     * perform the search each time the query prop changes or search limit
     * increases/decreases, so we always have updated list of suggestion.
     */
    useEffect(() => {
      if (teams.isAuthenticated) {
        onQueryChange(query, {});
      }
    }, [teams.isAuthenticated, props.query, searchLimit]);

    const options = contacts
      // Map list of attendees to the autocomplete options
      .map((contact) => ({
        label: contact?.name && contact.name !== contact.email ? `${contact.name} (${contact.email})` : contact.email,
        value: contact.email,
      }))
      // Filter out already selected options
      .filter((option) => !props.selectedOptions?.find((selectedOption) => selectedOption.value === option.value))
      // Filter out options that were excplicitly excluded (only when the query is empty)
      .filter((option) =>
        isEmpty(query)
          ? !props.excludedDefaultOptions?.find((excludedOption) => excludedOption.value === option.value)
          : true,
      )
      // Make sure there are no more than MAX_PEOPLE_OPTIONS options in the list
      .slice(0, MAX_PEOPLE_OPTIONS);

    /**
     * Render custom tag element with input field
     */
    const renderTag = useCallback<NonNullable<AutocompleteProps['renderTag']>>(
      ({ index, option }) => {
        return (
          <AutocompletePeopleTag
            key={option.value}
            index={index}
            value={option.value}
            isSortable={props.isSelectedOptionsSortable}
            isDisabled={option.isDisabled}
          >
            {option.label ?? option.value}
          </AutocompletePeopleTag>
        );
      },
      [props.isSelectedOptionsSortable],
    );

    /**
     * Render custom option element with name and avatar.
     */
    const renderOption = useCallback<NonNullable<AutocompleteProps['renderOption']>>(
      ({ option, isLoading }) => {
        const contact = contacts.find((item) => item.email === option.value);
        const id = contact?.id;
        const name = contact?.name;

        return isLoading ? (
          <AutocompletePeopleOptionSkeleton key={option.value} value={option.value} />
        ) : (
          <AutocompletePeopleOption key={option.value} userId={id} name={name} value={option.value} />
        );
      },
      [contacts],
    );

    return (
      <Autocomplete
        {...props}
        query={query}
        options={options}
        renderTag={renderTag}
        renderOption={renderOption}
        onQueryChange={onQueryChange}
        onQueryPaste={onQueryPaste}
        isLoading={isLoading}
      />
    );
  },
  { forwardRef: true },
);
