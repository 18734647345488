import { TagPickerOption } from '@fluentui/react-components';
import { FC, memo } from 'react';

import { Skeleton } from '../Skeleton/Skeleton';

export type AutocompleteOptionSkeletonProps = { label?: string; value: string };

export const AutocompleteOptionSkeleton: FC<AutocompleteOptionSkeletonProps> = memo(({ label, value }) => {
  return (
    <TagPickerOption value={value}>
      <Skeleton isActive>{label ?? 'John Doe'}</Skeleton>
    </TagPickerOption>
  );
});
