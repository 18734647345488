import {
  BrowserAuthError,
  BrowserAuthErrorCodes,
  OIDC_DEFAULT_SCOPES,
  PublicClientApplication,
} from '@azure/msal-browser';

// TODO: Remove dependency on @azure/msal-browser package since those functions are user in both Microsoft and Google OAuth2 flows

const getPopupClient = () => {
  const microsoftAuthInstance = new PublicClientApplication({
    auth: {
      clientId: '',
      authority: '',
    },
    cache: { cacheLocation: 'sessionStorage' },
  });

  /**
   * At this stage we're accessing the internal API that wouldn't be available
   * without the custom patch we're applying to the @azure/msal-browser
   * package.
   *
   * This is the only way to start the authenticatioin flow in a popup window
   * and end it on the server side. Unfortunately, the official MSAL library
   * doesn't provide a way to do it.
   *
   * The main reason for this approach is to combine convinience of the popup
   * and security of not exposing the access token to the browser.
   *
   * Reference:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/discussions/3822
   */
  const popupClient = microsoftAuthInstance.controller.createPopupClient();

  return popupClient;
};

export const openAuthWindow = async (options: { url: string }): Promise<Window> => {
  const { url } = options;

  const popupClient = getPopupClient();

  const popupName = popupClient.generatePopupName(OIDC_DEFAULT_SCOPES, 'authority');
  const windowReference = popupClient.openSizedPopup(url, popupName, {});

  if (!windowReference) {
    throw new Error('Popup was blocked by the browser');
  }

  return Promise.resolve(windowReference);
};

export const waitAuthWindowCode = async (options: { windowReference: Window }): Promise<string | undefined> => {
  try {
    const { windowReference } = options;

    const popupClient = getPopupClient();

    const windowHashString = await popupClient.monitorPopupForHash(windowReference);
    const authCode = windowHashString.replace('#code=', '');

    return authCode;
  } catch (error) {
    if (error instanceof BrowserAuthError && error.errorCode === BrowserAuthErrorCodes.userCancelled) {
      return undefined;
    }

    throw error;
  }
};
