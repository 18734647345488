import { Check24Regular as IconDescription } from '@fluentui/react-icons';
import { FC, ReactElement } from 'react';
import styled from 'styled-components';

import IconWrapper from './IconWrapper';

const MeetingDescriptionBox = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const MeetingDescriptionText = styled.div`
  font-size: 16px;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
`;

const IconDescriptionWrapper = styled(IconWrapper)`
  margin-top: 0;
  svg {
    width: 20px;
    height: 20px;
  }
`;

interface MeetingDescriptionProps {
  descriptionText?: string;
}

const MeetingDescription: FC<MeetingDescriptionProps> = (props): ReactElement => {
  return (
    <MeetingDescriptionBox>
      <IconDescriptionWrapper>
        <IconDescription />
      </IconDescriptionWrapper>
      <MeetingDescriptionText>{props.descriptionText}</MeetingDescriptionText>
    </MeetingDescriptionBox>
  );
};

export default MeetingDescription;
