import { ProgressBar } from '@fluentui/react-components';
import { observer } from 'mobx-react-lite';
import React, { FC, ReactElement, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { useStore } from '../stores';
import Notification from './Notification';

const ProgressBarContainer = styled.div`
  width: 100%;
  position: fixed;
  z-index: 1;
`;

const NotificationContainer = styled.div`
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 2;
`;

const Overlays: FC = (): ReactElement => {
  const { appStore } = useStore();

  return (
    <Suspense fallback={null}>
      {appStore.isLoading && (
        <ProgressBarContainer>
          <ProgressBar thickness="large" />
        </ProgressBarContainer>
      )}
      <NotificationContainer>
        <Notification open={!!appStore.snackBar} />
      </NotificationContainer>
      <Outlet />
    </Suspense>
  );
};

export default observer(Overlays);
