import { TagPickerOption } from '@fluentui/react-components';
import styled from 'styled-components';

export const StyledTagPickerOption = styled(TagPickerOption)`
  display: flex;
  gap: 12px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.div`
  font-size: 14px;
`;

export const Email = styled.div<{ $size?: 'default' | 'small' }>`
  ${(props) => props.$size === 'small' && `font-size: 12px;`}
`;
