import { Switch, Text } from '@fluentui/react-components';
import { FC, ReactElement } from 'react';
import styled from 'styled-components';

import { useStore } from '@/stores';

import { useViewSize } from '../hooks/useViewSize';

const SwitchInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSwitch = styled(Switch)`
  display: flex;
  align-items: center;
  width: 20%;
  label {
    display: flex;
    align-items: center;
    font-size: 1em;
    span {
      margin-left: 6px;
      white-space: nowrap;
    }
  }
  :nth-child(2) {
    margin-right: 6px;
  }
`;

interface MeetingTypeSwitchProps {
  labelPosition: 'above' | 'after' | 'before';
  value: string;
  switchCheck: boolean;
  onClick: () => void;
}

const TeamsLogo = styled.img`
  content: url('/teams_20x1.svg');
  width: 20px;
  height: 20px;
`;

const GmeetLogo = styled.img`
  content: url('/gmeet_20x1.svg');
  width: 20px;
  height: 20px;
`;

const MeetingTypeSwitch: FC<MeetingTypeSwitchProps> = (props): ReactElement => {
  const { appStore } = useStore();
  const { authStore } = appStore;
  const viewSize = useViewSize();

  const icon = authStore.integrationProvider === 'google' ? <GmeetLogo /> : <TeamsLogo />;
  const label = authStore.integrationProvider === 'google' ? 'Gmeet meeting' : 'Teams meeting';

  return (
    <StyledSwitch
      labelPosition={props.labelPosition}
      label={
        <SwitchInfoWrapper>
          {icon}
          {!viewSize.isMobile && <Text>{label}</Text>}
        </SwitchInfoWrapper>
      }
      checked={props.switchCheck}
      onClick={props.onClick}
    />
  );
};

export default MeetingTypeSwitch;
