import { isSymbol } from 'lodash';
import { makeAutoObservable } from 'mobx';
import ms from 'ms';

import { type AppStore } from '@/store/AppStore';
import { castArray, MaybeArray } from '@/utils/array';
import { MaybePromise, sleep } from '@/utils/promise';

type LockCallback<R> = () => MaybePromise<R>;

export const DEFAULT_LOCK_TIMEOUT = ms('10 seconds');

export class LockStore {
  rootStore: AppStore;

  constructor(rootStore: AppStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  async acquireFactory<R>(
    inputKey: MaybeArray<string | number>,
    callback: LockCallback<R>,
    options: { timeout?: number } = {},
  ): Promise<R> {
    const { timeout = DEFAULT_LOCK_TIMEOUT } = options;

    const key = ['lockStore', ...castArray(inputKey)].join(':');

    const resultPromise = Promise.withResolvers<R>();

    void navigator.locks.request(key, async () => {
      try {
        const callbackPromise = callback();

        const timeoutSymbol = Symbol('timeout');

        const promise = await Promise.race([callbackPromise, sleep(timeout).then(() => timeoutSymbol)]);

        if (promise === timeoutSymbol || isSymbol(promise)) {
          resultPromise.reject(new Error(`Lock timeout reached for key: ${key}`));
        } else {
          resultPromise.resolve(promise);
        }
      } catch (error) {
        resultPromise.reject(error);
      }
    });

    return resultPromise.promise;
  }
}
