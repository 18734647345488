import type { TagProps } from '@fluentui/react-components';
import { Tag } from '@fluentui/react-components';
import { FC, MouseEvent, ReactElement, useRef } from 'react';
import styled from 'styled-components';
import type { SetRequired } from 'type-fest';

import { useAutocompleteContext } from './AutocompleteContext';

export type AutocompleteTagProps = SetRequired<TagProps, 'value'>;

// Styles were taken from the Outlook interface
const StyledTag = styled(Tag)`
  background-color: #fff8df;
  border-color: #ffd335;
  color: #242424;
  padding: 0px 4px;
  margin: 4px 0;

  &:hover {
    background-color: #fff2c3;
  }

  span[class*='primaryText'] {
    padding: 0;
    margin-right: 4px;
    font-size: 14px;
  }

  span[class*='dismissIcon'] {
    padding: 0;
  }
`;

// TODO: Figure out how to fix the issue without disabling the ability to select a text within a tag

/**
 * This component is a simple wrapper around the standard Tag component to
 * change its default behaviour.
 *
 * Default behaviour: Clicking in any place would trigger the dismiss event
 * leading to removing the tag from the list.
 *
 * Updated behaviour: The clicking event is suppressed unless the click was made
 * directly onto the close icon.
 */
export const AutocompleteTag: FC<AutocompleteTagProps> = (props): ReactElement => {
  const autocompleteContext = useAutocompleteContext();

  const rootRef = useRef<HTMLButtonElement & HTMLSpanElement>(null);

  const onClick = (event: MouseEvent<HTMLButtonElement & HTMLSpanElement>) => {
    const targetElement = event.target as HTMLElement;
    const dismissButtonElement = rootRef.current?.querySelector(`[class*='dismissIcon']`);

    if (dismissButtonElement && !dismissButtonElement.contains(targetElement)) {
      event.preventDefault();
    }

    props.onClick?.(event);
  };

  return (
    <StyledTag
      {...props}
      ref={rootRef}
      onClick={onClick}
      disabled={autocompleteContext?.isDisabled || props.disabled}
    />
  );
};
