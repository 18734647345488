import { isNil, isString } from 'lodash';
import styled from 'styled-components';

import { MaybeArray } from '@/utils/array';

export const StyledRootDiv = styled.div<{
  $direction: string;
  $alignContent?: string;
  $alignItems?: string;
  $alignSelf?: string;
  $justifyContent?: string;
  $spacingColumn: number | string | 'inherit';
  $spacingRow: number | string | 'inherit';
  $grow?: number;
  $growTemplate?: MaybeArray<number | undefined>;
  $shrink?: number;
  $shrinkTemplate?: MaybeArray<number | undefined>;
  $inline?: boolean;
  $wrap?: boolean;
  $fullWidth?: boolean;
  $fullHeight?: boolean;
}>`
  display: ${(props) => (props.$inline ? 'inline-flex' : 'flex')};
  min-width: 0;
  ${(props) => `flex-direction: ${props.$direction};`}
  ${(props) => props.$alignContent && `align-content: ${props.$alignContent};`}
  ${(props) => props.$alignItems && `align-items: ${props.$alignItems};`}
  ${(props) => props.$alignSelf && `align-self: ${props.$alignSelf};`}
  ${(props) => props.$justifyContent && `justify-content: ${props.$justifyContent};`}
  ${(props) => `column-gap: ${isString(props.$spacingColumn) ? props.$spacingColumn : `${props.$spacingColumn}px`};`}
  ${(props) => `row-gap: ${isString(props.$spacingRow) ? props.$spacingRow : `${props.$spacingRow}px`};`}
  ${(props) => !isNil(props.$grow) && `flex-grow: ${props.$grow};`}
  ${(props) => !isNil(props.$shrink) && `flex-shrink: ${props.$shrink};`}
  ${(props) => props.$wrap && 'flex-wrap: wrap;'}
  ${(props) => props.$fullWidth && 'width: 100%;'}
  ${(props) => props.$fullHeight && 'height: 100%;'}
`;
