import localForage from 'localforage';

localForage.config({
  name: 'Youkeeps',
  storeName: 'stores',
  description: 'Persist MobX stores',
});

export { localForage };

export const persistConfig = {
  storage: localForage,
  stringify: false,
};
