import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Spinner,
} from '@fluentui/react-components';
import { FC, ReactElement, useState } from 'react';
import styled from 'styled-components';

const Body = styled(DialogBody)`
  text-align: center;
  margin-bottom: 15px;
`;

const Actions = styled(DialogActions)`
  display: flex;
  justify-content: center;
`;

const TextInContent = styled.div``;

interface EndVotingEarlyPopupProps {
  /** Whether the popup is open. */
  open: boolean;
  /**
   * The callback is executed when user clicks "No" Button.
   */
  onNo: () => void;
  /**
   * The callback is executed when user clicks "Yes" Button.
   */
  onYes: () => Promise<void>;
  /**
   * The list of participants that doesn't vote.
   */
  attendees: string[];
}

export const EndVotingEarlyPopup: FC<EndVotingEarlyPopupProps> = (props): ReactElement => {
  const [isYesClicked, setIsYesClicked] = useState<boolean>(false);
  return (
    <Dialog open={props.open}>
      <DialogSurface>
        <Body>
          <DialogTitle>Are you considering closing the voting now?</DialogTitle>
          <DialogContent>
            <TextInContent>Please note, ending the voting prematurely will prevent attendees</TextInContent>
            {props.attendees.map((attendie, index) => (
              <TextInContent key={index}>
                {index + 1}. {attendie}
              </TextInContent>
            ))}
            <TextInContent>from voting.</TextInContent>
            <TextInContent>They will receive the meeting confirmation via email.</TextInContent>
          </DialogContent>
        </Body>
        <Actions>
          <Button
            appearance="secondary"
            disabled={isYesClicked}
            onClick={() => {
              props.onYes();
              setIsYesClicked(true);
            }}
          >
            {!isYesClicked ? `Yes` : <Spinner size="tiny" />}
          </Button>
          <Button disabled={isYesClicked} appearance="primary" onClick={props.onNo}>
            No
          </Button>
        </Actions>
      </DialogSurface>
    </Dialog>
  );
};
