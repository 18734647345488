import { FC, memo } from 'react';

import { Avatar } from '../Avatar';
import { Email, InfoContainer, Name, StyledTagPickerOption } from './styles';

export type AutocompletePeopleOptionProps = {
  /** If provided, user's avatar will be fetched */
  userId?: string;
  /** If provided, user's name will be displayed alongside the email address */
  name?: string;
  /** User's email address and also value of the option */
  value: string;
};

export const AutocompletePeopleOption: FC<AutocompletePeopleOptionProps> = memo(({ userId, name, value }) => {
  // There are many cases when name field is populated but actually it's just a
  // copy of the email address
  const isNameProvided = name && name !== value;

  return (
    <StyledTagPickerOption value={value}>
      <Avatar color="colorful" size={40} name={name ?? value} userId={userId} />
      <InfoContainer>
        {isNameProvided && <Name>{name}</Name>}
        <Email $size={isNameProvided ? 'small' : 'default'}>{value}</Email>
      </InfoContainer>
    </StyledTagPickerOption>
  );
});
