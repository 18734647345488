import {
  DndContext,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  pointerWithin,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { TagPickerGroup, TagPickerGroupProps } from '@fluentui/react-components';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { CommonProps } from '../../utils/react';
import type { AutocompleteOption } from './Autocomplete';
import { AutocompleteDraggable } from './AutocompleteDraggable';

const StyledTagPickerGroup = styled(TagPickerGroup)`
  padding: 4px 6px 4px 0px;
  row-gap: 0px;
`;

export type AutocompleteTagGroupProps = CommonProps<
  {
    /** List of options (tags) to render. */
    selectedOptions?: AutocompleteOption[];
    /** The callback is fired when options are re-ordered. */
    onSelectedOptionsChange?: (selectedOptions: AutocompleteOption[]) => void;
    /** The callback is used to render tags. */
    renderTag: (data: { index: number; option: AutocompleteOption }) => ReactNode;
    /** If `true`, tags can be re-ordered @default false */
    isSortable?: boolean;
  } & TagPickerGroupProps
>;

/**
 * This component renders a list of tags. Also it abstracts all the logic from
 * `dnd-kit` and `framer-motion`.
 */
export const AutocompleteTagGroup: FC<AutocompleteTagGroupProps> = ({
  selectedOptions = [],
  onSelectedOptionsChange: onSelectedOptionsSort,
  renderTag,
  isSortable,
  ...rest
}) => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | undefined>();

  const { activeIndex, activeSelectedOption } = useMemo(() => {
    if (!activeId) return {};

    const index = selectedOptions.findIndex((option) => option.value === String(activeId));
    const option = selectedOptions[index];
    if (!option) return {};

    return { activeIndex: index, activeSelectedOption: option };
  }, [selectedOptions, activeId]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  );

  const handleDragStart = useCallback(
    ({ active }: DragStartEvent) => {
      setActiveId(active.id);
    },
    [setActiveId],
  );

  const handleDragOver = useCallback(
    ({ active, over }: DragOverEvent) => {
      if (!over || !active || active.id === over.id) return;

      const activeIndex = selectedOptions.findIndex((option) => option.value === String(active.id));
      const overIndex = selectedOptions.findIndex((option) => option.value === String(over.id));
      if (activeIndex === -1 || overIndex === -1) return;

      onSelectedOptionsSort?.(arrayMove(selectedOptions, activeIndex, overIndex));
    },
    [selectedOptions, onSelectedOptionsSort],
  );

  const handleDragCancel = useCallback(() => {
    setActiveId(undefined);
  }, [setActiveId]);

  const handleDragEnd = useCallback(() => {
    setActiveId(undefined);
  }, [setActiveId]);

  const sortableContextItems = selectedOptions.map((option) => option.value);

  const disableStrategy = useMemo(() => () => null, []);

  return (
    <StyledTagPickerGroup {...rest}>
      <DndContext
        sensors={sensors}
        collisionDetection={pointerWithin}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragCancel={handleDragCancel}
        onDragEnd={handleDragEnd}
      >
        <SortableContext disabled={!isSortable} items={sortableContextItems} strategy={disableStrategy}>
          {selectedOptions.map((selectedOption, selectedOptionIndex) => (
            <AutocompleteDraggable key={selectedOption.value} value={selectedOption.value}>
              {renderTag({ index: selectedOptionIndex, option: selectedOption })}
            </AutocompleteDraggable>
          ))}
        </SortableContext>
        <DragOverlay>
          {activeSelectedOption ? renderTag({ index: activeIndex, option: activeSelectedOption }) : undefined}
        </DragOverlay>
      </DndContext>
    </StyledTagPickerGroup>
  );
};
