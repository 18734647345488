import { isNil, isString } from 'lodash';
import { css, keyframes, styled } from 'styled-components';

import { currentThemeString } from '../../helpers/theming';

const formatUnit = (value: string | number) => (isString(value) ? value : `${value}px`);

const pulseKeyframes = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

const waveKeyframes = keyframes`
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const animationStyles = {
  pulse: css`
    animation: ${pulseKeyframes} 1.5s ease-in-out 0.5s infinite;
  `,
  wave: css`
    position: relative;
    overflow: hidden;

    &::after {
      animation: ${waveKeyframes} 1.6s linear 0.5s infinite;
      background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);
      content: '';
      position: absolute;
      transform: translateX(-100%);
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
    }
  `,
};

const variantStyles = {
  text: css`
    margin-top: 0;
    margin-bottom: 0;
    height: auto;
    transform-origin: 0 60%;
    transform: scale(1, 0.6);
    border-radius: 4px;

    &:empty:before {
      content: '\\00a0';
    }
  `,
  rect: css`
    border-radius: 4px;
  `,
  circle: css`
    border-radius: 50%;
  `,
};

export const Root = styled.div<{
  $themeString: string;
  $isWithChildren: boolean;
  $width?: string | number;
  $height?: string | number;
  $variant?: 'text' | 'rect' | 'circle';
  $animation?: 'pulse' | 'wave' | false;
}>`
  /* General styles */
  background-color: rgba(
    ${(props) => (currentThemeString(props.$themeString) === 'default' ? '0, 0, 0' : '255, 255, 255')},
    0.11
  );
  ${(props) => {
    if (isNil(props.$width)) {
      if (props.$isWithChildren) return `max-width: fit-content;`;
      else return undefined;
    }

    return `width: ${formatUnit(props.$width)};`;
  }}
  ${(props) => {
    if (isNil(props.$height)) {
      if (props.$isWithChildren) return `height: auto;`;
      else return `height: 1.2em;`;
    }

    return `height: ${formatUnit(props.$height)};`;
  }}

  /* Styles specific for some variants */
  ${(props) => {
    switch (props.$variant) {
      case 'text':
        return variantStyles.text;
      case 'rect':
        return variantStyles.rect;
      case 'circle':
        return variantStyles.circle;
    }
  }}

  /* Animation styles */
  ${(props) => {
    switch (props.$animation) {
      case 'pulse':
        return animationStyles.pulse;
      case 'wave':
        return animationStyles.wave;
    }
  }}

  /* Positioning and sizing styles */
  ${(props) =>
    props.$isWithChildren &&
    css`
      > * {
        visibility: hidden;
      }
    `}
`;
