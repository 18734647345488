import { Avatar as AvatarBase, AvatarProps as AvatarBaseProps } from '@fluentui/react-components';
import { FC, useEffect, useMemo, useState } from 'react';

import { getUserAvatar } from '@/api/calendar';

// TODO: Create wrapper for `AvatarGroupItem` with the same functionality

export type AvatarProps = AvatarBaseProps & {
  /** If provided, tries to find actual avatar image of that user. */
  userId?: string;
};

/**
 * A small wrapper around FluentUI Avatar component.
 *
 * It has additional `userId` prop that is used to fetch the actual user avatar image.
 */
export const Avatar: FC<AvatarProps> = ({ userId, ...rest }) => {
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>();

  /** This object is passed to the original FluentUI component. */
  const avatarImageProp = useMemo<AvatarProps['image']>(() => ({ src: avatarUrl }), [avatarUrl]);

  const fetchAvatarUrl = async (userId: string) => {
    const response = await getUserAvatar({ userId });
    if (response.byteLength === 0) return;

    // Convert the image to URL
    // const blob = await response.blob();
    const blob = new Blob([response]);
    const url = URL.createObjectURL(blob);

    return url;
  };

  // Every time `avatarUrl` changes re-fetch the image or clean up the state (to
  // show default placeholder).
  useEffect(() => {
    if (!userId) {
      setAvatarUrl(undefined);
    } else {
      fetchAvatarUrl(userId).then((avatarUrl) => {
        // `avatarUrl` is going to be either URL or empty value
        setAvatarUrl(avatarUrl);
      });
    }
  }, [userId]);

  return <AvatarBase {...rest} image={avatarImageProp} />;
};
