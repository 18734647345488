import moment from 'moment-timezone';

import { Vote } from '../api/meetings';
import { CalendarEvent } from '../components/Calendar';
import { ChoiceObject } from '../components/TimeSlots';

const dateFormat = 'YYYY-MM-DD';
const timeFormat = 'HH:mm';

interface mapChoicesProps {
  existingSlots: CalendarEvent[];
  slotsIds: string[];
  hideDisabled: boolean;
}

const slotsComparator = (lSlot: ChoiceObject, rSlot: ChoiceObject) => {
  return moment(lSlot.time, timeFormat).diff(moment(rSlot.time, timeFormat));
};

export const mapChoices = ({
  existingSlots,
  slotsIds,
  hideDisabled,
}: mapChoicesProps): { date: string; slots: ChoiceObject[] }[] => {
  const choicesMap = new Map();

  for (const slot of existingSlots) {
    const key = moment(slot.start).format(dateFormat);
    const time = moment(slot.start).format(timeFormat);
    const isDisabled = (slot.id && slotsIds.length > 0 && !slotsIds.includes(slot.id)) || slot.isOutdated;

    if (isDisabled && hideDisabled) continue;

    const currentSlots = choicesMap.get(key);

    if (!currentSlots) {
      choicesMap.set(key, [
        {
          time: time,
          id: slot.id,
          date: slot.start,
          isDisabled,
        },
      ]);
    } else {
      choicesMap.set(key, [
        ...currentSlots,
        {
          time: time,
          id: slot.id,
          date: slot.start,
          isDisabled,
        },
      ]);
    }
  }

  return Array.from(choicesMap, ([date, slots]) => ({
    date,
    slots: slots.sort(slotsComparator),
  }));
};

export const getSmallestVote = (votes: Vote[]): Vote | undefined => {
  if (votes.length === 0) return undefined;
  const res = Math.min(...votes.map((item) => item.slotIds.length));
  return votes.find((item) => item.slotIds.length === res);
};
