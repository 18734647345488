// TODO: This is a temp solution. Move all the code to a shared package once we create one.

export enum ProviderFeature {
  default = 'default',
  readOnlyCalendar = 'readOnlyCalendar',
}

export type IntegrationProviderScope = { name?: string; value: string };

// TODO: Maybe add name & description to provide info to a user why we need those scopes?
const MicrosoftProviderScopes = {
  'User.Read': { value: 'User.Read' },
  'User.ReadBasic.All': { value: 'User.ReadBasic.All' },
  'Calendars.Read': { value: 'Calendars.Read' },
  'Calendars.ReadWrite': { value: 'Calendars.ReadWrite' },
  'Mail.Send': { value: 'Mail.Send' },
  'People.Read': { value: 'People.Read' },
} as const satisfies Record<string, IntegrationProviderScope>;

export const MicrosoftProviderFeatureScopes: Record<ProviderFeature, IntegrationProviderScope[]> = {
  [ProviderFeature.default]: Object.values(MicrosoftProviderScopes),
  [ProviderFeature.readOnlyCalendar]: [MicrosoftProviderScopes['Calendars.Read']],
};

const GoogleProviderScopes = {
  'userinfo.profile': { name: 'userinfo.profile', value: 'https://www.googleapis.com/auth/userinfo.profile' },
  calendar: { name: 'calendar', value: 'https://www.googleapis.com/auth/calendar' },
  'contacts.readonly': { name: 'contacts.readonly', value: 'https://www.googleapis.com/auth/contacts.readonly' },
  'contacts.other.readonly': {
    name: 'contacts.other.readonly',
    value: 'https://www.googleapis.com/auth/contacts.other.readonly',
  },
  'gmail.send': { name: 'gmail.send', value: 'https://www.googleapis.com/auth/gmail.send' },
} as const satisfies Record<string, IntegrationProviderScope>;

export const GoogleProviderFeatureScopes: Record<ProviderFeature, IntegrationProviderScope[]> = {
  [ProviderFeature.default]: Object.values(GoogleProviderScopes),
  // TODO: Read-only calendar scope?
  [ProviderFeature.readOnlyCalendar]: [GoogleProviderScopes.calendar],
};
