import { TeamsUserCredential } from '@microsoft/teamsfx';
import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

import { AuthStore } from '@/features/auth/stores/auth.store';
import { GoogleStore } from '@/features/google/stores/google.store';
import { MicrosoftStore } from '@/features/microsoft-teams/stores/microsoft.store';
import { UserStore } from '@/features/user/stores/user.stores';

import { Event, getUserProfile, postUserEvent, Profile, sendEmail } from '../api/calendar';
import { setAppStore } from '../api/helpers';
import {
  deleteUserVote,
  DeleteUserVoteProps,
  FreeDateMeeting,
  getFreeDateMeetingByToken,
  getMyMeetings,
  getMyMeetingsAll,
  postCancelMeeting,
  PostCancelMeetingProps,
  postEndVotingEarly,
  PostEndVotingEarlyProps,
  postFreeDateMeeting,
  PostFreeDateMeetingProps,
  postResendInvitations,
  PostResendInvitationsProps,
  postVoteForMeeting,
  PostVoteForMeetingProps,
  updateFreeDateMeeting,
  UpdateFreeDateMeetingProps,
} from '../api/meetings';
import { persistConfig } from '../config/store.config';
import { captureException } from '../helpers/sentry';
import { LoadingStoreFactory } from './LoadingStoreFactory';
import { LockStore } from './lock.store';

interface User {
  displayName: string;
  objectId: string;
  preferredUserName: string;
  tenantId: string;
}

interface SnackBarProps {
  message: string;
  type: 'error' | 'warning' | 'info' | 'success';
}

export class AppStore {
  lockStore: LockStore;

  microsoftStore: MicrosoftStore;

  googleStore: GoogleStore;

  authStore: AuthStore;

  userStore: UserStore;

  isInitialized: boolean = false;

  isAppInitialized = false;

  themeMode: 'light' | 'dark' | 'contrast' = 'dark';

  fullName: string = '';

  user?: User = undefined;

  email?: string = undefined;

  profile?: Profile = undefined;

  events: Event[] = [];

  snackBar?: SnackBarProps = undefined;

  isLoading: boolean = false;

  loading = new LoadingStoreFactory({ names: ['attendeesAutocomplete'] });

  get teamsUserCredential(): TeamsUserCredential | undefined {
    return this.microsoftStore.teamsUserCredential;
  }

  needConsent: boolean = false;

  freeDateMeeting?: FreeDateMeeting = undefined;

  myMeetings: FreeDateMeeting[] = [];

  isInTeams: boolean | undefined = undefined;

  isMyMeetingsAllLoaded: boolean = false;

  votingCalendarTokenSaveId?: string = undefined;

  votingCalendarProviderType?: 'microsoft' | 'google' = undefined;

  constructor() {
    this.lockStore = new LockStore(this);
    this.microsoftStore = new MicrosoftStore(this);
    this.googleStore = new GoogleStore(this);
    this.authStore = new AuthStore(this);
    this.userStore = new UserStore(this);

    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      ...persistConfig,
      name: 'App',
      properties: ['fullName', 'user'],
    }).then(
      () => {
        setAppStore(this);
        this.isInitialized = true;
      },
      () => {
        console.error('PERSISTABLE ERROR');
      },
    );
  }

  async initializeApp() {
    this.setIsLoading(true);

    this.detectThemeMode();

    await this.microsoftStore.initialize();
    await this.googleStore.initialize();
    await this.authStore.initialize();

    this.isAppInitialized = true;
    this.setIsLoading(false);
  }

  // ===================
  // ===== ACTIONS =====
  // ===================

  resetAppStore = () => {
    this.fullName = '';
    this.user = undefined;
    this.email = undefined;

    this.isLoading = false;
    this.freeDateMeeting = undefined;
    this.myMeetings = [];
  };

  requestConsent = async () => {
    try {
      this.isLoading = true;
      await this.authStore.requestPermissions();
      this.setNeedConsent(false);
      this.isLoading = false;
    } catch (error: any) {
      this.isLoading = false;
      captureException(error);
      this.setSnackBar({ message: error.message || 'Something went wrong. Please try again later.', type: 'error' });
      console.error(error);
    }
  };

  detectThemeMode = () => {
    const systemTheme = window.matchMedia?.('(prefers-color-scheme: light)').matches ? 'light' : 'dark';

    this.themeMode = systemTheme;
  };

  setSnackBar = (value: SnackBarProps | undefined) => {
    this.snackBar = value;
  };

  getUserInfo = async () => {
    if (!this.teamsUserCredential) return;
    try {
      this.isLoading = true;

      const userInfo = await this.authStore.getTeamsCredentialsUserInfo();
      this.user = userInfo;

      this.isInTeams = true;
      this.isLoading = false;
    } catch (error: any) {
      this.isLoading = false;
      captureException(error);
      this.setSnackBar({ message: error.message || 'Something went wrong. Please try again later.', type: 'error' });
      console.error(error);
      this.isInTeams = false;
    }
  };

  getUserProfile = async () => {
    if (!this.teamsUserCredential) return;
    try {
      this.isLoading = true;
      const result = await getUserProfile();
      this.profile = result.data;
      this.isLoading = false;
    } catch (error: any) {
      this.isLoading = false;
      captureException(error);
      this.setSnackBar({ message: error.message || 'Something went wrong. Please try again later.', type: 'error' });
      console.error(error);
    }
  };

  updateFreeDateMeeting = async (payload: UpdateFreeDateMeetingProps) => {
    if (!this.teamsUserCredential) return;

    const oldVersion = this.myMeetings.find((item) => item.id === payload.id);
    if (!oldVersion) return;

    const newVersion = {
      ...oldVersion,
      participants: payload.participants,
      isPriorityMeeting: payload.isPriorityMeeting,
      optionalParticipants: payload.optionalParticipants,
      meetingDescription: payload.meetingDescription,
    };

    // Inject new version into the array optimistically
    this.myMeetings = this.myMeetings.map((item) => (item.id === payload.id ? { ...newVersion } : { ...item }));

    try {
      this.isLoading = true;
      const result = await updateFreeDateMeeting(payload);
      this.myMeetings = this.myMeetings.map((item) => (item.id === payload.id ? { ...result.data } : { ...item }));
      this.isLoading = false;
      this.setSnackBar({ message: 'Successfully updated a meeting', type: 'success' });
    } catch (error: any) {
      this.isLoading = false;
      captureException(error);
      this.setSnackBar({ message: error.message || 'Something went wrong. Please try again later.', type: 'error' });
      console.error(error);
      // Inject old version back into the array
      this.myMeetings = this.myMeetings.map((item) => (item.id === payload.id ? { ...oldVersion } : { ...item }));
    }
  };

  sendEmail = async () => {
    if (!this.teamsUserCredential) return;
    try {
      this.isLoading = true;
      await sendEmail();
      this.isLoading = false;
    } catch (error: any) {
      this.isLoading = false;
      captureException(error);
      this.setSnackBar({
        message: error.message || 'Something went wrong. Please try again later.',
        type: 'error',
      });
      console.error(error);
    }
  };

  getUserEvents = async () => {
    if (!this.teamsUserCredential) return;
    try {
      this.isLoading = true;
      const result = await this.userStore.getUserCalendarEvents();
      this.events = result;
      this.isLoading = false;
    } catch (error: any) {
      this.isLoading = false;
      captureException(error);
      this.setSnackBar({ message: error.message || 'Something went wrong. Please try again later.', type: 'error' });
      console.error(error);
    }
  };

  postUserEvent = async (payload: Event) => {
    if (!this.teamsUserCredential) return;
    try {
      this.isLoading = true;
      const result = await postUserEvent(payload);
      this.events = [...this.events, result.graphClientMessage];
      this.isLoading = false;
      this.setSnackBar({ message: 'Successfully created an event', type: 'success' });
    } catch (error: any) {
      this.isLoading = false;
      captureException(error);
      this.setSnackBar({ message: error.message || 'Something went wrong. Please try again later.', type: 'error' });
      console.error(error);
    }
  };

  createFreeDateMeeting = async (payload: PostFreeDateMeetingProps) => {
    if (!this.teamsUserCredential) return;
    try {
      this.isLoading = true;
      const result = await postFreeDateMeeting(payload);
      this.isLoading = false;
      this.setSnackBar({ message: 'Successfully created a meeting', type: 'success' });
      this.freeDateMeeting = result.data;
      return result.data;
    } catch (error: any) {
      this.isLoading = false;
      captureException(error);
      this.setSnackBar({ message: error.message || 'Something went wrong. Please try again later.', type: 'error' });
      console.error(error);
      return undefined;
    }
  };

  voteForMeeting = async (payload: PostVoteForMeetingProps, token: string) => {
    try {
      this.isLoading = true;
      const result = await postVoteForMeeting(payload);
      this.isLoading = false;
      this.setSnackBar({ message: 'Successfully submitted a vote', type: 'success' });
      return result.data;
    } catch (error: any) {
      this.isLoading = false;
      captureException(error);
      this.setSnackBar({ message: error.message || 'Something went wrong. Please try again later.', type: 'error' });
      console.error(error);
      if (error.cause?.status === 409) this.getFreeDateMeetingByToken(token);
      return undefined;
    }
  };

  postEndVotingEarly = async (payload: PostEndVotingEarlyProps) => {
    if (!this.teamsUserCredential) return;
    const oldVersion = this.myMeetings.find((item) => item.id === payload.meetingId);
    if (!oldVersion) return;
    try {
      this.isLoading = true;
      const result = await postEndVotingEarly(payload);

      // Inject new version into the array
      this.myMeetings = this.myMeetings.map((item) =>
        item.id === payload.meetingId ? { ...result.data } : { ...item },
      );

      this.isLoading = false;
      this.setSnackBar({ message: 'Voting was successfully ended early', type: 'success' });
    } catch (error: any) {
      this.isLoading = false;
      captureException(error);
      this.setSnackBar({ message: error.message || 'Something went wrong. Please try again later.', type: 'error' });
      return undefined;
    }
  };

  postCancelMeeting = async (payload: PostCancelMeetingProps) => {
    if (!this.teamsUserCredential) return;
    const oldVersion = this.myMeetings.find((item) => item.id === payload.meetingId);
    if (!oldVersion) return;
    try {
      this.isLoading = true;
      const result = await postCancelMeeting(payload);

      // Inject new version into the array
      this.myMeetings = this.myMeetings.map((item) =>
        item.id === payload.meetingId ? { ...result.data } : { ...item },
      );

      this.isLoading = false;
      this.setSnackBar({ message: 'Meeting was successfully canceled', type: 'success' });
    } catch (error: any) {
      this.isLoading = false;
      captureException(error);
      this.setSnackBar({ message: error.message || 'Something went wrong. Please try again later.', type: 'error' });
      return undefined;
    }
  };

  postResendInvitations = async (payload: PostResendInvitationsProps) => {
    if (!this.teamsUserCredential) return;
    try {
      this.isLoading = true;
      const result = await postResendInvitations(payload);
      this.isLoading = false;
      this.setSnackBar({ message: 'The nudge emails have been sent to the pending voters.', type: 'success' });
      return result.data;
    } catch (error: any) {
      this.isLoading = false;
      captureException(error);
      this.setSnackBar({ message: error.message || 'Something went wrong. Please try again later.', type: 'error' });
      return undefined;
    }
  };

  getFreeDateMeetingByToken = async (token: string) => {
    try {
      this.isLoading = true;
      const result = await getFreeDateMeetingByToken(token);
      this.isLoading = false;
      this.freeDateMeeting = result.data.meeting;
      this.email = result.data.tokenData.email;
      return result.data;
    } catch (error: any) {
      this.isLoading = false;
      captureException(error);
      this.setSnackBar({ message: error.message || 'Something went wrong. Please try again later.', type: 'error' });
      console.error(error);
      return undefined;
    }
  };

  getMyMeetings = async () => {
    if (!this.teamsUserCredential) return;
    try {
      this.isLoading = true;
      const result = await getMyMeetings();
      this.isLoading = false;
      this.myMeetings = result.data;
    } catch (error: any) {
      this.isLoading = false;
      captureException(error);
      this.setSnackBar({ message: error.message || 'Something went wrong. Please try again later.', type: 'error' });
      console.error(error);
    }
  };

  getMyMeetingsAll = async () => {
    if (!this.teamsUserCredential) return;
    try {
      this.isLoading = true;
      const result = await getMyMeetingsAll();
      this.isLoading = false;
      this.myMeetings = result.data;
      this.isMyMeetingsAllLoaded = true;
    } catch (error: any) {
      this.isLoading = false;
      captureException(error);
      this.setSnackBar({ message: error.message || 'Something went wrong. Please try again later.', type: 'error' });
      console.error(error);
    }
  };

  deleteUserVote = async (payload: DeleteUserVoteProps) => {
    if (!this.teamsUserCredential) return;
    try {
      this.isLoading = true;
      const result = await deleteUserVote(payload);
      // Inject new version into the array
      this.myMeetings = this.myMeetings.map((item) =>
        item.id === payload.meetingId ? { ...result.data } : { ...item },
      );

      this.isLoading = false;
      this.setSnackBar({ message: 'Vote was successfully canceled', type: 'success' });
    } catch (error: any) {
      this.isLoading = false;
      captureException(error);
      this.setSnackBar({ message: error.message || 'Something went wrong. Please try again later.', type: 'error' });
      return undefined;
    }
  };

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };

  setNeedConsent = (value: boolean) => {
    this.needConsent = value;
  };
}

const appStore = new AppStore();

export default appStore;
